import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const TestimonialModal = ({ isOpen, onClose }) => {
    const navigate = useNavigate(); // Hook to navigate users
    const [formData, setFormData] = useState({ message: "", email: "", video: null });
    const [isCheckingEmail, setIsCheckingEmail] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    if (!isOpen) return null; // Prevent rendering when modal is closed

    // Handle input changes correctly
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    // Handle file selection
    const handleFileChange = (e) => {
        setFormData((prev) => ({ ...prev, video: e.target.files[0] }));
    };

    //  Function to check if email exists in the database
    const checkEmailExists = async (email) => {
        try {
            setIsCheckingEmail(true);
            setErrorMessage("");

            const response = await fetch(`/api/check-email?email=${email}`); //  Replace with your actual API endpoint
            const data = await response.json();

            setIsCheckingEmail(false);
            return data.exists; // Backend should return { exists: true/false }
        } catch (error) {
            console.error("Error checking email:", error);
            setErrorMessage("Erreur lors de la vérification de l'email. Réessayez.");
            setIsCheckingEmail(false);
            return false;
        }
    };

    //  Handle form submission
    const handleSubmit = async () => {
        if (formData.message.trim() === "" || formData.email.trim() === "") {
            setErrorMessage("Veuillez remplir tous les champs.");
            return;
        }

        const emailExists = await checkEmailExists(formData.email);

        if (!emailExists) {
            navigate("/tarifs"); //  Redirect user to signup page
            return;
        }

        // Proceed with submission if email exists
        console.log("Submitting review:", formData);
        setFormData({ message: "", email: "", video: null });
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 text-tcfsectext">
            <div className="bg-white p-6 rounded-[5px] shadow-lg w-[95%] max-w-md relative">
                <h2 className="text-center text-sm md:text-ml mg:text-2xl font-bold">Donnez votre avis</h2>

                <div className="mt-[36px] md:mt-[20px] mg:mt-[33px] space-y-2">
                   
                    <input
                        className="w-full px-[12px] py-[10px] md:py-[14px] bg-tcfbg placeholder-tcfinput text-xs mg:text-base h-[40px] mg:h-[50px] rounded-[5px]"
                        placeholder="Entrez votre email"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                    />

                   
                    <textarea
                        className="w-full px-[12px] py-[10px] md:py-[14px] bg-tcfbg placeholder-tcfinput text-xs mg:text-base h-[167px] md:h-[209px] mg:h-[226px] rounded-[5px]"
                        placeholder="Écrire quelque chose"
                        name="message"
                        value={formData.message}
                        onChange={handleInputChange}
                    />
                </div>

                
                {errorMessage && <p className="text-red-500 text-sm mt-2">{errorMessage}</p>}

                <div className="flex justify-between gap-[5px] md:gap-[16px] mt-[16px] md:mt-[14px] lg:mt-[19px] w-full font-bold text-base">
                    <button
                        className="bg-tcfsectext w-full text-white h-[30px] md:h-[40px] rounded-[5px] disabled:opacity-50"
                        onClick={handleSubmit}
                        disabled={isCheckingEmail}
                    >
                        {isCheckingEmail ? "Vérification..." : "Envoyer"}
                    </button>
                    <button className="border border-tcfsectext h-[30px] md:h-[40px] w-full rounded-[5px]" onClick={onClose}>
                        Annuler
                    </button>
                </div>
            </div>
        </div>
    );
};

export default TestimonialModal;
