import React, { useState, useRef, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import arrowdownIcon from '../images/arrownDropdown.svg'


const DropdownMenu = ({  menuItems }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  

  return (
    <div className="relative" ref={dropdownRef}>
   <img onClick={toggleDropdown}  src={arrowdownIcon} alt={arrowdownIcon}   className={`transition-transform duration-300 ${isDropdownOpen ? 'rotate-180' : 'rotate-0'}`} />
      {isDropdownOpen && (
        <div className="text-sm absolute  right-[0px]  shadow-dropdownShadow shadow-cardshadow py-[22px] w-[320px]  border-white rounded-[5px] bg-white z-50 mt-[18px] font-bold">
          {menuItems.map((item, index) => (
            item.link ? (
              <NavLink
                key={index}
                className="flex h-[40px] px-[21px] block hover:bg-molightgray text-sm items-center"
                to={item.link}
                onClick={() => setIsDropdownOpen(false)}
              >
                {item.label}
              </NavLink>
            ) : (
              <button
                key={index}
                className="flex h-[40px] px-[21px] text-tcfsectext  block w-full text-left hover:bg-tcfbg text-sm items-center"
                onClick={() => {
                  setIsDropdownOpen(false);
                  item.onClick && item.onClick();
                }}
              >
                {item.label}
              </button>
            )
          ))}
        </div>
      )}
    </div>
  );
};

export default DropdownMenu;

