import React, { useEffect, useState } from 'react';
import { useSelector , useDispatch} from "react-redux";
import apiClient from "../services/httpService";
import ResultsStats from './ComprehensionTestResultsStats';
import ComprehensionTestCorrections from './ComprehesionTestCorrections';
import {  setTotalScore } from "../store/scoreSlice"


const ComprehensionResultsSummary = () => {

  
  const dispatch = useDispatch(); // 
  const [showCorrections, setShowCorrections] = useState(false); // State to show corrections
  const responseId = useSelector((state) => state.response.responseId);
  const [responses, setResponses] = useState([]);
  const [correctAnswersCount, setCorrectAnswersCount] = useState(0); // State for correct answers count
  const [totalQuestions, setTotalQuestions] = useState(0); // State for total questions count
  const { selectedTest } = useSelector((state) => state.questions);


  

  // Show corrections when button is clicked
  const handleShowCorrections = () => {
    setShowCorrections(true); // Set state to show corrections
  };

  // Hide corrections and show results stats
  const handleBackToStats = () => {
    setShowCorrections(false); // Hide corrections
  };

  useEffect(() => {
    if (responseId) {
      fetchResponses();
    }
  }, [responseId]);

  const fetchResponses = async () => {
    const response = await apiClient.get(`/mgmt/fetch-response/${responseId}/`);
    setResponses(response.data);

    setTotalQuestions(response.data.length);  // Total number of questions is the length of the response data


    // Count correct answers
    const correctCount = response.data.filter(item => item.is_correct).length;
    setCorrectAnswersCount(correctCount);
  };
  // console.log('fetch responseid and response', responseId, responses)
  useEffect(() => {
    if (Array.isArray(responses) && responses.length > 0) {
        const score = responses
            .filter(response => response.is_correct)
            .reduce((sum, response) => sum + (response.point || 0), 0);

        console.log("Dispatching score:", score); // Debugging log

        dispatch(setTotalScore(score)); // 
    }
}, [responses, dispatch]);



  return (

    <div className='w-full'>
      <h2 className="  text-2xl md:text-5xl lg:text-7xl text-white font-bold text-center">Comprehension Correction Test ({selectedTest})</h2>

      {/* Show ResultsStats or ComprehensionTestCorrections based on the state */}
      {!showCorrections ? (
        <>
          <ResultsStats
            totalQuestions={totalQuestions}
            correctAnswersCount={correctAnswersCount}
            handleShowCorrections={handleShowCorrections} // Pass the function as prop
            selectedTest={selectedTest}
          />
         
        </>

      ) : (
        <div>
          {/* Back to results stats button */}
          <button
            onClick={handleBackToStats} // This will call the function to hide corrections and show results stats
            className=" mt-[57px] text-white font-bold border border-white px-[35px] h-[30px] rounded-[5px] w-fit mb-4"
          >
            Retour au stats
          </button>
          <ComprehensionTestCorrections responses={responses} />


        </div>
      )}
    </div>
  );
};

export default ComprehensionResultsSummary;
