import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import apiClient from '../services/httpService';

const SubscriptionUpgrade = ({ currentPlan, currentSubType }) => {
    const navigate = useNavigate();
    const [subscriptionPlans, setSubscriptionPlans] = useState([]);
    const [examSubscriptionPlans, setExamSubscriptionPlans] = useState([]);
    const [trainingSubscriptionPlans, setTrainingSubscriptionPlans] = useState([]);

    useEffect(() => {
        const fetchSubscriptions = async () => {
            try {
                const response = await apiClient.get('/mgmt/available-subscriptions/');
                if (response.status === 200) {
                    const fetchedPlans = response.data.map(subscription => ({
                        sub_id: subscription.id,
                        name: subscription.plan_name,
                        price: parseFloat(subscription.amount), // Convert to number
                        duration: subscription.plan_duration,
                        sub_type: subscription.sub_type,
                    }));

                    const examPlans = fetchedPlans.filter(subscription => subscription.sub_type === 'Examen');
                    const trainingPlans = fetchedPlans.filter(subscription => subscription.sub_type === 'Formation');

                    const styledExamPlans = examPlans.map((plan, index) => ({
                        ...plan,
                        gradient: [
                            "from-[#FCEABB] to-[#FFFFFF]",
                            "from-[#B9F2FF] to-[#FFFFFF]",
                            "from-[#C0C0C0] to-[#FFFFFF]",
                            "from-[#FCEABB] to-[#FFFFFF]"
                        ][index] || "",
                        buttoncolor: [
                            "from-[#F7EF8A] to-[#AE8625]",
                            "from-[#AEE2FF] to-[#8EC5FC]",
                            "from-[#C0C0C0] to-[#808080]",
                            "from-[#F7EF8A] to-[#ae8625]"
                        ][index] || ""
                    }));

                    const styledTrainingPlans = trainingPlans.map((plan, index) => ({
                        ...plan,
                        gradient: [
                            "from-[#C0C0C0] to-[#FFFFFF]",
                            "from-[#B9F2FF] to-[#FFFFFF]",
                            "from-[#FCEABB] to-[#FFFFFF]",
                            "from-[#FCEABB] to-[#FFFFFF]"
                        ][index] || "",
                        buttoncolor: [
                            "from-[#C0C0C0] to-[#808080]",
                            "from-[#AEE2FF] to-[#8EC5FC]",
                            "from-[#F7EF8A] to-[#ae8625]",
                            "from-[#AEE2FF] to-[#8EC5FC] "
                        ][index] || ""
                    }));

                    setSubscriptionPlans(fetchedPlans);
                    setExamSubscriptionPlans(styledExamPlans);
                    setTrainingSubscriptionPlans(styledTrainingPlans);
                }
            } catch (error) {
                console.error('Error fetching subscriptions', error);
            }
        };

        fetchSubscriptions();
    }, []);

    if (subscriptionPlans.length === 0) return <p>Loading...</p>;

    const plansToShow = currentSubType === 'Examen' ? examSubscriptionPlans : trainingSubscriptionPlans;

    // Sort plans by price (ascending order)
    const sortedPlans = plansToShow.sort((a, b) => a.price - b.price);

    // Find the current plan
    const currentPlanData = sortedPlans.find(plan => plan.name === currentPlan) || null;

    // Get current plan price safely
    const currentPlanPrice = currentPlanData ? currentPlanData.price : 0;

    // Find the next plan that is more expensive
    const nextPlan = sortedPlans.find(plan => plan.price > currentPlanPrice) || null;

   

    if (!nextPlan) {
        return <p></p>;
    }

    const handleSubscribe = () => {
        navigate('/payment', { 
            state: { 
                plan: nextPlan.name, 
                duration: nextPlan.duration, 
                price: nextPlan.price, 
                sub_id: nextPlan.sub_id 
            } 
        });
    };

    return (
        <div className={`flex flex-col bg-gradient-to-r ${nextPlan.gradient} px-4 py-3 rounded-[5px] border border-tcfgray1 shadow-gold`}>
            <p className="text-lg md:text-sm mg:text-2xl font-bold">Passer à la version</p>
            <p className="text-[10px] mg:text-sm">Profitez de fonctionnalités étonnantes lorsque vous passez à <span className='font-bold'>{nextPlan.name}</span> </p>
            <p className="text-xl lg:text-2xl font-bold mt-2">${nextPlan.price.toFixed(2)}</p>

            <button 
                onClick={handleSubscribe}
                className={`w-full flex justify-center items-center bg-gradient-to-r ${nextPlan.buttoncolor} text-tcfsectetxt font-bold rounded-[5px] h-10 mt-4`}>
                <span className="w-full h-full flex justify-center items-center">
                    Passer à {nextPlan.name}
                </span>
            </button>
        </div>
    );
};

export default SubscriptionUpgrade;
