/** @format */

// LoginPage.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Footer from '../components/Footer';
import HomeNavigation from '../components/Navbar';
import loginbanner from '../images/looginbanner.png';
import background from '../images/noisy-gradients.png';
import InputField from '../components/InputField';
import apiClient from '../services/httpService';
import Alert from '../components/Alert';

const ForgotPasswordPage = () => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [successMessage, setSuccessMessage] = useState('');


  const handleSubmit = async () => {
    try {
      setLoading(true);
      setError(null);
      const res = await apiClient.post('/auth/users/reset_password/', {
        email: email,
      });
      setSuccessMessage(
        "Si l'adresse e-mail est associée à un compte, vous recevrez un lien de réinitialisation de mot de passe. Veuillez vérifier votre boîte de réception ou votre dossier spam."
      );
      // console.log('res', res);
      // navigate('/reset-password');
    } catch (err) {
      setError(err.response?.data?.message || 'Une erreur est survenue');
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (name, value) => {
    if (name === 'email') {
      setEmail(value);
    }
  };
  return (
    <div className='flex flex-col  gap-[35vh] md:gap-0 justify-between'>
      {/* Login Section */}
      <div className='  flex flex-col justify-between min-h-screen md:min-h-[85vh] lg:min-h-[120vh]  lg:min-h-[140vh]'>
        {/* Section 1 */}
        <section
          className='w-full flex pr-[32px] md:pr-[40px] lg:pr-[48px] mg:pr-[120px] xl:pr-[190px] pl-[32px] mg:pl-[64px] xl:pl-[102px] pt-[209px] md:pt-[183px]  lg:pt-[225px]  mg:pt-[277px] h-[338px] md:h-[298px] lg:h-[347px] mg:h-[433px] bg-cover bg-center '
          style={{
            backgroundImage: `url(${background})`,
          }}>
          <div className='z-10'>
            <HomeNavigation />
          </div>
          {/* Navbar at the top */}

          <div className='w-full flex flex-col md:flex-row gap-y-[36px] md:gap-x-[16px] lg:gap-x-[38px] xl:gap-x-[111px] justify-around'>
            {/* Image */}
            <div className='flex-1 flex'>
              <img
                src={loginbanner}
                alt='login'
                className='w-full h-[341px] md:h-[325px] lg:h-[489px] xl:h-[623px] object-cover rounded-[5px]'
              />
            </div>

            {/* Login Form */}
            <div className='flex-1 flex'>
              <div className='flex flex-col w-full text-tcfsectext md:text-white'>
                <h3 className='text-3xl md:text-4xl lg:text-7xl font-bold md:mb-[5px] mg:mb-0'>
                  Mot de passe oublié
                </h3>
                <p className='mb-[26px]  md:mb-[44px] lg:mb-[55px] mg:mb-[82px]'>
                  Entrez votre email pour recevoir le lien de réinitialisation
                  du mot de passe
                </p>

                <div className='flex flex-col justify-center w-full gap-[16px]'>
                  {error && <p className='text-red-500 text-sm'>{error}</p>}
                  {/* {successMessage && <p className='text-tcfsectext text-sm'>{successMessage}</p>} */}
                  {successMessage &&
                    <div className='sticky z-[999]'>
                      <Alert type='success' header={'Réussite'} message={successMessage} />
                    </div>
                  }

                  <InputField
                    placeholder='Entrez votre email'
                    name='email'
                    handleChange={handleChange}
                    style='bg-tcfbg  h-[40px] mg:h-[50px]'
                  />
                  <button
                    onClick={handleSubmit}
                    disabled={loading}
                    className='w-full bg-tcfsectext text-sm text-white h-[40px] mg:h-[50px] rounded-[5px] font-semibold shrink-0 mb-[16px]'>
                    {loading ? 'Envoi en cours...' : 'Envoyer'}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className=''>
        <Footer />
      </div>
    </div>
  );
};

export default ForgotPasswordPage;
