import React from 'react';
import statsIcon from '../images/stats.svg'
import ProgressBar from './ProgressBar';
import RoundProgressBar from './RoundProgress';

const Statistics = () => (
  <div className="text-tcfsectext flex flex-col gap-[19px] bg-white border border-tcfgray1 px-[22px] w-full rounded-[5px] py-[14px]">
    <div className='flex flex-col sm:flex-row  justify-between gap-[36px] md:gap-[18px] lg:gap-[36px] items-start md:items-normal'>
      <div className='flex flex-col gap-[19px]'>
        <h3 className="text-lg lg:text-2xl font-bold">Statistiques</h3>

        <div className='flex '>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-[19px] md:gap-[41px] w-full items-center text-base"> {/* Adding space between each progress bar section */}
            {[
              { name: "Expression Écrite", progress: 70, color: "bg-tcfyellow" },
              { name: "Expression Orale", progress: 50, color: "bg-tcfpink" },
              { name: "Compréhension Orale", progress: 60, color: "bg-tcfblue" },
              { name: "Compréhension Écrite", progress: 80, color: "bg-tcfpurple" }
            ].map((stat, index) => (
              <div key={index} className="space-y-[6px]"> {/* grid layout with some spacing */}
                <div>
                  <p className=" text-base">{stat.name}</p> {/* Better font styling */}
                </div>
                <div className="flex items-center gap-4">
                  <ProgressBar progress={stat.progress} timer={false} width='w-[210px] md:w-[180px] lg:w-[190px] mg:w-[260px]' />
                  {/* Optional: Adding a text indicating progress percentage */}
                  {/* <p className="font-bold text-sm">{stat.progress}%</p> */}
                </div>
              </div>
            ))}
          </div>

        </div>
      </div>
      <div className='flex flex-col items-center space-y-[9px]'>
        <RoundProgressBar progress={75} size={120} strokeWidth={20} color="#12372A" />
        <p className='font-bold text-sm'>Progression Générale</p>
      </div>
    </div>

  </div>


);

export default Statistics;
