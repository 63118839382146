/** @format */

import { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import apiClient from '../services/httpService';

const ApproveBooking = () => {
  const { bookingId } = useParams();
  const navigate = useNavigate();

  const handleApprove = async () => {
    try {
      const response = await apiClient.get(`/mgmt/bookings/${bookingId}/approve_booking/`);
      console.log('response', response);
      alert('Booking approved successfully!');
      navigate('/dashboard'); // Redirect after approval
    } catch (error) {
      alert('Error approving booking!');
    }
  };

  const handleReject = async () => {
    try {
      const response = await apiClient.get(`/mgmt/bookings/${bookingId}/reject_booking/`);
      console.log('response', response);
      alert('Booking rejected successfully!');
      navigate('/dashboard'); // Redirect after rejection
    } catch (error) {
      alert('Error rejecting booking!');
    }
  };

  return (
    <div>
      <button onClick={handleApprove}>Approve</button>
      <button onClick={handleReject}>Reject</button>
    </div>
  );
};

export default ApproveBooking;
