// src/pages/SignUpPage.js
import React, { useState, useEffect } from 'react';
import HomeNavigation from '../components/Navbar';
import Footer from '../components/Footer';
import SubscriptionSection from '../components/Subscription';
import background from '../images/noisy-gradients.png';



const Subscription = () => {


  return (
    <div className="flex flex-col min-h-screen justify-between">

      <div className=' text-tcfsectext pt-[176px] h-[296px] mg:h-[433px] bg-cover bg-center ' style={{
        
        backgroundImage: `url(${background})`,
        
       
      }}>
        <div className='text-center w-full text-white flex flex-col justify-center  ' >
          <HomeNavigation />

          <h2 className=" text-7xl font-bold mg:mt-[68px] ">Tarifs</h2>

        </div>
        <div className=' pt-[176px] mb-[223px]'>

          <SubscriptionSection />
        </div>
        <Footer />
      </div>
      
    </div>
  );
};




export default Subscription;
