/** @format */

import React from 'react';
import homelogo from '../images/homelogo2.png';

const Footer = () => {
  return (
    <div className='pt-[34px] lg:pt-[41px] px-[32px] lg:px-[67px] xl:px-[102px] pb-[28px] lg:pb-[44px] bg-tcfmediumgreen text-tcfsectext '>
      <section className='flex flex-col md:flex-row justify-between items-center md:items-end text-sm space-y-4 lg:space-y-0'>
        <div className='flex flex-col text-start lg:text-left'>
          <p className='text-3xl lg:text-5xl font-bold mb-[5px]'>Newsletter</p>
          <p className='text-sm md:flex-wrap lg:flex-nowrap'>
            Faites vos choix, enregistrez-vous, et commencez aujourd'hui
          </p>
        </div>

        <div className='flex flex-col md:flex-row  md:justify-end gap-[10px] w-full lg:w-auto'>
          <input
            type='text'
            placeholder='Exemple@mail.com'
            className='px-[13px] outline-none focus:outline-none h-[40px] rounded-[5px] placeholder:text-tcfgray w-full md:w-[246px]'
          />
          <button className='w-full md:w-fit lg:w-[120px] bg-tcfsectext text-white px-[25px] font-bold h-[40px] rounded-[5px]'>
            Subscribe
          </button>
        </div>
      </section>

      <div className='border-t border-tcfsectext  mt-[44px] lg:mt-[60px]'></div>

      <div className='flex flex-col md:flex-row pt-[27px] justify-between lg:justify-normal md:gap-[16px] lg:gap-[67px] lg:items-start text-start lg:text-left'>
        {/* Logo Section */}
        <div className='mb-[27px] lg:mb-0'>
          <a href="/">
            <img src={homelogo} alt="Home Logo" className=" h-[45px] w-[157px]  lg:mx-0" />
          </a>
        </div>                       

        {/* Links and Contact Grid */}
        <div className='grid grid-cols-1 md:grid-cols-3 gap-6 lg:gap-12 w-full lg:w-auto'>
          {/* Contacts */}
          <div className='font-normal text-base space-y-[6px] '>
            <p className='mb-[11px] text-sm font-bold'>Nos Contacts</p>
            <p>Phone: +237 6 92 55 11 16</p>
            <p>Whatsapp: +237 6 92 55 11 16</p>
            <p>SMS: +237 6 92 55 11 16</p>
            <p>Email: info@maitrisetcf.com</p>
          </div>

          {/* Pages */}
          <div className='font-normal text-base space-y-[6px] '>
            <p className='mb-[11px] text-sm font-bold'>Les Pages</p>
            <p>Compréhension Écrite</p>
            <p>Compréhension Orale</p>
            <p>Expression Écrite</p>
            <p>Expression Orale</p>
          </div>

          {/* Methodologies */}
          <div className='font-normal text-base space-y-[6px] '>
            <p className='mb-[11px] text-sm font-bold'>Les Méthodologies</p>
            <p>Compréhension Écrite</p>
            <p>Compréhension Orale</p>
            <p>Expression Écrite</p>
            <p>Expression Orale</p>
          </div>
        </div>
      </div>

      <div className='border-t  border-tcfsectext mt-[47px] lg:mt-[79px]'></div>

      <h1 className='mt-[26px] text-sm font-normal text-center md:text-start'>
        © 2024 Maitrise TCF. All rights reserved. Powered by{' '}
        <a
          className='underline text-tcfsectext hover:text-tcfsectext'
          href='https://modenbo.com/'
          target='_blank'
          rel='noopener noreferrer'>
          Modenbo Technologies
        </a>
      </h1>
    </div>
  );
};

export default Footer;
