import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import apiClient from "../services/httpService";

const SubscriptionTable = ({ label = true }) => {
  const navigate = useNavigate();
  const [userSubscriptions, setUserSubscriptions] = useState([]);

  const getUserSubscriptions = async () => {
    try {
      const response = await apiClient.get("/user/api/user-subscriptions/");
      if (response.status === 200) {
        const subscriptions = response.data.results.map((sub) => {
          const startDate = sub.created_on ? new Date(sub.created_on) : null;
          const planDurationDays = sub.subscription_details?.plan_duration
            ? parseInt(sub.subscription_details.plan_duration, 10)
            : 0;

          const endDate =
            startDate && !isNaN(planDurationDays)
              ? new Date(startDate.getTime() + planDurationDays * 24 * 60 * 60 * 1000)
              : null;

          const isActive = endDate && endDate > new Date();
          return {
            ...sub,
            status: isActive ? "Active" : "Expired",
            startDate,
            endDate,
          };
        });

        setUserSubscriptions(subscriptions);
      }
    } catch (error) {
      console.error("Failed to fetch subscriptions", error);
    }
  };

  useEffect(() => {
    getUserSubscriptions();
  }, []);


  const handleSubscribe = () => {
    navigate('/tarifs');
    
  };
  // console.log("userSubscriptions", userSubscriptions);


// console.log('userSubscriptions',userSubscriptions)
  return (
    <div>
      <div className="hidden md:block text-tcfsectext w-full text-[8px] md:text-sm mt-4 overflow-x-auto">
        {label && <h3 className="text-lg lg:text-2xl font-bold">Abonnement</h3>}

        <div className="w-full mt-4 rounded-[5px] border border-tcfgray1 overflow-hidden">
          <table className="w-full">
            <thead>
              <tr className="bg-tcfbackground">
                {["Adhésion", "Détails du forfait", "Montant", "Date de début et de fin", "Statut", "Action"].map((heading, index) => (
                  <th key={index} className="py-3 font-bold md:text-base lg:text-sm">{heading}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {userSubscriptions.length > 0 ? (
                userSubscriptions.map((row, rowIndex) => {
                  const formattedStartDate = row.startDate ? row.startDate.toLocaleDateString() : "N/A";
                  const formattedEndDate = row.endDate ? row.endDate.toLocaleDateString("en-GB") : "N/A";

                  return (
                    <tr key={rowIndex} className="border-t">
                      <td className="pl-2 md:pl-4 py-2 md:py-4">{row.subscription_details?.plan_name || "N/A"}</td>
                      <td>{row.subscription_details?.sub_type || "N/A"}</td>
                      <td>{row.subscription_details?.amount ? `$${row.subscription_details.amount }` : "N/A"}</td>
                      <td className="hidden md:table-cell">{formattedStartDate} - {formattedEndDate}</td>
                      <td className="block md:hidden">{formattedStartDate} <br /> {formattedEndDate}</td>
                      <td>{row.status}</td>
                      <td className="pr-2 md:pr-4">
                        {row.status === "Expired" && (

                          <button
                            className="w-full text-white px-1 md:px-4 h-[20px] md:h-[25px] font-bold rounded-[5px] text-[8px] md:text-base bg-tcfgreen"
                            onClick={() => handleSubscribe()}
                          >
                            Renew
                          </button>
                        )}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="6" className="text-center py-4">No subscriptions found.</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      <div className="block md:hidden text-tcfsectext w-full text-xs overflow-x-auto">
        <div className="w-full mt-4 rounded-[5px] border border-tcfgray1 overflow-hidden">
          <table className="w-full">
            <thead>
              <tr className="bg-tcfbackground">
                <th className="py-[9px] px-[16px] text-start font-bold text-sm">Abonnement</th>
              </tr>
            </thead>
            <tbody>
              {userSubscriptions.length > 0 ? (
                userSubscriptions.map((row, rowIndex) => {
                  const formattedStartDate = row.startDate ? row.startDate.toLocaleDateString() : "N/A";
                  const formattedEndDate = row.endDate ? row.endDate.toLocaleDateString("en-GB") : "N/A";

                  return (
                    <tr key={rowIndex} className="border-t">
                      <td className="pl-[16px] pt-[11px] pb-[16px]">
                        <div className="flex justify-between items-center pr-[16px]">
                          <div className="space-y-[3px]">
                            <p className="font-bold text-sm">
                              {row.subscription_details?.plan_name || "N/A"} - <span>{row.subscription_details?.amount ? `$${row.subscription_details.amount}` : "N/A"}</span>
                            </p>
                            <p className="text-tcfinput">
                              {row.subscription_details?.sub_type || "N/A"} - <span className={`${row.status === "Expired"?'text-tcfred':'text-tcfgreen'}`}>{row.status}</span>
                            </p>
                            <p className="font-normal">{formattedStartDate} - {formattedEndDate}</p>
                          </div>
                          {row.status === "Expired" && (
                            <button
                              className="w-fit text-white px-[16px] h-[25px] font-bold rounded-[5px] text-xs bg-tcfgreen"
                              onClick={() => handleSubscribe()}
                            >
                              Renew
                            </button>
                          )}
                        </div>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td className="text-center py-4">No subscriptions found.</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionTable;
