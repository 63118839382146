import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  responseId: null,
};

const responseSlice = createSlice({
  name: 'response',
  initialState,
  reducers: {
    setResponseId: (state, action) => {
      state.responseId = action.payload;
    },
    resetResponseId: (state) => {
      state.responseId = null;
    },
  },
});

export const { setResponseId, resetResponseId } = responseSlice.actions;

export default responseSlice.reducer;
