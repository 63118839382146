/** @format */

// LoginPage.js
import React from 'react';
import LoginForm from '../components/LoginForm';
import Footer from '../components/Footer';
import HomeNavigation from '../components/Navbar';
import loginbanner from '../images/looginbanner.png';
import background from '../images/noisy-gradients.png';

const LoginPage = () => {
  return (
    <div className='flex flex-col  gap-[35vh] md:gap-0 justify-between'>
      {/* Login Section */}
      <div className='  flex flex-col justify-between min-h-screen md:min-h-[85vh] lg:min-h-[120vh]  lg:min-h-[140vh]'>
        {/* Section 1 */}
        <section
          className='w-full flex pr-[32px] md:pr-[40px] lg:pr-[97px] mg:pr-[120px] xl:pr-[190px] pl-[32px] mg:pl-[64px] xl:pl-[102px] pt-[209px] md:pt-[183px]  lg:pt-[225px] h-[338px] md:h-[455px] lg:h-[629px] xl:h-[634px] bg-cover bg-center '
          style={{
            backgroundImage: `url(${background})`,
          }}>
          <div className='z-10'>
            <HomeNavigation />
          </div>
          {/* Navbar at the top */}

          <div className='w-full flex flex-col md:flex-row gap-y-[36px] md:gap-x-[16px] lg:gap-x-[38px]  mg:gap-x-[50px] xl:gap-x-[111px] justify-around'>
            <img
              src={loginbanner}
              alt='login'
              className='w-full h-[341px]  md:w-[30vh]  md:h-[325px] lg:w-[475px] lg:h-[489px] xl:h-[623px] object-cover transform  rounded-[5px] flex-1'
            />

            {/* Login Form (Same Growth as Image) */}
            <div className='flex-1 flex '>
              <LoginForm />
            </div>
          </div>
        </section>
      </div>
      <div className=''>
        <Footer />
      </div>
    </div>
  );
};

export default LoginPage;
