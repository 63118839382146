import React from 'react';
import ProfileCard from './ProfileCard';
import PointsProgress from './PointsProgress';
import Statistics from './Statistics';
import SubscriptionTable from './SubscriptionTable';
import ProgressionGeneral from './ProgressionGeneral';
import Banner from './Banner';
const ProfileSection = () => {
    return (

        <div className="w-full bg-white border border-tcfgray1 rounded-[5px] justify-between px-6 py-6 shadow-md min-h-fit lg:min-h-[84vh]  mg:min-h-[110vh] xl:min-h-[100vh]">
            {/* <ProfileCard /> */}
            <div className='flex flex-col gap-[20px] justify-between'>
                <Banner />
                <Statistics />
                <SubscriptionTable /> 
            </div>
            
        </div>


    );
};

export default ProfileSection