/** @format */

import React, { useState, useEffect } from 'react';
import bannerimage from '../images/bannerimage.jpg';
import { useRef } from "react";
import tcf from '../images/TCF.png';
import homepagestudents from '../images/homepagestudents.png';
import learn from '../images/learn.png';
import settings from '../images/settings.png';
import timer from '../images/timer.png';
import announcement from '../images/announcement.png';
import checkmarkIcon from '../images/checkmark.svg';
import greencheckmarkIcon from '../images/checkmark2.svg';
import logo from '../images/TCFlogo.png';
import assist from '../images/helpcenter.png';
import InputField from '../components/InputField';
import background from '../images/noisy-gradients.png';
import profileimage from '../images/profileimage.jpg';
import HomeNavigation from '../components/Navbar';
import Footer from '../components/Footer';
import logoImage from '../images/loginimagec.png';
import formation from '../images/section.jpg';
import CardComponent from '../components/USP';
import TarifCard from '../components/Tarifs';
import TeamCard from '../components/Team';
import keyboardright from '../images/Keyboardarrow right.svg';
import SubscriptionSection from '../components/Subscription';
import { NavLink, useLocation, useNavigate, useMatch } from 'react-router-dom';
import axios from 'axios';
import TestimonialModal from '../components/TestimonialsModal';



const Home = () => {
  const navigate = useNavigate();



  const teamData = [
    {
      image: profileimage,
      name: 'Abraham Lao ',
      position: 'Fondateur',
    },
    {
      image: profileimage,
      name: 'Mariam Diallo',
      position: 'Formatrice',
    },
    {
      image: profileimage,
      name: 'Erica Zambo',
      position: 'Formatrice',
    },
    {
      image: profileimage,
      name: 'Ibrahim Diallo',
      position: 'Formatrice',
    },
  ];

  // State to track the current index
  const [currentIndex, setCurrentIndex] = useState(0);

  // Number of cards visible at a time
  const visibleCount = 3;

  const centerIndex = Math.floor(visibleCount / 2);

  // Helper functions to navigate
  const moveLeft = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : teamData.length - visibleCount
    );
  };

  const moveRight = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex < teamData.length - visibleCount ? prevIndex + 1 : 0
    );
  };

  // Get the visible team members
  const visibleMembers = teamData.slice(
    currentIndex,
    currentIndex + visibleCount
  );

  // Handle wrapping around for visibleMembers
  if (visibleMembers.length < visibleCount) {
    visibleMembers.push(
      ...teamData.slice(0, visibleCount - visibleMembers.length)
    );
  }

  const clientData = [
    {
      image: profileimage,
      username: 'Abraham Lao ',
      handle: '@diallomaria',
      testimonial:
        'J’ai fini mon test TCF en 15 jours et j’avoue que c’était dur mais satisfaisant.',
    },
    {
      image: profileimage,
      username: 'Mariam Diallo',
      handle: '@diallomaria',
      testimonial:
        'J’ai fini mon test TCF en 15 jours et j’avoue que c’était dur mais satisfaisant.',
    },
    {
      image: profileimage,
      username: 'Erica Zambo',
      handle: '@diallomaria',
      testimonial:
        'J’ai fini mon test TCF en 15 jours et j’avoue que c’était dur mais satisfaisant.',
    },
  ];

  function ClientCard({ image, username, handle, testimonial }) {
    return (
      <div className='pl-[20px] pr-[45px] mg:pr-[64px] pt-[24px] pb-[42px] gap-[10px]  text-[#12372A] border border-[#C1C1C180]  rounded-[5px] flex flex-col text-start '>
        <div className='flex gap-[9px] items-center '>
          <img
            src={image}
            alt='User'
            className='w-[40px] h-[40px] rounded-full object-cover'
          />
          <div>
            <p className='text-start text-base font-bold mt-2'>{username}</p>
            <p className='text-start text-xs '>{handle}</p>
          </div>
        </div>
        <p className='text-sm w-[233px] mg:w-full '>{testimonial}</p>
      </div>
    );
  }

  // Add state for form fields
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    message: '',
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');

  // Handle input changes
  const handleInputChange = (name, value) => {
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Handle form submission
  const handleMessageSubmit = async () => {
    try {
      setIsSubmitting(true);
      setError('');

      const apiURL = process.env.REACT_APP_APIURL || '';

      console.log('formData', formData);
      const response = await axios.post(
        `${apiURL}/user/api/contact/`,
        formData
      );

      if (response.status === 200) {
        // Reset form
        setFormData({
          fullName: '',
          email: '',
          message: '',
        });
        alert('Message sent successfully!');
      }
    } catch (err) {
      setError('Failed to send message. Please try again.');
      console.error('Contact form error:', err);
    } finally {
      setIsSubmitting(false);
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <div className='flex flex-col overflow-x-hidden'>
      {/* Navbar at the top */}

      {/* Content Sections */}
      <div className=' relative flex flex-col justify-between min-h-screen '>
        {' '}
        {/* Padding top to avoid overlap with fixed navbar */}
        {/* Section 1 */}
        <section
          className=' flex flex-col gap-[75px] lg:gap-[58px] w-full  relative pt-[179px]  md:pt-[156px] lg:pt-[184px] mg:pt-[220px]  xl:pt-[260px] px-[32px] mg:px-[65px]   h-[653px] md:h-[572px] lg:h-[646px] xl:h-[1024px] bg-cover bg-center '
          style={{
            backgroundImage: `url(${background})`,
          }}>
          <HomeNavigation />

          <div className=' flex flex-col items-start  gap-[15px]   '>
            <p className=' text-3xl md:text-5xl lg:text-7xl font-bold  text-white'>
              Se préparer au TCF Canada
            </p>
            <p className='md:w-[552px] lg:w-[395px] mg:w-[428px] text-sm  text-white '>
              Plateforme dédiée à la préparation au TCF canada suivant les
              conditions réelles de l’examen
            </p>
            <div className='flex  gap-[18px] mt-[13px] '>
              
              <button
                onClick={() => {
                  navigate('/tarifs');
                }}
                className='bg-white  text-tcfsectext font-bold text-sm px-[19px] h-[40px] rounded-[5px]  '>
                Commencez ici
              </button>
              <button
                onClick={() => {
                  navigate('/login');
                }}
                className=' font-bold border border-white text-sm px-[19px] h-[40px] rounded-[5px] text-white '>
                Se connecter
              </button>
            </div>
          </div>

          {/* <img src={bannerimage} alt="Header" className="w-full h-[341px] md:h-[395px] lg:h-[445px] xl:h-[690px] object-cover transform  rounded-[5px] shrink-0" /> */}
          <img
            src={bannerimage}
            alt='Header'
            className='w-full h-[38vh] md:h-[44vh] lg:h-[48vh] mg:h-[60vh] xl:h-[78vh] object-cover rounded-[5px] shrink-0 transition-all duration-300 ease-in-out'
          />
        </section>
        {/* Section 2 */}
        <section className=' mt-[196px] md:mt-[351px] lg:mt-[309px]    '>
          <p className='flex text-3xl md:text-5xl lg:text-7xl  xl:px-[67px] font-bold text-tcfsectext justify-center'>
            Formation
          </p>
          <div className='mt-[34px] lg:mt-[47px] flex flex-col md:flex-row'>
            <img
              src={formation}
              alt='formation'
              className='w-full md:w-[305px] md:w-[415px] shrink-0 '
            />
            <div className=' bg-tcfmediumgreen w-full  '>
              <div className=' px-[33px] py-[26px]'>
                <p className='text-2xl font-bold text-tcfsectext mb-[15px]'>
                  Préparation Au TCF Canada - TCF Québec
                </p>
                <div className='space-y-[6px] mb-[46px] md:mb-[37px]'>
                  <p>Compréhension Écrite</p>
                  <p>Compréhension Orale</p>
                  <p>Expression Orale</p>
                  <p>Expression Écrite</p>
                </div>
                <button onClick={() => {
                  navigate('/formation');
                }} className='bg-tcfsectext text-white text-sm font-bold h-[40px] px-[15px] rounded-[5px]'>
                  
                  Programme de la formation
                </button>
              </div>
            </div>
          </div>
        </section>
        {/* Section 3 */}
        <section className='pt-[52px]  pt-[62px]  lg:pt-[105px]    '>
          <p className='flex text-3xl md:text-5xl lg:text-7xl font-bold text-tcfsectext text-center justify-center'>
            Pourquoi Choisir Notre Plate-Forme
          </p>
          <div className='mt-[58px] md:mt-[51px] lg:mt-[47px] flex'>
            <CardComponent />
          </div>
        </section>
        {/* Section 4 */}
        <section className=' pt-[70px] md:pt-[80px] lg:pt-[132px] lg:px-[30px]  flex flex-col  pb-[30px] text-center gap-[77px]'>
          <SubscriptionSection />
        </section>
        {/* Section 5 */}
        <section className=' pt-[102px] lg:px-[32px]  flex flex-col  pb-[30px] text-center mb-[169px]'>
          <div className='mb-[67px]'>
            <h2 className=' text-3xl md:text-5xl lg:text-7xl  font-bold  text-tcfsectext mb-[17px] '>
              Notre Équipe
            </h2>
            <p className='text-sm font-normal  text-tcfsectext '>
              Rejoignez notre équipe de formateurs TCF CANADA pour assurer votre
              réussite!
            </p>
          </div>
          <div className='flex items-center justify-evenly overflow-x-auto px-2 md:px-0 scrollbar-hide'>
            <img
              className={`hidden md:block  lg:mr-[30px] rotate-180 cursor-pointer   ${
                currentIndex === 0 ? 'opacity-50 cursor-not-allowed' : ''
              }`}
              src={keyboardright}
              alt='arrowright'
              onClick={moveLeft}
            />

            <div className='flex gap-[14px] lg:gap-[17px] justify-center  items-center '>
              <>
                {visibleMembers.map((member, index) => (
                  <TeamCard
                    key={index}
                    name={member.name}
                    position={member.position}
                    isCenterCard={index === centerIndex}
                  />
                ))}
              </>
            </div>
            <img
              className={` hidden md:block  lg:ml-[30px] cursor-pointer  ${
                currentIndex >= teamData.length - visibleCount
                  ? 'opacity-50 cursor-not-allowed'
                  : ''
              }`}
              src={keyboardright}
              alt='arrowright'
              onClick={moveRight}
            />
          </div>
        </section>  

        {/* Section 7 */}
        <section className=' w-full  relative   overflow-hidden  px-[32px] xl:px-[64px] '>
          <img
            src={background}
            alt='Header'
            className='w-full h-[475px] md:h-[284px]   mg:h-[367px] object-cover transform rounded-[5px] '
          />
          <div className=' absolute inset-0 right-[64px] left-[64px] md:right-[75px] md:left-[75px]  lg:right-[77px] lg:left-[57px] mg:right-[120px] mg:left-[120px] flex flex-col md:flex-row md:justify-between  gap-[44px] md:gap-0 '>
            <div>
              <h2 className=' text-3xl lg:text-5xl pt-[30px] lg:pt-[34px] text-start font-semibold  text-white mb-[12px]'>
                Contactez-nous
              </h2>
              <p className='text-sm font-normal text-white md:w-[292px] lg:w-auto '>
                Rejoignez notre équipe de formateurs TCF Canada pour assurer
                votre réussite !
              </p>
            </div>

            <div className='w-full md:w-[48%] lg:w-[29%] flex flex-col md:mt-[36px]'>
              <div className='space-y-[8px]'>
                <InputField
                style='h-[30px] mg:h-[40px]'
                  label='Prénom & Nom'
                  placeholder='Prénom & Nom'
                  name='fullName'
                  value={formData.fullName}
                  handleChange={handleInputChange}
                />
                <InputField
                style='h-[30px] mg:h-[40px]'
                  label='Courriel'
                  placeholder='exemple@mail.com'
                  name='email'
                  value={formData.email}
                  handleChange={handleInputChange}
                />
                <textarea
                  placeholder='Écrivez votre message'
                  className='w-full py-[7px] border text-base rounded-[5px] resize-none px-[12px] placeholder:text-tcfinput h-[102px] mg:h-[115px]'
                  rows='3'
                  value={formData.message}
                  onChange={(e) => handleInputChange('message', e.target.value)}
                />
              </div>
              {error && <p className='text-red-500 text-sm mt-2'>{error}</p>}
              <button
                className='text-white bg-tcfsectext font-bold text-base h-[30px] lg:h-[40px] rounded-[5px] mt-[7px]'
                onClick={handleMessageSubmit}
                disabled={isSubmitting}>
                {isSubmitting ? 'Envoi...' : 'Envoyez un message'}
              </button>
            </div>
            
            
          </div>
        </section>
        {/* Section 9 */}


        <section className='  flex flex-col pt-[95px] pb-[131px] text-center justify-center '>
          <div>
            <h2 className='flex text-3xl md:text-5xl lg:text-7xl px-[32px] justify-center  pt-[34px] text-center font-bold  text-tcfsectext mb-[40px]'>
              Quelque avis de nos clients satisfaits du service
            </h2>
          </div>
          <div className='flex  gap-[16px] overflow-auto flex-nowrap lg:justify-center snap-x snap-mandatory scrollbar-hide px-[32px] lg:px-[64px]'>
            {clientData.map((clientcard, index) => (
              <ClientCard
                key={index}
                image={clientcard.image}
                username={clientcard.username}
                handle={clientcard.handle}
                testimonial={clientcard.testimonial}
              />
            ))}
          </div>
          <button
          onClick={() => setIsModalOpen(true)}
            className={`flex items-center self-center justify-center w-[200px] font-bold text-base  h-[30px] rounded-[5px] mt-[15px]  bg-tcfsectext text-white `}>
            Laissez-nous votre avis
          </button>
          <TestimonialModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />

        </section>
      </div>

      <Footer />
    </div>
  );
};

export default Home;
