import React, { useState } from 'react';
import HomeNavigation from '../components/Navbar';
import Footer from '../components/Footer';
import ComprenensionEcriteSection from '../components/ComprehensionEcriteSection'
import background from '../images/noisy-gradients.png';

// the free page user sees when user is not logged in 
const ComprehensionEcritePage = () => {

    return (
        <div className="flex flex-col min-h-screen justify-between text-tcfsectext ">
            <div className='h-[590px] lg:h-[638px] bg-center bg-cover'  style={{
                backgroundImage: `url(${background})`,
            }}>
                <HomeNavigation />
                <>
                    <div className="mt-[193px] mg:mt-[240px] mb-[77px] mx-[32px]">
                        <ComprenensionEcriteSection showCorrections={false} />


                    </div>
                </>

                <Footer />
            </div>
        </div>
    );

};




export default ComprehensionEcritePage;
