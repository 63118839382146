import React from 'react';
import { useSelector } from 'react-redux';

const GradingSheet = () => {
    const totalScore = useSelector((state) => state.score.totalScore); // Get total score from Redux


    // Determine the grade based on totalScore
    let grade = "A1 Non Atteint";
    if (totalScore >= 100 && totalScore <= 199) grade = "A1";
    else if (totalScore >= 200 && totalScore <= 299) grade = "A2";
    else if (totalScore >= 300 && totalScore <= 399) grade = "B1";
    else if (totalScore >= 400 && totalScore <= 499) grade = "B2";
    else if (totalScore >= 500 && totalScore <= 599) grade = "C1";
    else if (totalScore >= 600 && totalScore <= 699) grade = "C2";

    const headers = ["NCLC", "Comprehension Écrite", "Comprehension Orale", "Expression Écrite", "Expression Orale"];
    const data = [
        ["10 et plus", "549 à 699(C1 - C2)", "549 à 699 (C1-C2)", "16 à 20 (C1 - C2)", "16 à 20  (C1 - C2)"],
        ["9", "523 à 548 (C1)", "524 à 548 (C1)", "14 - 15 (C1)", "14 - 15 (C1)"],
        ["8", "503 à 522 (C1)", "499 à 523 (B2-C1)", "12 -13 (B2)", "12- 13(B2)"],
        ["7", "458 à 502 (B2-C1)", "453 á 498 (B2)", "10-11 (B2)", "10-11 (B2)"],
        ["6", "398 à 457 (B1)", "406 á 452 (B2)", "7-8-9 (B1)", "7-8-9 (B1)"],
        ["5", "360 à 397 (B1)", "375 á 405 (B1-B2)", "6 (B1)", "6 (B1)"],
        ["4", "331 à 368 (B1)", "342 á 374 (B1)", "4-5 (A2)", "4-5 (A2)"],
    ];

    return (
        <div className='flex flex-col lg:flex-[2] mg:flex-1 text-sm text-tcfsectext justify-between text-tcfsectext w-full gap-[6px] overflow-x-hidden'>
            <label className='font-bold'>{totalScore < 100 ? "A1 Non Atteint" : ""}</label>

            {/* Grade Circling */}
            <div className='flex bg-tcfbg py-[6px] px-[12px] rounded-[5px] text-lg lg:text-2xl w-fit lg:gap-[32px]'>
                {["A1", "A2", "B1", "B2", "C1", "C2"].map((level) => (
                    <p
                        key={level}
                        className={`px-2 py-1 rounded-full border-2 ${
                            grade === level ? "border-black font-bold" : "border-transparent"
                        }`}
                    >
                        {level}
                    </p>
                ))}
            </div>


            <div className="overflow-x-auto text-xs mt-[15px] w-full">
                <table className="w-full border border-tcfbackground rounded-[5px] overflow-hidden">
                    <thead>
                        <tr className="bg-tcfsectext text-white">
                            {headers.map((header, index) => (
                                <th key={index} className="px-4 py-2">{header}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((row, rowIndex) => (
                            <tr key={rowIndex} className={rowIndex % 2 === 0 ? "bg-tcfmediumgreen" : "bg-tcfbg"}>
                                {row.map((cell, colIndex) => {
                                    const splitIndex = cell.indexOf("(");
                                    const numberPart = splitIndex !== -1 ? cell.substring(0, splitIndex).trim() : cell;
                                    const bracketPart = splitIndex !== -1 ? cell.substring(splitIndex).trim() : "";

                                    return (
                                        <td key={colIndex} className="px-4 py-2 text-center">
                                            <span className="block font-semibold">{numberPart}</span>
                                            {bracketPart && <span className="block text-xs text-gray-600">{bracketPart}</span>}
                                        </td>
                                    );
                                })}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default GradingSheet;
