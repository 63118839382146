// ResponseSection.js
import React, { useCallback, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import erroricon from '../images/exclamationicon.svg'
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentIndex, setExpressionWrittenResponse } from '../store/questionsSlice';
import apiClient from '../services/httpService';
import Alert from './Alert';
import ProgressBar from './ProgressBar';
import sendEmail from './Email';
import { resetSelectedTest } from '../store/questionsSlice';



const ResponseSection = ({ question , showCorrections, setActiveSection}) => {
  const [responses, setResponses] = useState([]);
  const [responseId, setResponseId] = useState([]);
  const [text, setText] = useState('');
  const [wordCount, setWordCount] = useState(0);
  const { questions, currentIndex, selectedTest } = useSelector((state) => state.questions);
  const dispatch = useDispatch();
  const maxWords = 120;

      const navigate = useNavigate();


  const handleChange = (event) => {

    const inputText = event.target.value;
    setText(inputText);

    // Calculate word count by splitting by spaces and filtering out empty strings
    const count = inputText.trim().split(/\s+/).filter(word => word.length > 0).length;
    setWordCount(count);
    dispatch(setExpressionWrittenResponse({ questionId: question.id, response: inputText }));
  };

  const handleNextQuestion = useCallback(() => {
    if (currentIndex < questions.expression_questions.length - 1) {
      dispatch(setCurrentIndex(currentIndex + 1));
    }
  }, [currentIndex, dispatch, questions.expression_questions.length]);

  const handlePreviousQuestion = useCallback(() => {
    if (currentIndex > 0) {
      dispatch(setCurrentIndex(currentIndex - 1));
    }
  }, [currentIndex, dispatch]);


  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated); // Get auth state

  const { user } = useSelector((state) => state.auth);



  const handleFinishExam = async () => {
    const formDataQuestions = questions?.expression_questions;
    // console.log(formDataQuestions)
    let valid = true;

    for (const question of formDataQuestions) {
      if (!question.response) {
        valid = false;
        alert('Please select an answer for all questions.');
        break; // Exit the loop immediately
      }
    }

    if (valid) {
      const response = await apiClient.post(`/mgmt/save-response-expression/${selectedTest}/`, {
        questions: questions.expression_questions,
      });
      if (response.status === 201) {
        setResponseId(response?.data?.id)

        //  fetch user response
        // const userResponse = await apiClient.get(`/mgmt/fetch-response/${responseId}/`);
          const userAnswers = {
          responses: formDataQuestions.map(item => item.response), // Collect all responses
          name:user.first_name,
          email: user.email // Append email only once
      };
      
        // console.log(userAnswers)
        // send email
        sendEmail(userAnswers)
       
          dispatch(resetSelectedTest()); // Ensures sidebar is visible on refresh
       
        setActiveSection('')

      }
    }
  };




  return (
    <div className=" flex flex-col gap-[22px]  mt-[42px]">
      {!question && <div className="text-red-500 text-xl">Veuillez sélectionner un test</div>}
      


      <div className='flex justify-between items-center '>
        <p className='flex gap-[12px] text-base md:text-sm font-bold'>{`Tache ${question?.task_number}`}<span className='font-bold text-[10px] md:text-xs bg-tcfmediumgreen h-[20px] px-[10px] rounded-[5px] '>{`${question?.points}`}points</span></p>

     {isAuthenticated    && !showCorrections && (
        <div className='flex items-center gap-[5px]'>
          <ProgressBar totalTime={questions?.duration} />
        </div>
        )}

      </div>

      {!showCorrections && (<Alert type="instruction" header="Consigne" message="Rédaction d'un message pour decrire, raconte et/ou expliquer, adresser à un ou plusieurs destinataires, dont le status a été précisé dans la consigne." />)}


      {(question && question?.task_number !== 3) &&
        <div className={`flex flex-col items-start  ${showCorrections?'':'bg-tcfmediumgreen px-[14px]  py-[11px]' }  rounded-[5px] gap-[6px] `}>
        {!showCorrections && (  
          <div className='flex flex-row w-full justify-between'>
            <p className="text-sm font-bold">SUJET</p>
          </div>)}
          <p className={`text-sm ${showCorrections?'text-tcfinput':'text-tcfsectext'}`}>
            {question?.description} <span className={`font-bold ${showCorrections?'text-tcfsectext':'text-tcfsectext '} `}>{question?.description_question}</span>
          </p>
          <p className="text-base self-end text-[#EB0728] mt-5">Minimum 60 mots - Maximum 120 mots</p>
        </div>
      }
      {question?.task_number === 3 &&
        <>
          <div className={`flex flex-col  items-start  ${showCorrections?'text-tcfinput':'text-tcfsectext bg-tcfmediumgreen py-[11px] px-[14px]'}  rounded-[5px] gap-[6px] `}>

            <div className="flex flex-col items-start  gap-1">
              <div className='flex flex-row w-full justify-between'>
                <p className="text-tcfsectext text-sm font-bold">Document 1</p>
              </div>
              <p className="text-sm  ">
                {question?.document_one}
              </p>
            </div>

            <div className=' border-tcfsectext w-full border-t mt-[30px] mb-[20px]'></div>
            <div className="flex flex-col items-start  gap-1">
              <div className='flex flex-row w-full justify-between'>
                <p className="text-tcfsectext text-sm font-bold">Document 2</p>
              </div>
              <p className="text-sm">
                {question?.document_two}
              </p>
            </div>
          </div>

          <div className={`flex flex-col items-start  ${showCorrections?'':'bg-tcfmediumgreen py-[11px] px-[14px]'}  rounded-[5px] gap-[6px] `}>
            <div className='flex flex-row w-full justify-between'>
              <p className="text-sm font-bold">SUJET</p>
            </div>
            <p className="text-sm font-bold ">
              {question?.question}
            </p>
            <p className="text-base self-end text-[#EB0728] mt-1">Minimum 60 mots - Maximum 120 mots</p>
          </div>
        </>
      }

      {/* Writing area */}
      {isAuthenticated && !showCorrections &&(
        <div className="relative w-full ">
          <textarea
            placeholder="Ecrire ici votre reponse..."
            className="w-full py-[12px] bg-tcfbg rounded-[5px] resize-none h-[259px] px-[16px] placeholder:text-tcfinput outline:none"
            rows="4"
            value={question?.response}
            onChange={handleChange}
          ></textarea>
          <p className="absolute bottom-[12px] right-[12px] text-tcfinput ">{wordCount}/{maxWords} mots</p>
        </div>
      )}

      {/* Correction */}
      {isAuthenticated && showCorrections &&(
        <div className=" w-full ">
          
        <div className={`flex flex-col items-start  bg-tcfmediumgreen px-[14px]  py-[11px] rounded-[5px] gap-[13px] `}>
                  <p className='flex gap-[12px] text-base md:text-sm font-bold'>{`Correction Tache ${question?.task_number}`}</p>
                  {/* <p>
                        <strong>Salut Cédric </strong>,<br/>
                        Le Château de Chombony est situé à environ 30 kilomètres de Lyon, dans un cadre magnifique, entouré de jardins
                        et de forêts. C'est un lieu historique avec une architecture impressionnante, parfait pour un mariage. Pour y accéder,
                        tu peux prendre la route en voiture, il y a un parking à disposition. Si tu viens en transport en commun, tu peux
                        prendre le train jusqu'à la gare de Villefranche-sur-Saône, puis un taxi ou une navette qui te conduira directement au
                        château. Le cadre est vraiment idyllique, et l'intérieur est tout aussi charmant, avec de grandes salles et des vues
                        imprenables. Je suis sûr que tu vas adorer l'endroit ! À très vite,<br/>

                       
                  </p> */}
                  {/* <strong>AYOUB </strong> */}
                   <div dangerouslySetInnerHTML={{ __html: question?.answer }} />

        </div>
     
          
        </div>
      )}

      {/* Buttons */}

      <div className='flex flex-col md:flex-row justify-between mt-[10px] gap-y-[11px] '>


        {currentIndex > 0 && (
          <button onClick={handlePreviousQuestion} className=" w-full font-bold border-tcfsectext border bg-white text-base px-[39px] h-[40px] md:h-[30px] rounded-[5px] text-tcfsectext mr-4 md:w-fit  ">Retour</button>
        )}

        {currentIndex < questions.expression_questions.length - 1 && (
          <button
            onClick={handleNextQuestion}
            className={`font-bold border text-base px-[36px] h-[40px] md:h-[30px] rounded-[5px] md:ml-auto bg-tcfsectext text-white
            ${(isAuthenticated && !showCorrections && currentIndex >= questions.expression_questions.length - 1 ) || ( isAuthenticated && !showCorrections && !questions.expression_questions[currentIndex]?.response) 
                ? ' cursor-not-allowed opacity-70'
                : ' border-white hover:bg-tcfmediumgreen hover:text-tcfsectext'
              }`} disabled={(isAuthenticated  && !showCorrections && currentIndex >= questions.expression_questions.length - 1 ) || ( isAuthenticated  && !showCorrections && !questions.expression_questions[currentIndex]?.response) }
          >
            Suivant
          </button>

        )}

{isAuthenticated && !showCorrections && (
  <>
        {currentIndex === questions?.expression_questions.length - 1 && (
          <button onClick={handleFinishExam} className=" w-full font-bold bg-tcfsectext border border-white text-base px-[36px] h-[40px] md:h-[30px] rounded-[5px] text-white md:ml-auto md:w-fit">Valider votre response</button>

        )}
        </>
      )}
      </div>
    </div>
  );
}

export default ResponseSection;
