import React, { useState } from "react";
import AudioPlayer from "./AudioPlayer";

const ComprehensionTestCorrection = ({ responses }) => {


    const [currentIndex, setCurrentIndex] = useState(0); // Track the current question index


    const handleNextQuestion = () => {
        if (currentIndex < responses.length - 1) {
            setCurrentIndex(currentIndex + 1);
        }
    };

    const handleClickedQuestion = (index) => {
        setCurrentIndex(index); // Set current question to clicked index
    };

    const handlePreviousQuestion = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
        }
    };

    // Pass totalScore up if a setter function exists


    const description = responses[currentIndex]?.description ?? "";
    const words = description.length > 0 ? description.split(/\s+/) : []; // Handle empty case
    const midIndex = Math.ceil(words.length / 2);

    const firstPart = words.slice(0, midIndex).join(" ");
    const secondPart = words.slice(midIndex).join(" ");

    const optionLabels = ['A', 'B', 'C', 'D', 'E', 'F']; // Extend if more options

    const apiURL = process.env.REACT_APP_APIURL || '';

    const imageUrl = responses[currentIndex]?.image ? `${apiURL}${responses[currentIndex]?.image}` : null;
    const audioUrl = responses[currentIndex]?.audio ? `${apiURL}${responses[currentIndex]?.audio}` : null;




    return (
        <div className='flex flex-col lg:flex-row gap-[16px] h-fit   '>
            <div className='bg-white shrink-0 w-full lg:w-[228px] mg:w-[316px] border border-[#C1C1C180] rounded-[5px] pt-[27px] pb-[36px] shadow-md px-[24px] md:px-[26px] '>

                <div className=" text-tcfsectext flex flex-col justify-between w-full ">
                    <h2 className="text-sm font-bold mb-[24px]">Nombre de questions</h2>
                    <div className="grid grid-cols-[repeat(auto-fit,_minmax(40px,_1fr))] gap-y-1 gap-x-[4px]">

                        {responses?.map((answer, i) => {
                            let bgColor = "bg-[#EF3B36]"; // wroung answer

                            if (answer.is_correct) {
                                bgColor = "bg-tcfmediumgreen"; // Current question
                            }

                            return (



                                <div className="text-tcfsectext flex flex-col justify-between  ">

                                    <div className="grid grid-cols-4 ">

                                        <button
                                            key={i}
                                            onClick={() => handleClickedQuestion(i)}
                                            className={`w-[40px] flex-1 text-tcfsectext  h-[40px] flex justify-center items-center   text-sm   hover:bg-tcfgray1 focus:outline-none  ${bgColor} ${bgColor === "bg-[#EF3B36]" ? "text-white" : ''}  `}
                                        >
                                            {i + 1}
                                        </button>
                                    </div>



                                </div>
                            );

                        })}

                    </div>
                    <div className="flex lg:flex-col  justify-between md:justify-normal lg:justify-between gap-[6px] md:gap-[22px] lg:gap-[6px] mg:gap-[16px] mt-[24px]">

                        <div className='flex gap-[7px] items-center'>
                            <div className="w-[15px] h-[15px] bg-tcfmediumgreen rounded-[3px] ">

                            </div>
                            <span className='text-base '>Correct</span>
                        </div>

                        <div className='flex gap-[7px] items-center'>
                            <div className="w-[15px] h-[15px] bg-[#EF3B36] rounded-[3px] ">

                            </div>
                            <span className='text-base '>Incorrrect</span>
                        </div>


                    </div>

                </div>
            </div>




            {/* Corrrections */}

            <div className='flex flex-col bg-white text-tcfsectext px-[25px] md:px-[42px] lg:px-[65px] py-[33px] w-full border border-[#C1C1C180] rounded-[5px] shadow-md '>
                {/* <p>Total Score: {finalScore}</p> */}

                {/* Question Header */}
                <div className="flex justify-between items-center">
                    <p className="flex gap-2 sm:gap-[12px] text-base md:text-sm font-bold">
                        Question {currentIndex + 1}{" "}
                        <span className="font-bold text-[10px] md:text-xs bg-tcfmediumgreen h-[20px] px-[10px] rounded-[5px] ">
                            {responses[currentIndex]?.point} points
                        </span>
                    </p>


                </div>

                {/* Question Text */}
                {/* <div className="mt-[33px] space-y-[3px]">
                    <p className='text-2xl font-bold '> {firstPart || ''}</p>
                    <p className='text-tcfinput text-sm '> {secondPart || ''}</p>

                </div>
                <p className="font-semibold mt-[21px]">{responses[currentIndex]?.text}</p> */}

                {!imageUrl ? (
                    responses[currentIndex]?.description && (
                        <div className="border border-tcfsectext p-2 rounded-[5px] mt-[29px]">

                            <p className='text-sm md:text-2xl '> {firstPart}</p>
                            <p className=' text-sm  font-bold  '> {secondPart}</p>
                        </div>)) : ('')
                }

                {imageUrl ? (
                    <div className='flex rounded-[5px] max-w-[800px] max-h-[500px] mb-6 overflow-hidden self-center mt-[29px]'>


                        <img className="rounded-[5px] w-full h-full object-fit bg-cover " src={imageUrl} alt="Question Image" />
                    </div>

                ) : (
                    ''

                )}
                {/* <div className='mt-[27px] px-[16px] py-[11px] text-sm rounded-[5px] bg-tcfmediumgreen'>{question?.text}</div> */}
                <p className="font-semibold mt-[21px]">{responses[currentIndex]?.text}</p>

                {audioUrl ? (
                    <AudioPlayer audioUrl={audioUrl} />) : ('')}





                {/* Questions */}




                {/* Correct/Incorrect status */}
                <div className="mt-[33px]">

                    <p className={`text-sm font-bold ${responses[currentIndex].is_correct ? "text-[#01A608]" : "text-[#EB0728]"}`}>
                        {responses[currentIndex].is_correct ? "Correct" : `Incorrect`}
                    </p>
                </div>

                {/* Options Section */}
                <div className="mt-[14px]">
                    <div className="flex flex-col space-y-[10px]">
                        {responses[currentIndex]?.options.map((option, optionIndex) => {
                            const isCorrect = option.value === responses[currentIndex].answer; // Correct answer
                            const isUserAnswer = option.value === responses[currentIndex].response; // User's answer
                            const isWrongAnswer = isUserAnswer && !isCorrect; // User's wrong answer

                            return (
                                <label
                                    key={optionIndex}
                                    className={`flex items-start gap-x-[12px] w-full py-2 px-1 rounded-[5px] 
                    ${isCorrect ? "bg-tcfmediumgreen text-[#01A608] border-2 border-[#01A608]" : ""}
                    ${isWrongAnswer ? "bg-[#FFDFE480] text-[#EB0728] border-2 border-[#EB0728]" : "bg-none"}
                `}
                                >
                                    <input
                                        type="radio"
                                        className={`mt-0.5 w-[18px] h-[18px] appearance-none border border-[#C1C1C180] bg-[#E5E5E5] rounded-full 
                    shrink-0 checked:shadow-[inset_0_0_0_4px_white] 
                    ${isCorrect ? "checked:bg-[#01A608]" : "checked:bg-[#EB0728]"}
                  `}
                                        disabled
                                        checked={isUserAnswer}
                                    />

                                    <span className="font-bold">{optionLabels[optionIndex]}</span> {option.text}

                                </label>
                            );
                        })}
                    </div>
                </div>

                {/* Navigation Buttons */}
                <div className='flex flex-col md:flex-row justify-between mt-[40px] gap-y-[11px]'>


                    {currentIndex > 0 && (
                        <button
                            onClick={handlePreviousQuestion}
                            className="w-full font-bold border border-tcfsectext bg-white text-base px-[39px] h-[40px] md:h-[30px] rounded-[5px] text-tcfsectext mr-4 md:w-fit"
                        >
                            Retour
                        </button>
                    )}

                    {currentIndex < responses.length - 1 && (
                        <button
                            onClick={handleNextQuestion}
                            className={`font-bold border text-base px-[36px] h-[40px] md:h-[30px] rounded-[5px] md:ml-auto bg-tcfsectext text-white
              ${!responses[currentIndex]?.response ? "cursor-not-allowed opacity-70" : "border-white hover:bg-tcfmediumgreen hover:text-tcfsectext"}
            `}
                            disabled={!responses[currentIndex]?.response}
                        >
                            Suivant
                        </button>
                    )}
                </div>


            </div>
        </div>
    );
};

export default ComprehensionTestCorrection;


