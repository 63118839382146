import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import apiClient from '../services/httpService';
import Calendar from './Calendar';
import TimePicker from './TimePicker';
import CalendarImage from "../images/calendar.png";


const ExpressionOraleBooking = ({ question, showCorrections, setActiveSection }) => {
    const dispatch = useDispatch();

    const [tests, setTests] = React.useState([]);
    const { selectedTest, questions, currentIndex } = useSelector((state) => state.questions);

    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated); // Get auth state

    const { user } = useSelector((state) => state.auth);

    // const handleBackToTests = () => {
    //     dispatch(resetSelectedTest());
    // };

    const selectedTestObj = tests.find((test) => test.id === selectedTest);
    const testName = selectedTestObj?.name

    //  console.log("selectedTest", testName)

    const getTestsForCategory = async () => {
        try {
            const response = await apiClient.get('/mgmt/tests/004/');
            if (response.status === 200) {
                setTests(response.data);
            }
        } catch (error) {
            console.error('Failed to fetch tests', error);
        }
    };

     useEffect(() => {
            getTestsForCategory();
        }, []);



        // console.log(tests)

    const today = new Date();
    const day = today.getDate();

    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedTime, setSelectedTime] = useState(null);
    const [bookedDates, setBookedDates] = useState({});
    const [bookedTimes, setBookedTimes] = useState([]);

    useEffect(() => {
        if (!selectedDate) return;

        const formattedDate = selectedDate.toISOString().split("T")[0];

        if (bookedDates[formattedDate]) {
            const acceptedTimes = bookedDates[formattedDate]
                .filter(appointment => appointment.status === "ACCEPTED")
                .map(appointment => appointment.time);

            setBookedTimes(acceptedTimes);
        } else {
            setBookedTimes([]);
        }
    }, [selectedDate, bookedDates]);

    // console.log('bookedDates', bookedDates)
    // console.log('bookedTimes', bookedTimes)




    return (

        <div className="flex flex-col items-center justify-center text-tcfsectext shadow-md min-h-screen  lg:min-h-[84vh]  mg:min-h-[110vh] xl:min-h-[100vh] bg-white rounded-[5px] border border-tcfgray1  px-[25px] md:px-[87px] lg:px-[105px] xl:px-[176px] pt-[26px] xl:pt-[102px] pb-[39px]">


            <img src={CalendarImage} alt='Calendar' className="w-[100px] h-[80px]" />
            <p className='font-bold text-lg xl:text-3xl'>Réserver un entretien oral</p>
            <p className=" text-center text-base  mb-[22px] md:mb-[48px] ">Sélectionnez une date pour prendre rendez-vous</p>
            <div className='flex flex-col md:flex-row  w-full gap-3 pb-[39px]'>
                <Calendar selectedDate={selectedDate} setSelectedDate={setSelectedDate} selectedTime={selectedTime} bookedDates={bookedDates} setBookedDates={setBookedDates} bookedTimes={bookedTimes} setBookedTimes={setBookedTimes} />
                <TimePicker selectedDate={selectedDate} selectedTime={selectedTime} setSelectedTime={setSelectedTime} bookedTimes={bookedTimes} />

            </div>
            <div className="flex flex-col  self-start  gap-[6px]">
                <div className='flex gap-[7px] self-start'>
                    <div className="w-[15px] h-[15px] bg-tcfsectext rounded-full ">

                    </div>
                    <span className='text-base '>Statut réserve</span>
                </div>
                <div className='flex gap-[7px] self-start'>
                    <div className="w-[15px] h-[15px] border border-tcfsectext  rounded-full ">

                    </div>
                    <span className='text-base ' >Statut sélectionné</span>
                </div>
                <div className='flex gap-[7px] self-start'>
                    <div className="w-[15px] h-[15px] bg-[#FFF67E] rounded-full">

                    </div>
                    <span className='text-base '>Statut en attente</span>
                </div>
                <div className='flex gap-[7px] self-start'>
                    <div className="w-[15px] h-[15px] bg-[#EB0728] rounded-full ">

                    </div>
                    <span className='text-base ' >Statut refusé</span>
                </div>
            </div>


            {/* Correction */}
            {isAuthenticated && showCorrections && (
                <div className=" w-full ">

                    <div className={`flex flex-col items-start  bg-tcfmediumgreen px-[14px]  py-[11px] rounded-[5px] gap-[13px] `}>
                        <p className='flex gap-[12px] text-base md:text-sm font-bold'>{`Correction Tache ${question?.task_number}`}</p>

                    </div>


                </div>
            )}


        </div>

    );
};

export default ExpressionOraleBooking;

