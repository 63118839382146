import React from "react";
import successIcon from "../images/successicon.svg";
import errorIcon from "../images/errorIcon.svg";
import instructionIcon from "../images/instructionicon.svg";

const Alert = ({ type = "instruction", header, message }) => {
  // Define styles based on alert type
  const alertStyles = {
    error: { color: "text-tcfred", border: "border--tcfred", bg: "bg-tcfred", iconBg: "bg-tcfred", icon: errorIcon },
    success: { color: "text-tcfgreen", border: "border-tcfgreen", bg: "bg-tcfgreen", iconBg: "bg-tcfgreen", icon: successIcon },
    instruction: { color: "text-tcfblue", border: "border-tcfblue", bg: "bg-tcfblue", iconBg: "bg-tcfblue", icon: instructionIcon },
  };

  // Get styles based on provided type
  const { color, border, bg, iconBg, icon } = alertStyles[type] || alertStyles["instruction"];

  return (
    <div className={`flex  px-[12px] text-sm py-[10px] gap-[9px] rounded-[5px] border border-tcfgray1 shadow-alert  `}>
      {/* Vertical colored bar */}
      <div className={`w-[4px] h-auto ${bg} rounded-[5px] shrink-0`}> </div> 

      {/* Icon and content */}
      <div className="flex items-start gap-[17px]">
        {/* Icon with circular background */}
        <div className={`w-[30px] h-[30px] flex items-center justify-center rounded-full shrink-0  ${iconBg}`}>
          <img src={icon} alt={`${type} icon`} className="w-[18px] h-[18px]" />
        </div>

        <div>
          <h3 className={`text-tcfsectext text-base md:text-sm font-bold `}>{header}</h3>
          <p className={`text-tcfsectext text-xs md:text-sm `}>{message}</p>
        </div>
      </div>
    </div>
  );
};

export default Alert;
