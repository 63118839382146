// SeriesButtons.js
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentIndex } from '../store/questionsSlice';

const TestButtons = ({questions}) => {
  const { currentIndex } = useSelector((state) => state.questions);
  const dispatch = useDispatch();
  return(
    <div className="grid grid-cols-3 justify-between gap-2 w-full bg-tcfbg rounded-[5px] py-[5px] px-[6px]">
      {questions?.map((question, i) => {
        let bgColor = ""; // Default background color

        if (question.revise_question && !question.response) {
          bgColor = "bg-[]"; // Revise question with no response
        }
        if (question.response) {
          bgColor = "bg-tcfmediumgreen"; // Question has a response
        }
        if (i === currentIndex) {
          bgColor = "bg-tcfsectext text-white "; // Current question
        }
        return (<button onClick={() => dispatch(setCurrentIndex(i))} key={i} className={`h-[40px]  font-bold ${bgColor} border-white rounded-[5px]  hover:bg-tcfmediumgreen  text-lg focus:bg-tcfsectext focus:text-white`}>
          Tache {question?.task_number}
        </button>)
      })}
  </div>
)};

export default TestButtons;
