// components/QuestionCard.js
import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setComprehensionWrittenResponse, setComprehensionWrittenReviseStatus, setCurrentIndex } from '../store/questionsSlice';
import apiClient from '../services/httpService';
import { setResponseId } from '../store/answerSlice';

function QuestionCard({ question, setActiveSection, showCorrections }) {
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const { questions, currentIndex, selectedTest } = useSelector((state) => state.questions);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated); // Get auth state
  const elapsedTime = useSelector((state) => state.time.elapsedTime);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSelect = (response) => {
    setSelectedAnswer(response);
    dispatch(setComprehensionWrittenResponse({ questionId: question.id, response }));
  };

  const handleReviseQuestion = () => {
    dispatch(setComprehensionWrittenReviseStatus({ questionId: question.id }));
  };

  const handleNextQuestion = useCallback(() => {
    if (currentIndex < questions.normal_questions.length - 1) {
      dispatch(setCurrentIndex(currentIndex + 1));
    }
  }, [currentIndex, dispatch, questions.normal_questions.length]);

  const handlePreviousQuestion = useCallback(() => {
    if (currentIndex > 0) {
      dispatch(setCurrentIndex(currentIndex - 1));
    }
  }, [currentIndex, dispatch]);

  const handleFinishExam = async () => {
    const formDataQuestions = questions.normal_questions;
    let valid = true;

    for (const question of formDataQuestions) {
      if (!question.response) {
        valid = false;
        alert('Please select an answer for all questions.');
        break; // Exit the loop immediately
      }
    }

    if (valid) {
      const response = await apiClient.post(`/mgmt/save-response/${selectedTest}/`, {
        questions: questions.normal_questions,
      });
      if (response.status === 201) {
        // console.log(`/results/${response?.data?.id}`);
        // console.log(response?.data);
        const responseId = response?.data?.id;
        // console.log(`🔹 Saved response ID: ${responseId}`);
        setActiveSection('comprehensionresultssummary')
        dispatch(setResponseId(responseId)); 
        // console.log(selectedTest)

        // dispatch(setUserElapsedTime(elapsedTime))
        // console.log('question page elapsedtime',elapsedTime)
        // console.log('elapsedtime',userElapsedTime)

      }
    }
  };

  const RadioButtonGroup = () => {
    const optionLabels = ['A', 'B', 'C', 'D', 'E', 'F']; // Extend if more options

    return (
      <div className="mt-[25px]">
        <div className="flex flex-col space-y-[20px] ">
          {question?.options?.map((option, i) => (
            <label
              key={i}
              onClick={() => handleSelect(option.value)}
              className={`flex  gap-x-[12px] w-full ${(showCorrections && question?.answer === option.value) ? 'bg-tcfmediumgreen text-[#01A608] border-2 border-[#01A608] p-2' : ' '} ${showCorrections?'':'cursor-pointer hover:bg-tcfbg hover:py-1 hover:px-1'} rounded-[5px]` }
             
            >
              <input
              
                type="radio"
                name={`answer-${question.text}`}
                value={option.value}
                checked={showCorrections ? question?.answer === option.value : question?.response === option.value}
                className={`mt-0.5 flex w-[18px] h-[18px] appearance-none border border-[#C1C1C180] bg-[#E5E5E5] rounded-full 
                ${showCorrections ? "checked:bg-[#01A608]" : "checked:bg-tcfsectext checked:border-tcfsectext"}  shrink-0
                  checked:shadow-[inset_0_0_0_4px_white] transition duration-200 ease-in-out
                  ${selectedAnswer === question.id ? "bg-tcfsectext" : ""}`}
                disabled={showCorrections ? true : false}
                onChange={() => handleSelect(option.value)}
              />
              <span className="font-bold ">{optionLabels[i]}</span> {option.text}

            </label>
          ))}
        </div>
      </div>
    );
  };



  return (
    <div >

      < RadioButtonGroup />
      <div className='flex flex-col md:flex-row justify-between mt-[55px] gap-y-[11px]'>
        {/* <button
          className="bg-[#3aba03] text-white py-4 rounded-md items-center justify-center mb-4"
          onClick={handleReviseQuestion}
        >Revise</button> */}


        {currentIndex > 0 && (
          <button onClick={handlePreviousQuestion} className=" font-bold border-tcfsectext border bg-white text-base px-[39px] h-[40px] md:h-[30px] rounded-[5px] text-tcfsectext md:mr-4">Retour</button>
        )}
        <div className='w-full flex flex-col-reverse md:flex-row gap-[11px]'> 
        {!showCorrections && (
          <button onClick={handleReviseQuestion} className="  flex justify-center items-center font-bold bg-tcfsectext border border-white text-base px-[39px] h-[40px] md:h-[30px] rounded-[5px] text-white  ">Revise</button>
        )}
        {currentIndex < questions.normal_questions.length - 1 && (
          <button
            onClick={handleNextQuestion}
            className={`font-bold border text-base px-[36px] h-[40px] md:h-[30px] rounded-[5px] md:ml-auto bg-tcfsectext text-white
            ${(!showCorrections && currentIndex >= questions.normal_questions.length - 1) || (!showCorrections && !questions.normal_questions[currentIndex]?.response)
                ? ' cursor-not-allowed opacity-70'
                : ' border-white hover:bg-tcfmediumgreen hover:text-tcfsectext'
              }`} disabled={(!showCorrections && currentIndex >= questions.normal_questions.length - 1) || (!showCorrections && !questions.normal_questions[currentIndex]?.response)}
          >
            Suivant
          </button>
        )}
        </div>
        {!showCorrections && (
          <>
            {currentIndex === questions?.normal_questions.length - 1 && (
              <button onClick= {isAuthenticated ? handleFinishExam : () => navigate('/tarifs')} className=" font-bold bg-tcfsectext border border-white text-base px-[36px] h-[40px] md:h-[30px] rounded-[5px] text-white md:ml-auto ">Terminer</button>
            )}
          </>
        )}
      </div>
      {/* <div className='flex justify-center items-center mt-[16px]'>
        <button onClick={handleReviseQuestion} className=" flex justify-center items-center font-bold bg-tcfsectext border border-white text-base px-[39px] h-[30px] rounded-[5px] text-white  ">Revise</button>

      </div> */}

    </div>
  );
}

export default QuestionCard;