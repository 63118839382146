// Banner.js
import React from 'react';
import bannerimage from "../images/bannerimage.jpg"

const Banner = () => (

  <div className=" flex items-center justify-between rounded-[5px]  h-[105px] md:h-[146px] bg-gradient-to-r from-[#93F9B9] to-[#1D976C] pr-[20px] text-white">
  {/* Image on the left */}
  <img 
    src={bannerimage} 
    alt="Header" 
    className="w-[82px] md:w-[288px] h-full object-cover rounded-l-[5px] rounded-r-full"
  />

  {/* Text on the right */}
  <div className="flex flex-col justify-center items-start  w-full pl-[16px] lg:pl-[20px]">
    <h2 className="text-base md:text-2xl font-bold">PRÉPAREZ VOUS AU TCF</h2>
    <p className="text-xs md:text-sm">
      Préparez-vous avec des tests précis <br className='hidden lg:block'/>
      et des <br className='hidden md:block lg:hidden'/> simulations réelles d’examen.
    </p>
  </div>
</div>

);

export default Banner;
