import React from 'react';
import { useNavigate } from 'react-router-dom';
import inactiveSubIcon from '../images/nosubscription.png'

const NoActiveSubsription = () => {
    const navigate = useNavigate()
    return (

        <div className="flex flex-col gap-[7px] items-center text-tcfsectext shadow-md min-h-[300px] md:min-h-[640px]  mg:min-h-[757px] bg-white rounded-[5px] border border-tcfgray1 px-[25px] py-[30px] xl:py-[102px]">
            <img src={inactiveSubIcon} alt={`${inactiveSubIcon}`} className='w-[150px] h-[100px] lg:w-[300px] lg:h-[209px] max-w-[301px] max-h-[209px]' />

            <p className='text-center font-bold text-lg md:text-2xl '>Aucun abonnement actif </p>
            <div>
                <p className='text-sm  text-center '>Vous n'avez actuellement aucun abonnement actif. </p>
                <p className=' text-center'>Veuillez souscrire à un plan pour profiter de nos services.</p>
            </div>
            <button
                onClick={() => navigate('/tarifs')}
                className="font-bold text-sm text-white bg-tcfsectext h-[40px] px-[49px] rounded-[5px] w-fit"
            >
                S'abonner
            </button>


        </div>

    );
};

export default NoActiveSubsription

