

import emailjs from '@emailjs/browser';

const sendEmail = async ( userAnswers) => {
    const recipientEmail = 'infomaitrisetcf@gmail.com';

    const templateParams = {
        subject: `Grade Essay for ${userAnswers?.name || "Unknown User"}`,
        to_email: recipientEmail, 
        message: 
        `   Student email:  ${userAnswers?.email || "Unknown User"}
            Tache 1: ${userAnswers?.responses?.[0] || "No response provided."}  
            Tache 2: ${userAnswers?.responses?.[1] || "No response provided."}  
            Tache 3: ${userAnswers?.responses?.[2] || "No response provided."}
        `,
    };

    try {
        const emailResponse = await emailjs.send(
            'service_il2mobk',
            'template_hqiykaq',
            templateParams,
            'vl4ozhchH0tcdII_8'
        );

        // console.log('Email sent successfully:', emailResponse);
    } catch (error) {
        console.error('Error sending email:', error);
    }
};

export default sendEmail;

