import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import arrowRight from "../images/Keyboardarrow right.svg";
import calendarIcon from "../images/calendar.svg";
import apiClient from '../services/httpService';




const Calendar = ({ selectedDate, setSelectedDate, selectedTime, bookedDates, setBookedDates }) => {



    const { user } = useSelector((state) => state.auth);
    const [loading, setLoading] = useState(false);

    const [currentDate, setCurrentDate] = useState(new Date());
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const daysOfWeek = ["LUN", "MAR", "MER", "JEU", "VEN", "SAM", "DIM"];

    const getDaysInMonth = (date) => new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();

    const getFirstDayOfMonth = (date) => {
        const firstDay = new Date(date.getFullYear(), date.getMonth(), 1).getDay();
        return firstDay === 0 ? 6 : firstDay - 1; // Adjust for Monday start
    };

    const handlePrevMonth = () => {
        if (currentDate.getFullYear() > today.getFullYear() || currentDate.getMonth() > today.getMonth()) {
            setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1));
        }
    };

    const handleNextMonth = () => {
        const nextMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1);
        const futureLimit = new Date(today.getFullYear(), today.getMonth() + 11);
        if (nextMonth <= futureLimit) {
            setCurrentDate(nextMonth);
        }
    };

    const handleDateClick = (day) => {
        const selectedFullDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), day);
        const isWeekend = selectedFullDate.getDay() === 6 || selectedFullDate.getDay() === 0;

        if ((selectedFullDate >= today || selectedFullDate.toDateString() === today.toDateString()) && !isWeekend) {
            setSelectedDate(selectedFullDate);
        }
    };

    const handleClearInput = () => {
        setSelectedDate(null);
    };

    const formatDateToFrench = (date) => {
        if (!(date instanceof Date)) {
            console.error("Invalid date:", date);
            return "";
        }
        return date.toLocaleDateString("fr-FR", {
            weekday: "long",
            day: "numeric",
            month: "long",
            year: "numeric"
        });
    };

    const handleReserve = async () => {
        if (!selectedDate) {
            alert("Veuillez sélectionner une date.");
            return;
        }
        if (!selectedTime) {
            alert("Veuillez sélectionner une heure.");
            return;
        }

        const formattedDate = `${selectedDate.getDate()}-${selectedDate.getMonth() + 1}-${selectedDate.getFullYear()}`;

        const convertTo24HourFormat = (time) => {
            const [timePart, modifier] = time?.split(" ");
            let [hours, minutes] = timePart?.split(":");

            if (modifier === "PM" && hours !== "12") {
                hours = String(parseInt(hours, 10) + 12);
            }
            if (modifier === "AM" && hours === "12") {
                hours = "00";
            }

            return `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}:00`;
        };

        const formattedTime = convertTo24HourFormat(selectedTime);
        setLoading(true); // ✅ Start loading when submitting

        try {
            const response = await apiClient.post(`/mgmt/bookings/create/`,
                {
                    user: user.id,
                    date: formattedDate,
                    time: formattedTime
                });

            if (response.status === 200 || response.status === 201) { // Ensure success
                alert("Réservation confirmée !");
            }
        } catch (error) {
            if (error.response) {
                // Server responded but with an error (4xx or 5xx)
                alert("Erreur : " + (error.response.data?.message || "Impossible de réserver."));
            } else {
                // Network error or no response from server
                alert("Erreur réseau, veuillez réessayer.");
            }
        }

        setLoading(false); // ✅ Start loading when submitting


    };



    useEffect(() => {
        const fetchBookings = async () => {
            try {
                const response = await apiClient.get(`/mgmt/bookings/`);

                const bookingsByDate = {};
                response.data.forEach(appointment => {
                    const formattedDate = appointment.date;
                    if (!bookingsByDate[formattedDate]) {
                        bookingsByDate[formattedDate] = [];
                    }
                    bookingsByDate[formattedDate].push({
                        time: appointment.time,
                        status: appointment.status
                    });
                });

                setBookedDates(bookingsByDate);
            } catch (error) {
                console.error("Error fetching bookings:", error);
            }
        };

        fetchBookings();
    }, []);



    const renderDays = () => {
        const days = [];
        const totalDays = getDaysInMonth(currentDate);
        const firstDay = getFirstDayOfMonth(currentDate);

        for (let i = 0; i < firstDay; i++) {
            days.push(<div key={`empty-${i}`} className="text-tcfinput"> </div>);
        }

        for (let day = 1; day <= totalDays; day++) {
            const date = new Date(currentDate.getFullYear(), currentDate.getMonth(), day);
            const formattedDate = date.toISOString().split("T")[0]; // ✅ Define it here

            const isPast = date < today && date.toDateString() !== today.toDateString();
            const isWeekend = date.getDay() === 6 || date.getDay() === 0;
            const isToday = date.toDateString() === today.toDateString();

            let bgColor = "";
            if (bookedDates[formattedDate]) {
                const statuses = bookedDates[formattedDate].map(apt => apt.status);
                if (statuses.includes("ACCEPTED")) bgColor = "bg-tcfsectext text-white";
                else if (statuses.includes("PENDING")) bgColor = "bg-[#FFF67E]";
                else if (statuses.includes("REJECTED")) bgColor = "bg-[#EB0728] text-white";
            }

            days.push(
                <button
    key={day}
    className={`w-[30px] h-[30px] text-base font-medium flex items-center justify-center rounded-full transition-all   
        ${bgColor}  
        ${isPast || isWeekend ? "text-tcfinput cursor-not-allowed" : "hover:bg-tcfbg"} 
        ${selectedDate && selectedDate.toDateString() === date.toDateString() ? "border-2 border-tcfsectext" : ""} 
    `}
    onClick={() => handleDateClick(day)}
    disabled={isPast || isWeekend}
>
    {day}
</button>
            );
        }
        return days;
    };

    return (
        <div className="flex flex-col gap-[11px] flex-[1.5]">
            <div className="w-full bg-tcfbg h-[40px] rounded-[5px] flex justify-between items-center pr-4 pl-3">
                <input
                    placeholder="Sélectionnez une date"
                    value={selectedDate ? formatDateToFrench(selectedDate) : ""}
                    readOnly
                    className="text-tcfinput text-base flex-1 outline-none bg-transparent"
                />
                {selectedDate ? (
                    <button onClick={handleClearInput} className="text-tcfinput text-sm">✕</button>
                ) : (
                    <img src={calendarIcon} alt="calendarIcon" />
                )}
            </div>
            <div className="text-tcfsectext pt-2 pb-[17px] w-full rounded-[5px] shadow-md border border-tcfgray1 bg-white">
                <div className="flex flex-col px-4 items-center">
                    <p className="text-sm font-bold mb-2">Calendrier</p>
                    <div className="w-full flex justify-between mb-3 bg-tcfbg h-[30px] p-1">
                        <img
                            src={arrowRight}
                            alt="arrowright"
                            onClick={handlePrevMonth}
                            className="rotate-180 bg-white w-[23px] rounded-[2px] cursor-pointer"
                        />
                        <h2 className="font-medium text-center">
                            {currentDate.toLocaleString("fr-FR", { month: "long" })} {currentDate.getFullYear()}
                        </h2>
                        <img
                            src={arrowRight}
                            alt="arrowright"
                            onClick={handleNextMonth}
                            className="bg-white w-[23px] rounded-[2px] cursor-pointer"
                        />
                    </div>
                    <div className="w-full grid grid-cols-7 justify-items-center gap-y-[14px] text-[12px] leading-[100%] mb-4 text-tcfinput">
                        {daysOfWeek.map((day) => (
                            <div key={day}>{day}</div>
                        ))}
                    </div>
                    <div className="w-full grid grid-cols-7 gap-2">{renderDays()}</div>
                </div>
                <div className="w-full border-t border-tcfgray1 mt-[22px]" />
                <div className="flex justify-center mt-[15px] w-full px-5">
                    <button
                        disabled={loading}
                        onClick={handleReserve}
                        className="flex justify-center items-center w-full bg-tcfsectext text-white h-[30px] text-base font-bold rounded-[5px]"
                    >
                        {loading ? (
                            <svg
                                className="animate-spin h-5 w-5 mr-2 text-white"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="white"
                                viewBox="0 0 24 24"
                            >
                                <circle
                                    className="opacity-25"
                                    cx="12"
                                    cy="12"
                                    r="10"
                                    stroke="white"
                                    strokeWidth="4"
                                ></circle>
                                <path
                                    className="opacity-75"
                                    fill="white"
                                    d="M4 12a8 8 0 018-8v4l3-3-3-3v4a8 8 0 00-8 8z"
                                ></path>
                            </svg>
                        ) :
                            'Réserve'}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Calendar;
