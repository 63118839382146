import React from 'react';
import { PaystackButton } from 'react-paystack';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const PaystackPayment = ({ amount, sub_id, email, password, address, first_name, last_name, country }) => {
    const publicKey = process.env.REACT_APP_PAYSTACK_PUBLIC_KEY;
    const navigate = useNavigate();
    const config = {
        reference: (new Date()).getTime().toString(),
        email: email,
        amount: amount * 100,
        publicKey,
        currency: 'GHS',
    };


    const onSuccess = async (reference) => {
        const apiURL = process.env.REACT_APP_APIURL || '';
        // Verify transaction
        const response = await axios.post(`${apiURL}/payment/paystack/verify/`, { reference: reference.reference, amount, sub_id, email, password, address, first_name, last_name, country })
        if (response.data?.status === "success") {
            navigate('/payment/confirmation?success=true&payment_type=paystack');
        }
    };

    const onClose = () => {
        console.log('Transaction was not completed.');
    };

    return (
        <>
            <PaystackButton
                text="S’inscription"
                className=" w-full bg-tcfsectext text-white font-bold text-sm px-[19px] h-[40px] rounded-[5px] mt-10 "
                {...config}
                onSuccess={onSuccess}
                onClose={onClose}
            />
        </>
    );
};

export default PaystackPayment;
