import React, { useEffect, useState } from "react";
import timerIcon from "../images/timer.svg";
import { setElapsedTime } from "../store/timeSlice";
import { useDispatch, useSelector } from "react-redux";

const ProgressBar = ({ 
  totalTime = 0, 
  timer: isTimer = true, 
  progress = 0, 
  width = "w-[100px]", 

}) => {
 
  const dispatch = useDispatch();

  const [remainingTime, setRemainingTime] = useState(totalTime);
  const storedElapsedTime = useSelector((state) => state.time.elapsedTime); // Get Redux elapsed time
  const [elapsedTime, setElapsedTimeLocal] = useState(storedElapsedTime || 0); // Initialize from Redux

  useEffect(() => {
    if (isTimer) {
      setRemainingTime(totalTime);
      const interval = setInterval(() => {
        setRemainingTime((prev) => (prev > 0 ? prev - 1 : 0));
        setElapsedTimeLocal((prev) => prev + 1); // Count elapsed time
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [totalTime, isTimer]);

  useEffect(() => {
    dispatch(setElapsedTime(elapsedTime));
  }, [elapsedTime, dispatch]);



  // Calculate progress percentage
  const progressPercentage = isTimer
    ? ((totalTime - remainingTime) / totalTime) * 100
    : progress;

 
  // Format time (MM:SS)
  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = Math.floor(timeInSeconds % 60);
    return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
  };

  return (
    <div className="flex items-center gap-[5px] w-full">
     

      {/* Progress Bar */}
      <div className={`bg-tcfmediumgreen h-[5px] ${width} rounded-full`} aria-label="Progress bar">
        <div
          className="bg-tcfsectext h-[5px] transition-all rounded-full duration-500"
          style={{ width: `${progressPercentage}%` }}
        ></div>
      </div>

      {/* Show Timer Icon for Countdown Timer */}
      {isTimer && <img src={timerIcon} alt="timer" />}

      {/* Display Remaining Time for Timer  */}
      {isTimer ? (
        <p className="text-base md:text-base font-bold text-tcfsectext">{formatTime(remainingTime)}</p>
      ) 
      : null}
    </div>
  );
};

export default ProgressBar;