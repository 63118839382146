// src/components/Checkbox.js
import React from 'react';

const Checkbox = ({ label, cstyle, lstyle, style, text='', ...props }) => (
  <div className="flex items-start ">
    <input type="checkbox" {...props} className={cstyle}  />
    <label className={lstyle}>{label}<span className={style}>{text}</span></label>
  </div>
);

export default Checkbox;
