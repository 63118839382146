import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import SeriesButtons from './SeriesButtons';

const TabView = ({ tests, testName = '' }) => {
    // const [activeTab, setActiveTab] = useState(0);

    // const tabs = ['Expression Ecrite', 'Correction Expression'];

    // Function to count words
    const countWords = (text) => {
        const words = text.trim().split(/\s+/);
        return words.filter(Boolean).length;
    };

const isAuthenticated = useSelector((state) => state.auth.isAuthenticated); // Get auth state


    return (

        <div className="w-full h-full  " >
            <div className={``}>
                {!isAuthenticated &&   (
                    <>
                   {testName.includes('Comprehension') || testName.includes( 'comprehension') ? (
                    <div className=' flex flex-col text-center justify-center items-center pb-[36px] gap-[15px]   '>

                        <h2 className=" text-3xl lg:text-7xl font-bold text-center text-white">Plus de 1000 questions pour <br />
                            preparer votre Tcf </h2>
                        <p className=" text-sm  text-white ">Des tests élaborés par des examinateurs afin de vous aider à vous entraîner dans les conditions réelles <br />
                            de l'examen du TCF et d'assurer votre réussite.</p>
                    </div>):(
               
                    <div className=' flex flex-col text-center justify-center items-center pb-[56px] gap-[15px]   '>
                           

                        <h2 className=" text-3xl lg:text-7xl font-bold  text-white">{testName}(sujets d'actualités)</h2>
                        <p className=" text-sm  text-white ">Découvrez Les nouveaux sujets de l’expression écrite qui se répètent. Pratiquez sur ces thèmes <br />
                            afin d'obtenir de bonnes notes.</p>
                    </div>
                    )}
                    </>
                    )

                }
            </div>
            <SeriesButtons tests={tests} testName={testName} />
        </div>



    );
};

export default TabView;
