/** @format */

// ResponseSection.js
import React from 'react';
import QuestionCard from './QuestionCard';
import AudioPlayer from './AudioPlayer';

const ResponseSection = ({ question, setActiveSection, showCorrections }) => {
  // const BASE_URL = "http://localhost:8000"; // Change this to match your backend URL

  const apiURL = process.env.REACT_APP_APIURL || '';
  // console.log('apiURL', apiURL);
  // const BASE_URL = "http://127.0.0.1:8000"; // Change this to match your backend URL

  const imageUrl = question?.image ? `${apiURL}${question?.image}` : null;
  const audioUrl = question?.audio ? `${apiURL}${question?.audio}` : null;

  // console.log("Image URL:", imageUrl);
  // console.log("Audio URL:", audioUrl);

  const description = question?.description ?? '';
  const words = description.split(/\s+/); // Split by whitespace
  const midIndex = Math.ceil(words.length / 2);

  const firstPart = words.slice(0, midIndex).join(' ');
  const secondPart = words.slice(midIndex).join(' ');

  return (
    <div className='flex flex-col'>
      {!imageUrl ? (

        question?.description && (

          <div className="border border-tcfsectext p-2 rounded-[5px] mt-[29px]">
            <p className=' text-sm md:text-2xl font-bold'> {firstPart}</p>
            <p className=' text-sm font-bold  '> {secondPart}</p>
          </div>)
      ) : null}

      {imageUrl && (
        <div className='flex w-auto rounded-[5px] max-w-[800px] max-h-[500px] mb-6 overflow-hidden self-center'>
          <img
            className='rounded-[5px] w-full h-full object-fit bg-cover'
            src={imageUrl}
            alt='Question'
            loading='lazy'
          />
        </div>
      )}

      <div className='mt-4 sm:mt-6 px-4 sm:px-6 py-3 text-sm rounded-[5px] bg-tcfmediumgreen'>
        {question?.text}
      </div>

      {audioUrl && <AudioPlayer audioUrl={audioUrl} />}

      <div className='mt-4 w-full'>
        <QuestionCard
          question={question}
          setActiveSection={setActiveSection}
          showCorrections={showCorrections}
        />
      </div>
    </div>
  );
};

export default ResponseSection;
