import React from 'react';

import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const TarifCard = ({ plan, duration, price, features, sub_id }) => {

    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated); // Get auth state

    const isGreen =  plan === "Platinum";
    // const isGreen = plan === "Premium" || plan === "Platinum";


    const navigate = useNavigate();


    const handleSubscribe = () => {
        
            navigate('/payment', { state: { plan, duration, price, sub_id } });
       
    };
        
    

    
    return (
        <div
            className={`w-[228px] py-[17px] rounded-[5px] flex flex-col items-center shrink-0  ${isGreen
                ? 'bg-tcfsectext text-white'
                : 'bg-white text-tcfsectext border border-tcfsectext'
                }`}
        >
            <h3 className="px-[21px] text-base font-normal">{plan}</h3>
            <p
                className={`px-[21px] text-4xl font-bold mt-2 ${isGreen ? 'text-white' : ''
                    }`}
            >
                ${price}
            </p>
            <p
                className={`px-[21px] text-lg ${isGreen ? 'text-white' : 'text-tcfsectext'
                    }`}
            >
                {duration} days
            </p>
            <button
                onClick={handleSubscribe}
                className={`font-bold text-base px-[28px] h-[30px] rounded-[5px] mt-[15px] ${isGreen ? 'bg-white text-tcfsectext' : 'bg-tcfsectext text-white'
                    }`}
            >
                S’abonner
            </button>

            <ul className="mt-[36px] space-y-[6px]">
                {features.map((feature, index) => (
                    <li
                        key={index}
                        className={`px-[21px] wrap flex items-center gap-[6px] py-[6px] text-xs ${index % 2 === 0 ? 'bg-tcfbg' : ''
                            }`}
                    >
                        {feature}
                    </li>
                ))}
            </ul>
        </div>
    );


};

export default TarifCard;





