import React, { useState } from 'react';
import HomeNavigation from '../components/Navbar';
import Footer from '../components/Footer';
import formation from '../images/section.jpg'
import formation2 from '../images/formation2.jpg'
import SubscriptionSection from '../components/Subscription';
import background from '../images/noisy-gradients.png';
import { useNavigate, useMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';



const FormationPage = () => {
    const navigate = useNavigate();

    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated); // Get auth state



    const handleNavigation = () => {
        if (isAuthenticated) {
            navigate("/dashboard"); // Redirect to dashboard if logged in
        } else {
            navigate("/tarifs"); // Otherwise, go to subscription
        }
    };
    const handleFreeCompOraleTestNavigation = () => {
        if (isAuthenticated) {
            navigate("/dashboard"); // Redirect to dashboard if logged in
        } else {
            navigate("/comprehension-orale"); // Otherwise, go to subscription
        }
    };


    return (
        <div className=" flex flex-col min-h-screen text-tcfsectext ">

            <div className=" h-[248px] md:h-[253px] lg:h-[320px] mg:h-[420px] bg-cover bg-center" style={{ backgroundImage: `url(${background})` }}>

                <HomeNavigation />

                <div className=' flex flex-col text-center justify-center items-center pt-[177px] mg:pt-[250px] gap-[15px] px-[32px]  '>

                    <h2 className=" text-3xl lg:text-7xl font-bold  text-white">Formation </h2>

                </div>

                <>

                    <div className=" h-min-screen pt-[71px] md:pt-[93px] lg:pt-[123px] mg:pt-[173px]"> {/* Padding top to avoid overlap with fixed navbar */}
                        <div className=' border border-[#C1C1C180] pt-[16px] pb-[27px] lg:pt-[38px] lg:pb-[33px] px-[13px] lg:px-[38px] mb-[45px] mx-[32px]  rounded-[5px] '>
                            <div className='flex items-start gap-[8px] lg:gap-[17px]'>

                                <div className='space-y-[7px]'>
                                    <p className='text-lg lg:text-2xl font-bold'>Objectif de la Formation</p>
                                    <p className='text-sm '>Vous envisagez d’immigrer au Canada
                                        ou de poursuivre des études supérieures
                                        dans une université canadienne ?
                                        Le Test de Connaissance du Français
                                        pour le Canada (TCF Canada) est une
                                        exigence pour prouver votre niveau de
                                        français auprès des institutions
                                        canadiennes. Nous proposons une
                                        formation en ligne pour vous aider à
                                        réussir le TCF Canada avec succès.
                                        Notre formation en ligne est dispensée
                                        par des professeurs de français
                                        expérimentés et qualifiés qui
                                        connaissent les exigences du
                                        TCF Canada. Nous vous offrons une
                                        formation personnalisée pour vous
                                        aider à améliorer vos compétences en
                                        français et à vous préparer au test.
                                        Nos cours en ligne vous permettent
                                        d'apprendre à votre propre rythme et
                                        d'adapter la formation à votre emploi
                                        du temps. Notre formation en ligne est
                                        conçue pour vous donner une
                                        compréhension approfondie des
                                        différents aspects du test TCF Canada,
                                        y compris la compréhension orale, la
                                        compréhension écrite, l'expression orale
                                        et l'expression écrite. Nous vous
                                        proposons des exercices pratiques, des
                                        simulations de test et des évaluations
                                        régulières pour vous aider à évaluer vos
                                        progrès et à améliorer vos compétences
                                        en français. En suivant notre formation
                                        en ligne, vous augmenterez
                                        considérablement vos chances de
                                        réussir le TCF Canada et de réaliser vos
                                        projets d'immigration ou d'études
                                        supérieures au Canada. Nous sommes là
                                        pour vous aider à atteindre vos objectifs
                                        et à réaliser vos rêves. N'attendez plus
                                        pour vous inscrire à notre formation en
                                        ligne TCF Canada. Rejoignez notre
                                        communauté de candidats motivés et
                                        bénéficiez de notre expertise en français
                                        pour réussir le TCF Canada. </p>
                                    <ul className='list-disc pl-5'>
                                        <li>Présentation de l'examen du TCF (test de connaissance du français).</li>
                                        <li>Un programme adapté aux besoins du Candidat.</li>
                                        <li>Identification de ses forces et ses faiblesses.</li>
                                        <li>Familiarisation avec la forme et le contenu générale des épreuves.</li>
                                        <li>La maitrise des structures de l'expression écrite et de l'expression orale (techniques, astuces, simulations, etc.).</li>
                                        <li>Entrainement intensif des exercices représentatifs et application des acquis. </li>
                                        <li>Apprentissage de la gestion du temps. </li>
                                        <li>Passation d'un examen blanc avec une correction et des conseils individualisés. </li>

                                    </ul>
                                </div>

                            </div>
                        </div>



                        <div className='mt-[47px] flex flex-col md:flex-row  mb-[62px]'>
                            <img
                                src={formation}
                                alt='formation'
                                className=' w-full h-[280px] md:w-[305px] md:h-auto  lg:w-[415px] lg:h-[379px] shrink-0  object-cover'

                            />
                            <div className=' bg-tcfmediumgreen w-full rounded-r-[5px] '>
                                <div className=' px-[33px] py-[26px]'>
                                    <div className='flex items-start gap-[8px] lg:gap-[17px] mb-[15px]'>

                                        <div className='space-y-[7px]'>
                                            <p className='text-lg lg:text-2xl font-bold'>Pendant la formation
                                            </p>

                                        </div>


                                    </div>
                                    <p className='mb-[24px]'>Francophone ou non-francophone ayant besoin pour des raisons personnelles, universitaires ou professionnelles, d’évaluer ses connaissances en français, de façon fiable et reconnue.</p>

                                    <p>Français langue étrangère ou langue secondaire dont le niveau d’entrée est supérieur à +B1</p>
                                    <p>Un test de positionnement est obligatoire (Gratuit)</p>
                                    <p>Disposer d’une autonomie informatique et d’un ordinateur avec webcam et connexion internet</p>
                                </div>

                            </div>

                        </div>

                        <div className='mt-[47px] flex flex-col md:flex-row  mb-[62px]'>
                            <img
                                src={formation2}
                                alt='formation2'
                                className=' w-full h-[280px] md:w-[305px] md:h-auto  lg:w-[415px] lg:h-[379px] shrink-0  object-cover'

                            />
                            <div className=' bg-tcfmediumgreen w-full rounded-r-[5px] '>
                                <div className=' px-[33px] py-[26px]'>
                                    <div className='flex items-start gap-[8px] lg:gap-[17px] mb-[15px]'>

                                        <div className='space-y-[7px]'>
                                            <p className='text-lg lg:text-2xl font-bold'>Profil et pre-requis

                                            </p>

                                        </div>


                                    </div>
                                    <p className='mb-[24px]'>Nous offrons un suivi particulier à chacun de nos candidats et nous nous rassurons de leur progrès dans les différentes épreuves du TCF.</p>

                                    <p>Nos cours respectent l’équilibre entre la théorie et la pratique dans le but de mieux vous aider à préparer votre test.</p>
                                    <p>Selon le déroulement de la formation qui vous sera précisé par votre formateur, selon vos avancés et vos besoins. Vous devrez prévoir de lui envoyer le travail personnel réalisé après chaque séance.</p>
                                </div>

                            </div>

                        </div>
                       




                    </div>


                    <div className='text-tcfsectext  mt-[115px] mb-[79px]'>
                        <SubscriptionSection />
                    </div>


                   
                </>
                <Footer />
            </div>
        </div>
    );
};




export default FormationPage;
