import React from 'react';

const RoundProgressBar = ({ progress = 75, size = 120, strokeWidth = 20, color = '#12372A', label=''}) => {
  const radius = (size - strokeWidth) / 2; // radius of the circle
  const circumference = 2 * Math.PI * radius; // circumference of the circle
  const strokeDasharray = circumference; // this defines the total length of the circle
  const strokeDashoffset = circumference - (progress / 100) * circumference; // this defines the length of the "remaining" part

  return (
    <div className="relative z-[1]">
      <svg
        className="top-0 left-0"
        width={size}
        height={size}
        viewBox={`0 0 ${size} ${size}`}
        xmlns="http://www.w3.org/2000/svg"
      >
        {/* Background circle */}
        <circle
          cx={size / 2}
          cy={size / 2}
          r={radius}
          stroke="#C2F7C9"
          strokeWidth={5}
          fill="none"
        />
        {/* Foreground circle */}
        <circle
          cx={size / 2}
          cy={size / 2}
          r={radius}
          stroke={color}
          strokeWidth={strokeWidth}
          fill="none"
          strokeDasharray={strokeDasharray}
          strokeDashoffset={strokeDashoffset}
          transform={`rotate(90 ${size / 2} ${size / 2})`} // Rotate to make it start from the bottom (6 o'clock position)
        />
      </svg>

      {/* Center Text */}
      <div
        className={`absolute inset-0 flex items-center justify-center ${label?'text-[22px] leading-[28.17px]':'text-xl font-bold'}`}
        style={{ color: color }}
      >
       {label ? label : `${progress}%`}
      </div>
    </div>
  );
};

export default RoundProgressBar;
