// DashboardPage.js
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Sidebar from '../components/Sidebar';
import Header from '../components/Header';
import HomeNavigation from '../components/Navbar';
import ProfileSection from '../components/ProfileSection'
import ExpressionEcriteSection from '../components/ExpressionEcriteSection';
import ExpressionOraleSection from '../components/ExpressionOraleSection';
import ComprenensionEcriteSection from '../components/ComprehensionEcriteSection'
import ComprehensionOraleSection from '../components/ComprehensionOraleSection'
import { resetSelectedTest } from '../store/questionsSlice';
import background from '../images/noisy-gradients.png'
import ComprehensionResultsSummary from '../components/ComprehensionResultsSummary';
import SubscriptionUpgrade from '../components/SubscriptionUpgrade';
import AccountPage from '../components/AccountsSection';
import ComprenensionEcriteCorrectionSection from '../components/ComprehensionEcriteSection'
import ComprehensionOraleCorrectionSection from '../components/ComprehensionOraleSection'
import ExpressionEcriteCorrectionSection from '../components/ExpressionEcriteSection';
import SubscriptionStatusChecker from '../components/SubscriptionChecker';
import apiClient from '../services/httpService';
import NoActiveSubsription from '../components/NoActiveSubcription';

const DashboardPage = () => {

  const dispatch = useDispatch();
  const [userSubscriptions, setUserSubscriptions] = useState();

  useEffect(() => {
    const handleNavigation = () => {
      dispatch(resetSelectedTest()); // Reset selectedTest when user navigates back or forward
    };

    window.addEventListener("popstate", handleNavigation);

    return () => {
      window.removeEventListener("popstate", handleNavigation);
    };
  }, [dispatch]);

  const getUserSubscriptions = async () => {
    try {
      const response = await apiClient.get('/user/api/user-subscriptions/'); //Comprehension Ecrite
      if (response.status === 200) {
        setUserSubscriptions(response?.data?.results);
      }
    } catch (error) {
      console.error('Failed to fetch tests', error);
    }
  };

  useEffect(() => {
    getUserSubscriptions();
  }, []);




  const { selectedTest } = useSelector((state) => state.questions);
  // console.log('selectedTest', selectedTest)


  // State to keep track of active section
  const [activeSection, setActiveSection] = useState('profilesection');

  // Reset sidebar visibility on refresh
  useEffect(() => {
    dispatch(resetSelectedTest()); // Ensures sidebar is visible on refresh
  }, [dispatch]);

  const [showCorrections, setShowCorrections] = useState(false);

  const subscriptionStatus = useSelector((state) => state.auth.subscriptionStatus);
  // console.log('subscriptionStatus', subscriptionStatus)


  // Function to render the section content based on `activeSection`
  const renderSectionContent = () => {
    switch (activeSection) {
      case 'profilesection':
        return <><ProfileSection /> <SubscriptionStatusChecker /></>;
      case 'expressionecritesection':
        return subscriptionStatus === 'Active' ? <ExpressionEcriteSection showCorrections={showCorrections} setActiveSection={setActiveSection} /> : <NoActiveSubsription />;
      case 'expressionecritecorrectionsection':
        return subscriptionStatus === 'Active' ? <ExpressionEcriteCorrectionSection showCorrections={!showCorrections} /> : <NoActiveSubsription />;
      case 'expressionoralesection':
        return subscriptionStatus === 'Active' ? <ExpressionOraleSection /> : <NoActiveSubsription />;
      case 'expressionoralecorrectionsection':
        return subscriptionStatus === 'Active' ? <ExpressionOraleSection showCorrections={!showCorrections} /> : <NoActiveSubsription />;
      case 'comprehensionecritesection':
        return subscriptionStatus === 'Active' ? <ComprenensionEcriteSection setActiveSection={setActiveSection} showCorrections={showCorrections} /> : <NoActiveSubsription />;
      case 'comprehensionecritecorrectionsection':
        return subscriptionStatus === 'Active' ? <ComprenensionEcriteCorrectionSection showCorrections={!showCorrections} /> : <NoActiveSubsription />;
      case 'comprehensionoralesection':
        return subscriptionStatus === 'Active' ? <ComprehensionOraleSection setActiveSection={setActiveSection} showCorrections={showCorrections} /> : <NoActiveSubsription />;
      case 'comprehensionoralecorrectionsection':
        return subscriptionStatus === 'Active' ? <ComprehensionOraleCorrectionSection showCorrections={!showCorrections} /> : <NoActiveSubsription />;
      case 'comprehensionresultssummary':
        return <ComprehensionResultsSummary />;

      case 'accountsettings':
        return <AccountPage />;

      // Add more cases as needed
      default:
        return <ProfileSection /> // Default section
    }
  };

  const latestSubscription = userSubscriptions?.length > 0
    ? userSubscriptions.at(-1)
    : null;


  // console.log('curentplanontab', latestSubscription?.subscription_details?.plan_name)

  // console.log('latestSubscription?.subscription_details?.sub_type', latestSubscription?.subscription_details?.sub_type)


  return (
    <div className="  flex flex-col justify-between min-h-screen  "  >
      <div className="w-full flex flex-col px-[32px] mg:px-[65px] h-[658px] lg:[495px] bg-cover bg-center " style={{
        backgroundImage: `url(${background})`,
      }}>
        <HomeNavigation />
        {/* <SubscriptionStatusChecker /> */}
        {/* <div className="w-full flex flex-col lg:flex-row  gap-[16px] justify-between pt-[153px] lg:pt-[197px] pb-[59px]"> */}
        <div
          className={`w-full grid gap-[16px] justify-between pt-[153px] lg:pt-[197px] pb-[59px] 
        ${selectedTest ? "grid-cols-1" : "grid-cols-1 lg:grid-cols-[minmax(228px,25%)_1fr] mg:grid-cols-[minmax(316px,25%)_1fr]"}`}
        >
          <div className='lg:hidden '>{!selectedTest && <Header setActiveSection={setActiveSection} currentPlan={latestSubscription?.subscription_details?.plan_name} currentSubType={latestSubscription?.subscription_details?.sub_type} />}</div>
          {!selectedTest ? (<Sidebar setActiveSection={setActiveSection} currentPlan={latestSubscription?.subscription_details?.plan_name} currentSubType={latestSubscription?.subscription_details?.sub_type} />) : ''}
          <div className=' flex flex-col justify-between gap-[24px]  w-full flex-[4]  '>
            <div className='hidden lg:block '>{!selectedTest && <Header />}</div>
            <div className="w-full">

              {renderSectionContent()}
              <div className='md:hidden mt-10'>
                {!selectedTest ? (<SubscriptionUpgrade currentPlan={latestSubscription?.subscription_details?.plan_name} currentSubType={latestSubscription?.subscription_details?.sub_type} />) : ''}
              </div>
            </div>
          </div>

        </div>
      </div>

    </div>

  );
}

export default DashboardPage;


