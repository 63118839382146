import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import HomeNavigation from '../components/Navbar';
import Footer from '../components/Footer';
import formation from '../images/section.jpg'
import SubscriptionSection from '../components/Subscription';
import background from '../images/noisy-gradients.png';


const ExpressionOralePage = () => {
    const navigate = useNavigate();

    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated); // Get auth state

    const handleNavigation = () => {
        if (isAuthenticated) {
            navigate("/dashboard"); // Redirect to dashboard if logged in
        } else {
            navigate("/tarifs"); // Otherwise, go to subscription
        }

    }


    const handleFreeExpEcriteTestNavigation = () => {
        if (isAuthenticated) {
            navigate("/dashboard"); // Redirect to dashboard if logged in
        } else {
            navigate("/expression-orale"); // Otherwise, go to subscription
        }
    };

    return (
        <div className=" flex flex-col min-h-screen text-tcfsectext ">

            <div className=" h-[560px] md:h-[473px] lg:h-[520px] bg-cover bg-center" style={{ backgroundImage: `url(${background})` }}>

                <HomeNavigation />

                <div className=' flex flex-col text-center justify-center items-center pt-[184px] mg:pt-[225px] gap-[15px] px-[32px]  '>

                    <h2 className=" text-3xl lg:text-7xl font-bold  text-white">Expression Orale</h2>
                    <p className=" text-sm  text-white ">Afin de vous préparer au mieux au TCF et booster votre score, nous mettons à votre disposition des tests qui <br />
                        réunissent les mêmes conditions que lors d’une session officielle</p>
                    <div className='flex flex-col md:flex-row  gap-[18px] mt-[13px]'>
                        <button onClick={handleFreeExpEcriteTestNavigation} className="bg-white  text-tcfsectext font-bold text-sm px-[50px] h-[40px] rounded-[5px]  "> Sujets d’actualités</button>
                        <button onClick={handleNavigation} className=" font-bold border border-white text-sm px-[30px] h-[40px] rounded-[5px] text-white ">S’entraîner</button>

                    </div>
                </div>

                <div className='pt-[168px] lg:pt-[200px]'>
                    {/* Padding top to avoid overlap with fixed navbar */}


                    <div className=' border border-[#C1C1C180] pt-[8px] pb-[14px] lg:pt-[16px] lg:pb-[16px] px-[13px] lg:px-[38px] mb-[45px] mx-[32px]  rounded-[5px] '>
                        <div className='flex items-start gap-[8px] lg:gap-[17px]'>

                            <div className='space-y-[7px]'>
                                <p>Guide Complet pour Réussir l’Épreuve d’Expression Orale du TCF Canada
                                    L’épreuve d’expression orale du TCF Canada est une étape essentielle pour évaluer votre capacité à vous exprimer clairement et spontanément en français. Pour bien vous préparer, il est important de connaître la structure de l’épreuve, les critères d’évaluation et les types de sujets auxquels vous serez confronté. Ce guide vous apportera toutes les informations nécessaires pour réussir cette épreuve avec sérénité.
                                </p>

                            </div>

                        </div>
                    </div>

                    <div className=' border border-[#C1C1C180] pt-[16px] pb-[27px] lg:pt-[38px] lg:pb-[33px] px-[13px] lg:px-[38px] mb-[45px] mx-[32px]  rounded-[5px] '>
                        <div className='flex items-start gap-[8px] lg:gap-[17px]'>
                            <div className="relative bg-tcfmediumgreen text-sm font-bold lg:text-2xl rounded-full flex items-center text-tcfsectext p-1.5 lg:p-4  ">
                                01
                            </div>
                            <div className='space-y-[7px]'>
                                <p className='text-lg lg:text-2xl font-bold'>Structure de l’Épreuve d’Expression Orale du TCF Canada</p>
                                <p>L’épreuve d’expression orale dure 12 minutes et se déroule sous forme d’un entretien individuel avec un examinateur. Elle est divisée en trois tâches distinctes, chacune évaluant une compétence particulière en interaction et en prise de parole.</p>
                                <p>Les trois tâches de l’épreuve :</p>


                                <ul class="list-disc pl-5 space-y-1">
                                    <li>
                                        <strong>Tâche 1</strong>   Entretien dirigé (2 minutes, sans préparation)
                                        L’objectif est de vérifier si le candidat peut échanger avec une personne inconnue en répondant à des questions simples sur des sujets personnels.

                                    </li>
                                    <li>
                                        <strong>Tâche 2</strong>Interaction avec préparation (5 minutes 30 secondes)
                                        Le candidat doit poser des questions pertinentes à l’examinateur pour obtenir une information spécifique dans une situation de la vie courante. Cette partie teste la capacité à mener une conversation et à formuler des demandes claires.
                                    </li>
                                    <li>
                                        <strong>Tâche 3</strong> Expression d’un point de vue (4 minutes 30 secondes, sans préparation)
                                        Le candidat doit exprimer spontanément son opinion sur un sujet donné, en structurant son discours et en argumentant de manière convaincante.
                                    </li>
                                </ul>

                            </div>

                        </div>
                    </div>


                    <div className=' border border-[#C1C1C180] pt-[16px] pb-[27px] lg:pt-[38px] lg:pb-[33px] px-[13px] lg:px-[38px] mb-[45px] mx-[32px]  rounded-[5px] '>
                        <div className='flex items-start gap-[8px] lg:gap-[17px]'>
                            <div className="relative bg-tcfmediumgreen text-sm font-bold lg:text-2xl rounded-full flex items-center text-tcfsectext p-1.5 lg:p-4  ">
                                02
                            </div>
                            <div className='space-y-[7px]'>
                                <p className='text-lg lg:text-2xl font-bold'>Notation de l’Épreuve d’Expression Orale</p>
                                <p> <strong>Barème de notation : </strong></p>
                                <p>L’épreuve est notée sur 20 points et le score obtenu est converti selon l’échelle du CECRL (Cadre Européen Commun de Référence pour les Langues), permettant d’évaluer le niveau du candidat.</p>
                                <p>Niveau CECRL	Compétences évaluées</p>

                                <ul class="list-disc pl-5 space-y-4">
                                    <li>
                                        A1  : Décrire en termes simples son environnement et les personnes qu’il connaît.

                                    </li>
                                    <li>
                                        A2 : Parler de son quotidien, de ses expériences et de son travail avec des phrases simples.

                                    </li>
                                    <li>
                                        B1 :  Raconter un événement, exprimer ses réactions et donner des explications sur un projet.

                                    </li>
                                    <li>
                                        B2 :  Exprimer une opinion de manière détaillée et nuancée, comparer des idées.

                                    </li>
                                    <li>
                                        C1 : Présenter et développer des idées complexes avec clarté et cohérence.

                                    </li>
                                    <li>
                                        C2 : Structurer une argumentation fluide et précise sur des sujets abstraits et complexes.

                                    </li>

                                </ul>

                            </div>

                        </div>
                    </div>


                    <div className=' border border-[#C1C1C180] pt-[16px] pb-[27px] lg:pt-[38px] lg:pb-[33px] px-[13px] lg:px-[38px] mb-[45px] mx-[32px]  rounded-[5px] '>
                        <div className='flex items-start gap-[8px] lg:gap-[17px]'>
                            <div className="relative bg-tcfmediumgreen text-sm font-bold lg:text-2xl rounded-full flex items-center text-tcfsectext p-1.5 lg:p-4  ">
                                03
                            </div>
                            <div className='space-y-[7px]'>
                                <p className='text-lg lg:text-2xl font-bold'>Critères d’Évaluation</p>
                                <p>L’évaluation de l’épreuve repose sur trois critères principaux, qui déterminent la qualité de votre expression orale.</p>
                                <ol class="list-decimal pl-5 space-y-4">
                                    <li>
                                        <p> <strong>Critères linguistiques

                                        </strong></p>
                                        <ul class="list-disc pl-5 space-y-4">
                                            <li>
                                                Précision et richesse du vocabulaire.
                                            </li>
                                            <li>
                                                Correction grammaticale et syntaxique.
                                            </li>
                                            <li>
                                                Fluidité et aisance dans l’expression orale.
                                            </li>
                                            <li>
                                                Prononciation et intonation.
                                            </li>
                                        </ul>
                                    </li>

                                    <li>
                                        <p> <strong>Critères pragmatiques

                                        </strong></p>
                                        <ul class="list-disc pl-5 space-y-4">
                                            <li>
                                                Capacité à interagir avec l’examinateur.
                                            </li>
                                            <li>
                                                Capacité à interagir avec l’examinateur.
                                            </li>
                                            <li>
                                                Dévelopement et organisation des idées.

                                            </li>
                                        </ul>
                                    </li>

                                    <li>
                                        <p> <strong>Critères sociolinguistiques

                                        </strong></p>
                                        <ul class="list-disc pl-5 space-y-4">
                                            <li>
                                                Pertinence du discours en fonction de la situation de communication.
                                            </li>
                                            <li>
                                                Capacité à adapter son langage au contexte et à s’exprimer de manière naturelle.
                                            </li>
                                        </ul>
                                    </li>
                                </ol>
                                <p> Évaluation en double aveugle : L’enregistrement de l’entretien est corrigé indépendamment par deux correcteurs, garantissant une notation impartiale.
                                </p>

                            </div>

                        </div>
                    </div>


                    <div className=' border border-[#C1C1C180] pt-[16px] pb-[27px] lg:pt-[38px] lg:pb-[33px] px-[13px] lg:px-[38px] mb-[45px] mx-[32px]  rounded-[5px] '>
                        <div className='flex items-start gap-[8px] lg:gap-[17px]'>
                            <div className="relative bg-tcfmediumgreen text-sm font-bold lg:text-2xl rounded-full flex items-center text-tcfsectext p-1.5 lg:p-4  ">
                                04
                            </div>
                            <div className='space-y-[7px]'>
                                <p className='text-lg lg:text-2xl font-bold'>Exemples de Sujets d’Expression Orale du TCF Canada
                                    L’épreuve comprend trois tâches correspondant à des types d’interaction différents. Voici quelques exemples de sujets pour chaque tâche :</p>
                                <p> <strong>Tâche 1 - </strong> Entretien dirigé (2 minutes, sans préparation)
                                    L’examinateur vous posera des questions personnelles auxquelles vous devrez répondre spontanément.</p>

                                <p>📌 Exemples de questions :</p>
                                <ul class="list-disc pl-5 space-y-4">
                                    <li>
                                        Pouvez-vous vous présenter ?
                                    </li>
                                    <li>
                                        Quel est votre film préféré ? Pourquoi ?
                                    </li>
                                    <li>
                                        Où avez-vous appris le français ?
                                    </li>
                                    <li>
                                        Comment imaginez-vous votre vie dans 30 ans ?
                                    </li>
                                </ul>

                                <p> <strong>Tâche 2 - </strong> Interaction (5 minutes 30 secondes, avec préparation)</p>

                                <p>📌 Exemples de situations :</p>
                                <ul class="list-disc pl-5 space-y-4">
                                    <li>
                                        Vous cherchez une personne pour garder votre chat pendant vos vacances. Posez des questions pour vérifier si elle est fiable.
                                    </li>
                                    <li>
                                        Vous vous interrogez sur les transports en commun en France. Posez des questions sur leur organisation.
                                    </li>
                                    <li>
                                        Vous souhaitez rejoindre une association d’aide aux personnes en difficulté. Renseignez-vous sur son fonctionnement.
                                    </li>

                                </ul>

                                <p> <strong>Tâche 3 - </strong>Expression d’un point de vue (4 minutes 30 secondes, sans préparation)
                                Vous devez argumenter spontanément sur un sujet donné.</p>

                                <p>📌 Exemples de questions :</p>
                                <ul class="list-disc pl-5 space-y-4">
                                    <li>
                                    Faut-il interdire la vente d’alcool aux mineurs ?
                                    </li>
                                    <li>
                                    Les voyages rendent-ils plus heureux ? Justifiez votre réponse.
                                    </li>
                                    <li>
                                    Les moyens de communication modernes améliorent-ils réellement les échanges entre les gens ?
                                    </li>

                                </ul>
                                <p><strong>Objectif:</strong> Structurer vos arguments, utiliser des exemples concrets et exprimer une opinion de manière fluide.</p>

                            </div>

                        </div>
                    </div>


                    <div className=' border border-[#C1C1C180] pt-[16px] pb-[27px] lg:pt-[38px] lg:pb-[33px] px-[13px] lg:px-[38px] mb-[45px] mx-[32px]  rounded-[5px] '>
                        <div className='flex items-start gap-[8px] lg:gap-[17px]'>
                            <div className="relative bg-tcfmediumgreen text-sm font-bold lg:text-2xl rounded-full flex items-center text-tcfsectext p-1.5 lg:p-4  ">
                                05
                            </div>
                            <div className='space-y-[7px]'>
                                <p className='text-lg lg:text-2xl font-bold'>Comment Bien Se Préparer à l’Expression Orale du TCF Canada ?</p>
                                
                                <ol class="list-decimal pl-5 space-y-4">
                                    <li>
                                        <p> <strong> Pratiquer régulièrement l’oral

                                        </strong></p>
                                        <ul class="list-disc pl-5 space-y-4">
                                            <li>
                                            S’entraîner à parler à voix haute pour améliorer la fluidité et l’aisance.
                                            </li>
                                            <li>
                                            Faire des simulations d’entretien avec un partenaire ou un professeur.
                                            </li>
                                           
                                        </ul>
                                    </li>

                                    <li>
                                        <p> <strong>Travailler la structuration du discours

                                        </strong></p>
                                        <ul class="list-disc pl-5 space-y-4">
                                            <li>
                                            Apprendre à introduire, développer et conclure ses idées clairement.
                                            </li>
                                            <li>
                                            Utiliser des connecteurs logiques pour enchaîner les arguments.
                                            </li>
                                        
                                        </ul>
                                    </li>

                                    <li>
                                        <p> <strong>Améliorer son vocabulaire et sa prononciation

                                        </strong></p>
                                        <ul class="list-disc pl-5 space-y-4">
                                            <li>
                                            Lire des articles et regarder des vidéos pour enrichir son lexique.
                                            </li>
                                            <li>
                                            Travailler la prononciation et l’intonation avec des exercices d’écoute.
                                            </li>
                                        </ul>
                                    </li>

                                    <li>
                                        <p> <strong>Gérer son stress le jour de l’épreuve
                                        </strong></p>
                                        <ul class="list-disc pl-5 space-y-4">
                                            <li>
                                            Respirer profondément et prendre son temps avant de répondre.
                                            </li>
                                            <li>
                                            Ne pas paniquer en cas de trou de mémoire : reformuler ou expliquer autrement.
                                            </li>
                                        </ul>
                                    </li>
                                </ol>

                                       

                                
                            </div>

                        </div>
                    </div>


                    <div className=' border border-[#C1C1C180] pt-[16px] pb-[27px] lg:pt-[38px] lg:pb-[33px] px-[13px] lg:px-[38px] mb-[45px] mx-[32px]  rounded-[5px] '>
                        <div className='flex items-start gap-[8px] lg:gap-[17px]'>
                            <div className="relative bg-tcfmediumgreen text-sm font-bold lg:text-2xl rounded-full flex items-center text-tcfsectext p-1.5 lg:p-4  ">
                                06
                            </div>
                            <div className='space-y-[7px]'>
                                <p className='text-lg lg:text-2xl font-bold'>Peut-on s’entraîner à l’expression orale sur <a className='underline font-normal 'href='https://maitrisetcf.com/'>MaitriseTCF.com </a>?
                                Oui ! MaitriseTCF.com propose :</p>
                                <p>Vous allez bientôt passer le TCF Canada ? Nous allons vous donner quelques informations concernant l’expression orale ! En effet, vous devez comprendre les différents exercices que vous allez devoir passer lors de votre examen. De plus, nous vous aiderons à vous préparer pour que ce dernier se passe pour le mieux !</p>
                                <p>Votre Test de Connaissance du Français approche et vous avez choisi de passer l’épreuve complémentaire d’expression orale ?</p>
                                <p>Afin de vous aider à comprendre ce que l’on attend de vous le jour de l’examen, nous vous expliquons les étapes à connaître et à franchir pour que cette épreuve soit une réussite :</p>


                                <ul class="list-disc pl-5 space-y-1">
                                    <li>
                                        Comment se structure l’épreuve d’expression orale ?
                                    </li>
                                    <li>
                                        Comment est-elle notée ?
                                    </li>
                                    <li>
                                        Quels sont les types d’exercices que vous aurez à réaliser ?
                                    </li>
                                    <li>
                                        Quels sont les conseils pour réussir cette épreuve ?
                                    </li>
                                    <li>Pouvez-vous vous entraîner à l’expression orale sur la plateforme <strong>Tcfcatest.com ?</strong></li>
                                </ul>
                                <p>Suivez-nous dans ce petit tour d’horizon pour une préparation plus sereine de cette épreuve.</p>

                            </div>

                        </div>
                    </div>


                    <div className='text-tcfsectext  mt-[115px] mb-[79px]'>
                        <SubscriptionSection />
                    </div>

                </div>
                <Footer />

            </div>

        </div>
    );


};

export default ExpressionOralePage;




