/** @format */

// src/pages/SignUpPage.js
import React, { useState, useEffect } from 'react';
import TarifCard from '../components/Tarifs';
import apiClient from '../services/httpService';

const SubscriptionSection = () => {
  const [planOptions, setPlanOptions] = useState([]);

  useEffect(() => {
    const fetchSubscriptions = async () => {
      try {
        const response = await apiClient.get('/mgmt/available-subscriptions/');
        if (response.status === 200) {
          const options = response.data.map((subscription) => ({
            sub_id: subscription.id,
            plan: subscription.plan_name,
            value: subscription.id,
            price: subscription.amount,
            duration: subscription.plan_duration,
            sub_type: subscription.sub_type,
            features: subscription.plan_items ? subscription.plan_items.split('|').map(item => item.trim()) : ['5 seances, Compréhension Écrite, Compréhension Orale',
        '5 seances, Compréhension Écrite, Compréhension Orale',
        '5 seances, Compréhension Écrite, Compréhension Orale',
        '5 seances, Compréhension Écrite, Compréhension Orale',
        '5 seances, Compréhension Écrite, Compréhension Orale',
        '5 seances, Compréhension Écrite, Compréhension Orale',
        '5 seances, Compréhension Écrite, Compréhension Orale',],
          }));

          // console.log('options', options);
          //  console.log('response', response);
          setPlanOptions(options);
        }
      } catch (error) {
        console.error('Error fetching subscriptions', error);
      }
    };

    fetchSubscriptions();
  }, []);

  // console.log('planOptions', planOptions);


  const TrainingData = [
    {
      plan: 'Standard',
      duration: '20 ',
      price: '79.99',
      features: [
        '5 seances, Compréhension Écrite, Compréhension Orale',
        '5 seances, Compréhension Écrite, Compréhension Orale',
        '5 seances, Compréhension Écrite, Compréhension Orale',
        '5 seances, Compréhension Écrite, Compréhension Orale',
        '5 seances, Compréhension Écrite, Compréhension Orale',
        '5 seances, Compréhension Écrite, Compréhension Orale',
        '5 seances, Compréhension Écrite, Compréhension Orale',
      ],
    },
    {
      plan: 'Essential',
      duration: '30 ',
      price: '99.99',
      features: [
        '5 seances, Compréhension Écrite, Compréhension Orale',
        '5 seances, Compréhension Écrite, Compréhension Orale',
        '5 seances, Compréhension Écrite, Compréhension Orale',
        '5 seances, Compréhension Écrite, Compréhension Orale, ',
        '5 seances, Compréhension Écrite, Compréhension Orale',
        '5 seances, Compréhension Écrite, Compréhension Orale',
        '5 seances, Compréhension Écrite, Compréhension Orale',
      ],
    },
    {
      plan: 'Premium',
      duration: '30 ',
      price: '129.99',
      features: [
        '5 seances, Compréhension Écrite, Compréhension Orale',
        '5 seances, Compréhension Écrite, Compréhension Orale',
        '5 seances, Compréhension Écrite, Compréhension Orale',
        '5 seances, Compréhension Écrite, Compréhension Orale',
        '5 seances, Compréhension Écrite, Compréhension Orale',
        '5 seances, Compréhension Écrite, Compréhension Orale',
        '5 seances, Compréhension Écrite, Compréhension Orale',
      ],
    },
    {
      plan: 'Platinum',
      duration: '30 ',
      price: '169.99',
      features: [
        '5 seances, Compréhension Écrite, Compréhension Orale',
        '5 seances, Compréhension Écrite , Compréhension Orale',
        '5 seances, Compréhension Écrite, Compréhension Orale',
        '5 seances, Compréhension Écrite, Compréhension Orale',
        '5 seances, Compréhension Écrite, Compréhension Orale',
        '5 seances, Compréhension Écrite, Compréhension Orale',
        '5 seances, Compréhension Écrite, Compréhension Orale',
      ],
    },
  ];

  return (
    <div className='flex flex-col min-h-screen justify-between'>
      {/* Map over cardData and pass each item to the TarifCard component */}

      <div className='flex flex-col gap-[144px]'>
        <div className='flex flex-col justify-center w-full'>
          {/* Section Title */}
          <div className='text-tcfsectext text-center mb-[24px]  px-[32px]'>
            <p className='text-3xl md:text-5xl lg:text-7xl font-bold'>
              Nos tests sont tirés de l'examen réel du TCF
            </p>
            <p className='text-sm'>
              Faites vos choix, enregistrez-vous, et commencez aujourd'hui
            </p>
          </div>

          {/* Scrollable Cards Container */}
          <div className='flex justify-center w-full '>
            <div className='flex gap-[16px] pl-[27px] lg:pl-0 last:pr-[27px] lg:last:pr-0 overflow-x-auto flex-nowrap scrollbar-hide '>
              {planOptions
                .filter((card) => card.plan.toLowerCase() !== "atry") // Exclude 'atry' plan
                .filter((card) => card.sub_type === "Examen") // Keep only 'Basic' sub_type
                .sort((a, b) => a.price - b.price) // Sort in ascending order by price
                .map((card, index) => (
                  <div key={index} className="snap-start shrink-0">
                    <TarifCard
                      sub_id={card.sub_id}
                      plan={card.plan}
                      duration={card.duration}
                      price={card.price}
                      features={card.features}
                      
                    />
                  </div>
                ))}
            </div>
          </div>
        </div>

        {/* <div>
                    <div className='text-tcfsectext  text-center mb-[24px]'>
                        <p className=" text-3xl md:text-5xl lg:text-7xl  font-bold ">Nos tests sont tirés de l'examen réel du TCF</p>
                        <p className='text-sm  '>Faites vos choix, enregistrez-vous, et commencez aujourd'hui</p>
                    </div>
                    <div className="flex gap-[16px] justify-center overflow-x-auto flex-nowrap snap-x snap-mandatory scrollbar-hide pl-[27px] lg:pl-0 last:pr-[27px] lg:last:pr-0">
                        {planOptions.map((card, index) => (
                            <TarifCard
                                key={index}
                                plan={card.plan}
                                duration={card.duration}
                                price={card.price}
                                features={card.features}
                            />
                        ))}
                    </div>
                </div> */}

        <div className='flex flex-col justify-center w-full'>
          {/* Section Title */}
          <div className='text-tcfsectext text-center mb-[24px] px-[32px]'>
            <p className='text-3xl md:text-5xl lg:text-7xl font-bold'>
              Nos forfaits pour formation chez nous
            </p>
            <p className='text-sm'>
              Faites vos choix, enregistrez-vous, et commencez aujourd'hui
            </p>
          </div>

          {/* Scrollable Cards Container */}
          <div className='flex justify-center w-full'>
            <div className='flex gap-[16px] pl-[27px] lg:pl-0 last:pr-[27px] lg:last:pr-0 overflow-x-auto flex-nowrap  scrollbar-hide'>
            {planOptions
                .filter((card) => card.plan.toLowerCase() !== "atry") // Exclude 'atry' plan
                .filter((card) => card.sub_type === "Formation") // Keep only 'Basic' sub_type
                .sort((a, b) => a.price - b.price) // Sort in ascending order by price
                .map((card, index) => (
                  <div key={index} className="snap-start shrink-0">
                    <TarifCard
                      sub_id={card.sub_id}
                      plan={card.plan}
                      duration={card.duration}
                      price={card.price}
                      features={card.features}
                    />
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionSection;
