import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import HomeNavigation from '../components/Navbar';
import Footer from '../components/Footer';
import formation from '../images/section.jpg'
import SubscriptionSection from '../components/Subscription';
import background from '../images/noisy-gradients.png';


const ExpressionEcritePage = () => {
    const navigate = useNavigate();

    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated); // Get auth state

    const handleNavigation = () => {
        if (isAuthenticated) {
            navigate("/dashboard"); // Redirect to dashboard if logged in
        } else {
            navigate("/tarifs"); // Otherwise, go to subscription
        }

    }


    const handleFreeExpEcriteTestNavigation = () => {
        if (isAuthenticated) {
            navigate("/dashboard"); // Redirect to dashboard if logged in
        } else {
            navigate("/expression-ecrite"); // Otherwise, go to subscription
        }
    };

    return (
        <div className=" flex flex-col min-h-screen text-tcfsectext ">

            <div className=" h-[560px] md:h-[473px] lg:h-[520px] bg-cover bg-center" style={{ backgroundImage: `url(${background})` }}>

                <HomeNavigation />

                <div className=' flex flex-col text-center justify-center items-center pt-[184px] mg:pt-[225px] gap-[15px] px-[32px]  '>

                    <h2 className=" text-3xl lg:text-7xl font-bold  text-white">Expression Ecrite </h2>
                    <p className=" text-sm  text-white ">Afin de vous préparer au mieux au TCF et booster votre score, nous mettons à votre disposition des tests qui <br />
                        réunissent les mêmes conditions que lors d’une session officielle</p>
                    <div className='flex flex-col md:flex-row  gap-[18px] mt-[13px]'>
                        <button onClick={handleFreeExpEcriteTestNavigation} className="bg-white  text-tcfsectext font-bold text-sm px-[50px] h-[40px] rounded-[5px]  "> Sujets d’actualités</button>
                        <button onClick={handleNavigation} className=" font-bold border border-white text-sm px-[30px] h-[40px] rounded-[5px] text-white ">S’entraîner</button>

                    </div>
                </div>

                <div className='pt-[168px] lg:pt-[200px]'>
                    {/* Padding top to avoid overlap with fixed navbar */}
                    <div className=' border border-[#C1C1C180] pt-[16px] pb-[27px] lg:pt-[38px] lg:pb-[33px] px-[13px] lg:px-[38px] mb-[45px] mx-[32px]  rounded-[5px] '>
                        <div className='flex flex-col items-start gap-[26px]'>

                            <div className='space-y-[7px]'>
                                <p className=' text-lg lg:text-2xl font-bold'>TCF Canada Expression Écrite: Introduction</p>
                                <p className='text-sm '>Cette épreuve d’expression écrite dure : 60 minutes, Cette épreuve comporte 3 tâches <br />
                                    Vous êtes évalué sur vos capacités à :</p>
                            </div>

                            <div className="grid grid-cols-1 md:grid-cols-2 md:gap-4">
                                <ol className="list-decimal list-inside ">
                                    <li>Communiquer un message de façon claire.</li>
                                    <li>Donner les informations demandées.</li>
                                    <li>Décrire, raconter, expliquer.</li>
                                    <li>Justifier un choix, une position, une décision, etc.</li>
                                    <li>Enchaîner des idées et faire preuve de cohérence dans votre discours.</li>
                                </ol>
                                <ol start="6" className="list-decimal list-inside ">
                                    <li>Comparer deux points de vue.</li>
                                    <li>Exprimer votre avis et l’argumenter.</li>
                                    <li>Utiliser un vocabulaire et des structures adaptés à la tâche demandée.</li>
                                    <li>Être capable de faire une synthèse et de reformuler.</li>
                                </ol>
                            </div>


                        </div>
                    </div>

                    <div className='mt-[47px]  bg-tcfmediumgreen  rounded-[5px] mb-[62px] py-[26px] mx-[32px] px-[38px]'>
                        <div className='space-y-[8px]'>
                            <p className='text-lg lg:text-2xl font-bold'>Modalités de passage</p>
                            <p>L’épreuve d’expression écrite du TCF  CANADA se passe sur ordinateur.</p>
                            <p><strong>ATTENTION </strong>: le type de clavier que vous utiliserez ne sera pas nécessairement le même d’un centre d’examen à un autre. <br />
                                Assurez-vous de demander à votre centre d’examen quels claviers ils utilisent pour ne pas avoir de surprise le jour de l’examen <br />
                                et vous préparer au mieux</p>

                        </div>

                    </div>

                    <div className='border border-[#C1C1C180] py-[33px] mb-[45px] mx-[32px] px-[38px] rounded-[5px] pt-[38px]'>
                        <div className='space-y-[23px]'>
                            <p className='text-lg lg:text-2xl font-bold'>Critères d'évaluation pour l'épreuve d'expression écrite (TCF CANADA) :</p>
                            <p>Deux examinateurs évalueront vos compétences en expression écrite de manière indépendante l'un de l'autre.<br /> Les examinateurs porteront une attention particulière à:</p>

                            <ul className='list-disc pl-5'>
                                <li>votre niveau de langue (grammaire et syntaxe, variété et précision du vocabulaire…)
                                </li>
                                <li>votre aisance à structurer vos propos (cohésion, logique interne à vos écrits…)</li>
                                <li>votre capacité à vous adapter à différentes situations de communication.</li>
                            </ul>
                        </div>

                    </div>



                    <div className='border border-[#C1C1C180] py-[33px] mb-[45px] mx-[32px] px-[38px] rounded-[5px] pt-[38px]'>


                        <div className='space-y-[23px]'>
                            <p className='text-lg lg:text-2xl font-bold'>Savoir-faire attendus :</p>
                            <p>Au cours de l’épreuve d’expression écrite du TCF CANADA, on vous demandera de :</p>

                            <ul className='list-disc pl-5'>
                                <li>vous exprimer clairement sur un sujet donné ;
                                </li>
                                <li>fournir des informations spécifiques en lien avec le sujet ;</li>
                                <li>argumenter, décrire, expliquer, reformuler ;</li>
                                <li>avoir une expression cohérente et organisée en portant une attention particulière au lexique, à la grammaire et à la syntaxe.</li>

                            </ul>
                        </div>


                    </div>

                    <div className='border border-[#C1C1C180] py-[33px] mb-[45px] mx-[32px] px-[38px] rounded-[5px] pt-[38px]'>
                        <div className='space-y-[23px]'>
                            <p className='text-lg lg:text-2xl font-bold'>L’évaluation de l'épreuve d’expression écrite :</p>
                            <p>L‘épreuve d’expression écrite est évaluée 2 fois de manière totalement indépendante et en double aveugle (sans que l’un ou l’autre des deux évaluateurs n’ait connaissance des niveaux attribués par l’autre) par l’équipe de correcteurs de France Éducation international.</p>
                            <p>Ces correcteurs sont sélectionnés en fonction de leur expérience notable dans le domaine du Français langue Étrangère (FLE) et de l’évaluation, et après un test de sélection. Ils sont spécifiquement formés à la correction des épreuves d’expression écrite de toutes les déclinaisons du TCF par les agents du bureau des tests de France Éducation international qui assurent un suivi de la qualité du travail de chaque correcteur. </p>
                            <p><strong>Les critères d’évaluation de cette épreuve d’expression écrite sont d’ordre : </strong></p>
                            <ul className='list-disc pl-5'>
                                <li>linguistique (étendue du lexique, correction grammaticale, orthographe, degré d’élaboration des phrases…) ;
                                </li>
                                <li>pragmatique (cohérence et cohésion, développement thématique…) ;</li>
                                <li>sociolinguistique (adéquation à la situation de communication).</li>
                            </ul>
                        </div>

                    </div>

                    <div className=' border border-[#C1C1C180] pt-[16px] pb-[27px] lg:pt-[38px] lg:pb-[33px] px-[13px] lg:px-[38px] mb-[45px] mx-[32px]  rounded-[5px] '>
                        <div className='flex items-start gap-[8px] lg:gap-[17px]'>
                            <div className="relative bg-tcfmediumgreen text-sm font-bold lg:text-2xl rounded-full flex items-center text-tcfsectext p-1.5 lg:p-4  ">
                                01
                            </div>
                            <div className='space-y-[7px]'>
                                <p className='text-lg lg:text-2xl font-bold'>TCF Canada Expression Écrite Tâche 1 :</p>
                                <p>Rédaction d’un message, mail, annonce pour décrire, raconter et/ou expliquer, adressé à un ou plusieurs destinataires, dont le statut a été précisé dans la consigne. Minimum 60 mots /maximum 120 mots.</p>
                                <p><strong>TCF Canada Expression Écrite : La structure du mail Tâche 1 :
                                </strong></p>
                                <>Objet : (Mes vacances à Londres) par exemple.</>
                                <ol class="list-decimal pl-5 space-y-4">
                                    <li>

                                        <p>Salutations : (Salut Diallo, comment vas-tu ?)</p>
                                    </li>
                                    <li>

                                        <p>Objectif général du mail. (je t’écris ce mail pour te dire que je serai à Paris.)

                                        </p>
                                    </li>
                                    <li>

                                        <p>Exprimer les attentes concrètes. (Dépendamment de la consigne du sujet) J’aimerais que tu identifies des endroits chics que je Pourrais visiter.</p>
                                    </li>
                                    <li>

                                        <p>formules de recommandation, promesse, remerciement.</p>
                                    </li>
                                    <li>                                        <p>formules d’Aurevoir (à  bientôt, Porte toi bien, amicalement, cordialement, etc.)</p>
                                    </li>
                                </ol>

                            </div>

                        </div>
                    </div>



                    <div className=' border border-[#C1C1C180] pt-[16px] pb-[27px] lg:pt-[38px] lg:pb-[33px] px-[13px] lg:px-[38px] mb-[45px] mx-[32px]  rounded-[5px] '>
                        <div className='flex items-start gap-[8px] lg:gap-[17px]'>
                            <div className="relative bg-tcfmediumgreen text-sm font-bold lg:text-2xl rounded-full flex items-center text-tcfsectext p-1.5 lg:p-4  ">
                                02
                            </div>
                            <div className='space-y-[7px]'>
                                <p className='text-lg lg:text-2xl font-bold'>TCF Canada Expression Écrite Tâche 2 :</p>
                                <p  >Rédaction d’un article de blog/ forum. À l’intention de plusieurs destinataires pour faire un compte rendu d’expérience ou un récit. Comptes rendus et récits seront accompagnés de commentaires, d’opinions ou d’arguments, en fonction d’un objectif. (Minimum 120 mots/maximum 150 mots.)</p>
                                <p className='bg-tcfmediumgreen rounded-[5px] px-[16px] py-[10px]'>Imaginez si on vous demande de rédiger un article de blog sur un métier qui vous passionne, en donnant envie aux gens de s’y intéresser.”</p>
                                <p>TCF Canada Expression Ecrite : La structure de la Tâche 2 :
                                </p>
                                <ol class="list-decimal pl-5 space-y-4">
                                    <li>

                                        <p>Il faut un titre attirant : Exemple : « un métier de nobles ». De nos jours les articles de blog sont très attrayants grâce à leurs titres. On a des titres comme 03 façons de reconquérir son ex. C’est attirant, ça donne envie de lire.
                                        </p>
                                    </li>
                                    <li>

                                        <p>Après le titre, le Premier paragraphe porte simplement sur une présentation succincte et attirante de l’activité.</p>
                                    </li>
                                    <li>

                                        <p>Ensuite vous partagez/ racontez votre propre expérience tout en utilisant les connecteurs chronologiques.



                                        </p>
                                    </li>
                                    <li>

                                        <p>Enfin des recommandations. Vous recommandez à des personnes qui suivent votre blog de s’intéresser à l’activité que vous décrivez. Tout dépendra de la consigne.
                                        </p>
                                    </li>


                                </ol>

                            </div>

                        </div>
                    </div>








                    <div className=' border border-[#C1C1C180] pt-[16px] pb-[27px] lg:pt-[38px] lg:pb-[33px] px-[13px] lg:px-[38px] mb-[45px] mx-[32px]  rounded-[5px] '>
                        <div className='flex items-start gap-[8px] lg:gap-[17px]'>
                            <div className="relative bg-tcfmediumgreen text-sm font-bold lg:text-2xl rounded-full flex items-center text-tcfsectext p-1.5 lg:p-4  ">
                                03
                            </div>
                            <div className='space-y-[7px]'>
                                <p className='text-lg lg:text-2xl font-bold'>TCF Canada Expression Écrite Tâche 3 :</p>

                                <ul class="list-disc pl-5 space-y-4">
                                    <li>
                                        Rédaction d’un article (pour un journal, un site Internet, etc.) qui compare deux points de vue portant sur un fait de société (1ère partie), exprimés dans deux documents courts et simples. Le candidat prend position sur le thème traité dans les deux documents (2ème partie).                                    </li>
                                    <li>
                                        Nombre de mots attendu : minimum 120 mots/maximum 180 mots.                                     </li>
                                    <li>
                                        On vous donne deux documents. Deux opinions. Au sujet d’une réalité. On vous demande de rédiger un court article.                                    </li>
                                    <li>
                                        L’article doit comprendre une première partie de 40 à 60 mots dans laquelle vous  résumez le point de vue des deux documents.                                    </li>
                                    <li>
                                        ’article doit comprendre une deuxième partie de 80 à 120 mots dans laquelle vous donnez votre point de vue vis-à-vis du sujet dont parle les deux documents.                                    </li>

                                </ul>
                                <p><strong>TCF Canada Expression Ecrite : La structure de la Tâche 3 :

                                </strong></p>
                                <ol class="list-decimal pl-5 space-y-4">
                                    <li>

                                        <p>Titre.
                                        </p>
                                    </li>
                                    <li>

                                        <p>Point de vue des deux documents.</p>
                                    </li>
                                    <li>

                                        <p>votre point de vue.




                                        </p>
                                    </li>
                                </ol>
                                <p>Si on vous donne par exemple deux points de vue sur l’utilisation d’Internet par les enfants, Vous pouvez commencer cette section avec des constructions comme :

                                </p>
                                <p>“De nos jours, la question de l’utilisation d’Internet par les enfants divise l’opinion public. Certains pensent que (Résumé Doc A) l’Internet rend les enfants paresseux et improductifs. Par contre, (Résumé Doc B d’autres trouvent en cet outil un véritable support de connaissances et de développement de l’imagination des enfants.”  (40 à 60 mots)</p>
                                <p>Et ensuite votre point de vue sur le sujet peut commencer par une construction comme :</p>
                                <p>“A ce sujet, il est important de reconnaître qu’aujourd’hui il devient difficile d’appréhender la dynamique des sociétés actuelles en négligeant le rôle joué par Internet. Cet outil est devenu incontournable, voilà pourquoi il est nécessaire de prêter à cela un contrôle parental pour régulariser les heures de connexion des enfants et choisir les sites web appropriés à leurs âges. …etc.”     (80 à 120 mots)</p>
                            </div>

                        </div>
                    </div>

                    <div className='text-tcfsectext  mt-[115px] mb-[79px]'>
                        <SubscriptionSection />
                    </div>

                </div>
                <Footer />

            </div>

        </div>
    );


};

export default ExpressionEcritePage;




