import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import Checkbox from '../components/Checkbox';
import PaystackPayment from './PaystackPayment';
import apiClient from '../services/httpService';
import HomeNavigation from '../components/Navbar';
import Footer from '../components/Footer';
import SignUpForm from '../components/SignUpForm';
import background from '../images/noisy-gradients.png';
import Alert from '../components/Alert';


const PaymentPage = () => {
  const [planOptions, setPlanOptions] = useState([]);
  // const [price, setPrice] = useState(0);
  const [selectedPlanId, setSelectedPlanId] = useState(0);
  const [paymentOption, setPaymentOption] = useState('');
  const { signUp, user, isAuthenticated } = useSelector((state) => state.auth);
  const [error, setError] = useState('');
  const [errors, setErrors] = useState({});

  const navigate = useNavigate();

  const [subscription, setSubscription] = useState({});
  const [email, setEmail] = useState('');

  const [password, setPassword] = useState('');
  const [address, setAddress] = useState('');
  const [first_name, setFirstName] = useState('');
  const [last_name, setLastName] = useState('');
  const [country, setCountry] = useState('');
  const [formData, setFormData] = useState({});
  const [formIsValid, setFormIsValid] = useState(false);
  const location = useLocation();
  const { plan, price, sub_id } = location.state || {};

  const [loading, setLoading] = useState(false);


  const paymentOptions = [
    { label: 'Carte de paiement', value: 'card' },
    { label: 'Paypal', value: 'paypal' },
  ];



  const handlePaymentOptionChange = (value) => {
    setPaymentOption(value);
  };

  const handleFormDataChange = (data, validationErrors) => {
    setFormData(data);
    setErrors(validationErrors || {}); // Ensure errors is always an object
  }

console.log(errors)
  const handleSubmit = (e) => {
    // ✅ Prevent form submission if validation errors exist
    if (Object.values(errors).some((error) => error)) {
      e.preventDefault(); // Stop form submission
      alert('Veuillez corriger les erreurs avant de continuer.');
      return;
    }

    setLoading(true); // ✅ Start loading when submitting
  };




  return (
    <div className='flex flex-col min-h-screen justify-between '>
      <div
        style={{
          height: '638px',
          backgroundImage: `url(${background})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}>
        {' '}
        <HomeNavigation />
        <div className='flex flex-col border border-[#C1C1C180] mt-[202px] mb-[137px] mx-[32px] mg:mx-[65px] text-tcfsectext bg-white rounded-[5px] px-[25px] lg:px-[59px] shadow-md  py-[43px] gap-[44px]'>
          <div className='flex flex-col gap-[11px] md:gap-[18px]'>
            <Alert
              type='error'
              header='Attention'
              message='Tous nos tarifs mentionnés sont en dollars américains'
            />
            <Alert
              type='success'
              header='Success'
              message='Silver a été ajouté à votre panier. Voir le panier'
            />
          </div>
          <div className='flex flex-col md:flex-row gap-[30px] lg:gap-[79px]'>
            <div className='w-full'>
              <div className='flex flex-col gap-[13px] mb-[60px]'>
                <p className='text-2xl text-tcfsectext font-bold'>
                  Détails de Facturation
                </p>
                <div className='text-sm'>
                  <p>Déjà client? <a className='underline' href='/login'>Cliquez ici pour vous connecter</a></p>
                  <p>
                    {/* Avez-vous un code promo?Cliquez ici pour saisir votre code */}
                  </p>
                </div>
              </div>
              {!isAuthenticated && (
                <SignUpForm onFormDataChange={handleFormDataChange} />
              )}

            </div>
            <div className='w-full bg-[#E5E5E580] border border-[#C1C1C180] rounded-[5px]'>
              <div className='px-[16px] lg:px-[32px] py-[20px]'>
                <h2 className='text-2xl font-bold mb-[14px]'>Votre commande</h2>
                <div className='border border-t-tcfgray1 mb-4'></div>

                <div className='flex justify-between items-center'>
                  <span>Produit</span>
                  <span>Montant</span>
                </div>

                <div className='flex justify-between items-center py-2'>
                  <span>{plan}</span>
                  <span className='font-medium'>${price}</span>
                </div>

                <div className='border border-t-tcfgray1 mt-[29px] mb-4'></div>

                <div className='flex justify-between items-center mt-3'>
                  <span>Sous-total</span>
                  <span className='font-medium'>${price}</span>
                </div>

                <div className='flex justify-between items-center text-lg pt-2 pb-[29px] font-bold'>
                  <span>Total</span>
                  <span>${price}</span>
                </div>

                {/* Radio Buttons for Payment Methods */}
                <div className='mb-4'>
                  {paymentOptions.map((option) => (
                    <div key={option.value} className='mb-2'>
                      <label className='flex items-start space-x-3 cursor-pointer'>
                        <input
                          type='radio'
                          name='paymentMethod'
                          value={option.value}
                          checked={paymentOption === option.value}
                          onChange={() =>
                            handlePaymentOptionChange(option.value)
                          }
                          className='w-[18px] h-[18px] appearance-none border border-[#C1C1C180] bg-[#E5E5E5] rounded-full 
                    checked:bg-tcfsectext checked:border-tcfsectext shrink-0
                    checked:shadow-[inset_0_0_0_4px_white] transition duration-200 ease-in-out'
                        />
                        <div className='flex flex-col'>
                          <span className='text-sm'>{option.label}</span>
                          {option.value === 'card' && (
                            <p className='text-tcfinput'>
                              Payez avec votre carte bancaire
                            </p>
                          )}
                        </div>
                      </label>

                      {/* Display Card Payment Form Immediately Below "Carte de paiement" */}
                      {paymentOption === 'card' && option.value === 'card' && (
                        <form
                          method='POST'
                          action={`${process.env.REACT_APP_APIURL}/payment/stripe/create-checkout-session/`}
                          onSubmit={handleSubmit}>

                          <input type='hidden' name='amount' value={price} />
                          <input type='hidden' name='sub_id' value={sub_id} />
                          {!isAuthenticated ? (
                            <>
                              <input
                                type='hidden'
                                name='email'
                                value={formData.email || ''}
                              />
                              <input
                                type='hidden'
                                name='password'
                                value={formData.password || ''}
                              />
                              <input
                                type='hidden'
                                name='first_name'
                                value={formData.firstName || ''}
                              />
                              <input
                                type='hidden'
                                name='last_name'
                                value={formData.lastName || ''}
                              />
                              <input
                                type='hidden'
                                name='country'
                                value={formData.country || ''}
                              />
                              <input
                                type='hidden'
                                name='phone'
                                value={formData.phone || ''}
                              />

                              <button

                                type='submit'
                                disabled={!formData.email || !formData.password || loading}
                                className={`text-base flex justify-center items-center md:text-sm w-full bg-tcfsectext text-white h-[40px] rounded-[5px] font-bold mt-2 mb-2 ${!formData.email || !formData.password ? 'opacity-75' : ''}`}>
                                {loading ? (
                                  <svg
                                    className="animate-spin h-5 w-5 mr-2 text-white"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="white"
                                    viewBox="0 0 24 24"
                                  >
                                    <circle
                                      className="opacity-25"
                                      cx="12"
                                      cy="12"
                                      r="10"
                                      stroke="white"
                                      strokeWidth="4"
                                    ></circle>
                                    <path
                                      className="opacity-75"
                                      fill="white"
                                      d="M4 12a8 8 0 018-8v4l3-3-3-3v4a8 8 0 00-8 8z"
                                    ></path>
                                  </svg>
                                ) : (
                                  "S'inscrire"
                                )}
                              </button>
                            </>

                          ) :
                            (
                              <>
                                <input
                                  type='hidden'
                                  name='email'
                                  value={user?.email || ''}
                                />
                                <input
                                  type='hidden'
                                  name='password'
                                  value={user?.email.password || ''}
                                />
                                <input
                                  type='hidden'
                                  name='first_name'
                                  value={user?.email.firstName || ''}
                                />
                                <input
                                  type='hidden'
                                  name='last_name'
                                  value={user?.email.lastName || ''}
                                />
                                <input
                                  type='hidden'
                                  name='country'
                                  value={user?.email.country || ''}
                                />
                                <input
                                  type='hidden'
                                  name='phone'
                                  value={user?.email.phone || ''}
                                />

                                <button

                                  type='submit'
                                  disabled={!user?.email || loading}
                                  className={`text-base flex justify-center items-center md:text-sm w-full bg-tcfsectext text-white h-[40px] rounded-[5px] font-bold mt-2 mb-2 ${!user?.email ? 'opacity-75' : ''}`}>

                                  {loading ? (
                                    <svg
                                      className="animate-spin h-5 w-5 mr-2 text-white"
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="white"
                                      viewBox="0 0 24 24"
                                    >
                                      <circle
                                        className="opacity-25"
                                        cx="12"
                                        cy="12"
                                        r="10"
                                        stroke="white"
                                        strokeWidth="4"
                                      ></circle>
                                      <path
                                        className="opacity-75"
                                        fill="white"
                                        d="M4 12a8 8 0 018-8v4l3-3-3-3v4a8 8 0 00-8 8z"
                                      ></path>
                                    </svg>
                                  ) : (
                                    "S'inscrire"
                                  )}
                                </button>
                              </>
                            )}


                        </form>
                      )}
                    </div>
                  ))}
                </div>

                <div className='border border-t-tcfgray1 mt-4 mb-4'></div>

                <p>
                  Vos données personnelles seront utilisées pour le traitement
                  de votre commande, vous accompagner au cours de votre visite
                  du site web, et pour d’autres raisons décrites dans notre
                  privacy policy.
                </p>

                <div className='border border-t-tcfgray1 mt-4 mb-4'></div>

                <Checkbox
                  cstyle='w-[15px] h-[15px] mr-2'
                  lstyle='text-sm'
                  label='J’ai lu et j’accepte les terms and conditions'
                />

                {(paymentOption === '' || price === 0) && (
                  <button
                    disabled
                    type='button'
                    className='text-base md:text-sm w-full bg-tcfred text-white h-[40px] rounded-[5px] font-bold mt-10 mb-4'>
                    Sélectionnez le mode de paiement
                  </button>
                )}

                {paymentOption === 'paypal' && price > 0 && (
                  <PaystackPayment
                    email={isAuthenticated ? user?.email : signUp.email}
                    amount={price}
                    sub_id={sub_id}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default PaymentPage;
