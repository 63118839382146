import React, { useState, useEffect } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import homelogo from '../images/homelogo.png';
import logo from '../images/homelogo2.png'
import menuOpen from '../images/menu_open_white.svg';
import menuClose from '../images/menu_close_white.svg';
import background from '../images/noisy-gradients.png';
import ecrite from '../images/ecrite.svg'
import listen from '../images/listen.svg'
import learn from '../images/learn.svg'
import speak from '../images/speak.svg'
import contact from '../images/contact.svg'
import card from '../images/card.svg'
import logouticon from '../images/logout.svg'
import graduate from '../images/graduate.svg'
import facebook from '../images/facebook.png'
import whatsapp from '../images/whatsapp.png'
import instagram from '../images/instagram.png'
import youtube from '../images/youtube.png'
import { resetQuestions, resetSelectedTest } from "../store/questionsSlice";
import { logoutSuccess } from '../store/authSlice';
import DropdownMenu from './DropdownMenu';


const HomeNavigation = ({ navHeight, bg = false }) => {



    const navigate = useNavigate();

    const dispatch = useDispatch();




    const handleLogout = () => {
        dispatch(logoutSuccess());
        dispatch(resetSelectedTest()); // Clear selected test/questions
        dispatch(resetQuestions());  // Reset the questions state properly
        navigate("/login");
    };


    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated); // Get auth state

    const handleNavigation = (event) => {
        event.preventDefault();
        if (isAuthenticated) {
            navigate("/dashboard"); // Redirect to dashboard if logged in
        } else {
            navigate("/"); // Otherwise, go to homepage
        }
    };

    const goToHome = (event) => {
        event.preventDefault(); // Prevent NavLink default behavior
        toggleMenu(); // Close the menu if necessary
        if (isAuthenticated) {
            navigate("/dashboard"); // Redirect to dashboard if logged in
        } else {
            navigate("/"); // Otherwise, go to home
        }
    };



    const location = useLocation();


    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };



    useEffect(() => {
        if (isMenuOpen) {
            document.body.classList.add("overflow-hidden");
        } else {
            document.body.classList.remove("overflow-hidden");
        }

        return () => {
            document.body.classList.remove("overflow-hidden"); // Cleanup on unmount
        };
    }, [isMenuOpen]);

    // Handle scroll behavior
    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    // Reset scroll position when navigating to a new page
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]); // Runs whenever the route changes


    const handleFreeExpEcriteTestNavigation = () => {
        if (isAuthenticated) {
            navigate("/dashboard"); // Redirect to dashboard if logged in
        } else {
            navigate("/expression-ecrite"); // Otherwise, go to subscription
        }
    };



    const handleExpEcriteCorrectionNavigation = () => {
        if (isAuthenticated) {
            navigate("/dashboard"); // Redirect to dashboard if logged in
        } else {
            navigate("/tarifs"); // Otherwise, go to subscription
        }
    };

    const expressionecritemenuItems = [

        { label: ' Les sujets d’actualités', onClick: handleFreeExpEcriteTestNavigation },
        { label: ' Méthodologie', onClick: () => window.location.href = '/expression-ecrite-instructions' },
        { label: '  Correction des sujets d’expression écrite', onClick: handleExpEcriteCorrectionNavigation },
        { label: ' Plate-forme de rédaction', onClick: handleFreeExpEcriteTestNavigation },

    ];

    const expressionoralemenuItems = [

        { label: 'Les sujets d’actualités', onClick: () => window.location.href = '/expression-orale' },
        { label: ' Méthodologie', onClick: () => window.location.href = '/expression-orale-instructions' },
        { label: '  Correction des sujets ', onClick: () => window.location.href = 'tarifs' },

    ];

    return (
        <nav
            className={` fixed top-0 left-0 right-0 transition-all duration-300 z-50 ${isScrolled ? 'bg-[#255C56] ' : ''
                } ${isMenuOpen ? ' bg-transparent' : ''} px-[32px] mg:px-[67px]`}
            style={{
                height: isScrolled ? '68px' : navHeight || '',
                backgroundImage: !isScrolled && bg ? `url(${background})` : "none",
                backgroundSize: 'cover',
                backgroundPosition: 'center',
            }}

        >


            {/* top footer for xl screen, is hidden for smaller */}

            <div className={`hidden mg:flex justify-between  gap-[27px]   bg-tcfmediumgreen absolute top-0 right-0 left-0 w-full py-[12px] xl:py-[24px] px-[64px] ${isScrolled ? 'mg:hidden' : ''}`}>
                <div className='flex justify-between lg:justify-normal'>
                    {/* Left Section - Navigation Links */}
                    <div className="flex flex-col md:flex-row gap-[6px] md:gap-6 text-base font-normal md:text-sm">

                        <div className="flex items-center gap-2">
                            <img src={graduate} alt="Graduate Icon" />
                            <NavLink to="/formation" className="text-tcfsectext">Formations</NavLink>
                        </div>

                        <div className="flex items-center gap-2">
                            <img src={contact} alt="Contact Icon" />
                            <NavLink to="/contact" className="text-tcfsectext">Contactez-nous</NavLink>
                        </div>

                        <div className="flex items-center gap-2">
                            <img src={card} alt="Card Icon" />
                            <NavLink to="/tarifs" className="text-tcfsectext">Tarifs</NavLink>
                        </div>

                    </div>
                    <div className=' hidden md:flex lg:hidden gap-[8px]  font-normal'>
                        <a href="https://www.facebook.com/share/16HhSLiMpQ/?mibextid=wwXIfr" target="_blank" rel="noopener noreferrer" className="flex items-center space-x-2">
                            <img src={facebook} alt="Facebook" className="w-7 h-7 cursor-pointer " />

                        </a>
                        <a href="https://youtube.com/@bidgroup-i2u?feature=shared" target="_blank" rel="noopener noreferrer" className="flex items-center space-x-2">
                            <img src={whatsapp} alt="WhatsApp" className="w-7 h-7 cursor-pointer" />
                        </a>
                        <a href="https://www.instagram.com/bidgrouptravel1.0?igsh=bnVoeHdqaGVtMDdm&utm_source=qr" target="_blank" rel="noopener noreferrer" className="flex items-center space-x-2">
                            <img src={instagram} alt="Instagram" className="w-7 h-7 cursor-pointer " />
                        </a>

                        <a href="https://youtube.com/@bidgroup-i2u?feature=shared" target="_blank" rel="noopener noreferrer" className="flex items-center space-x-2">
                            <img src={youtube} alt="YouTube" className="w-7 h-7 cursor-pointer" />

                        </a>


                    </div>
                </div>

                {/* Right Section - Authentication & Social Icons */}



                {/* Social Media Icons */}
                <div className="flex gap-3 md:hidden lg:flex">
                    <a href="https://www.facebook.com/share/16HhSLiMpQ/?mibextid=wwXIfr" target="_blank" rel="noopener noreferrer" className="flex items-center space-x-2">
                        <img src={facebook} alt="Facebook" className="w-7 h-7 cursor-pointer " />

                    </a>
                    <a href="https://youtube.com/@bidgroup-i2u?feature=shared" target="_blank" rel="noopener noreferrer" className="flex items-center space-x-2">
                        <img src={whatsapp} alt="WhatsApp" className="w-7 h-7 cursor-pointer" />
                    </a>
                    <a href="https://www.instagram.com/bidgrouptravel1.0?igsh=bnVoeHdqaGVtMDdm&utm_source=qr" target="_blank" rel="noopener noreferrer" className="flex items-center space-x-2">
                        <img src={instagram} alt="Instagram" className="w-7 h-7 cursor-pointer " />
                    </a>

                    <a href="https://youtube.com/@bidgroup-i2u?feature=shared" target="_blank" rel="noopener noreferrer" className="flex items-center space-x-2">
                        <img src={youtube} alt="YouTube" className="w-7 h-7 cursor-pointer" />

                    </a>

                </div>





            </div>

            {/* Logo and Menu Icon Container menu is hidden for xl screens  */}

            <div className={`flex justify-between  ${isScrolled && !isMenuOpen ? 'mt-[14px]' : ' mt-[61px] lg:mt-[52px]'} ${isScrolled ? ' ' : 'mg:mt-[110px]'} text-white items-center `}>
                <NavLink to="/" onClick={handleNavigation}>
                    <img
                        src={homelogo}
                        alt="Home Logo1"
                        className={`h-auto ${isScrolled ? 'w-[130px]' : 'w-[157px]'} `}
                    />
                </NavLink>



                {/* Menu Open Icon */}
                <button
                    onClick={toggleMenu}
                    className={`focus:outline-none flex mg:hidden items-center z-50 gap-[9px] md:text-lg ${isScrolled && !isMenuOpen ? 'px-[11px]  h-[35px] md:h-[35px]' : ' px-[14px] md:px-[21px]  h-[40px] md:h-[50px] '}  border  rounded-[5px] ${isMenuOpen ? 'hidden mdtext-tcfsectext border-tcfsectext ' : 'text-white border-white'
                        }`}
                >
                    Menu
                    <img
                        src={isMenuOpen ? menuClose : menuOpen}
                        alt={isMenuOpen ? 'Close Menu Icon' : 'Menu Icon'}

                    />
                </button>


                <div className="hidden mg:flex justify-between mg:gap-[30px]  text-sm ">


                    <div className="flex items-center gap-[7px] justify-between">

                        <NavLink to="/comprehension-ecrite-instructions">
                            Compréhension Écrite
                        </NavLink>
                    </div>

                    <div className='flex gap-[7px] items-center justify-between'>

                        <NavLink to="/comprehension-orale-instructions">
                            Compréhension Orale
                        </NavLink>
                    </div>

                    {/* Xl screen nav links */}
                    <div className="flex gap-[7px] items-center ">

                        <NavLink to="/expression-ecrite-instructions">
                            Expression Écrite
                        </NavLink>
                        <DropdownMenu menuItems={expressionecritemenuItems} />
                    </div>

                    <div className="flex gap-[7px] items-center ">


                        <NavLink to="/expression-orale-instructions">
                            Expression Orale
                        </NavLink>
                        <DropdownMenu menuItems={expressionoralemenuItems} />
                    </div>


                </div>


                <div className='hidden mg:flex'>

                    {isAuthenticated ? (
                        <button
                            onClick={handleLogout}
                            className="flex  w-fit gap-2 items-center border border-white text-white text-sm px-4 py-2 rounded-md hover:bg-opacity-80 transition"
                        >
                            <img src={logouticon} alt="Logout Icon" className="w-4 h-4" />
                            <span>Se déconnecter</span>
                        </button>
                    ) : (
                        <div className="flex gap-2">
                            <button onClick={() => navigate("/login")} className=" bg-white text-tcfsectext text-sm px-4 py-2 rounded-md">
                                Se connecter
                            </button>
                            <button onClick={() => navigate("/tarifs")} className="border border-white text-white text-sm px-4 py-2 rounded-md">
                                Commencez ici
                            </button>
                        </div>
                    )}

                </div>



            </div>



            {/* Sidebar menu for small to large screen */}
            {/* Sidebar Menu */}
            {isMenuOpen && (
                <>
                    <div className=" transition-transform duration-300 ease-in fixed top-0 left-0 w-full h-full md:h-[473px] lg:h-[391px] bg-white z-50  flex flex-col justify-between pb-[38px] z-90 md:rounded-b-[5px] overflow-y-auto ">
                        {/* Sidebar Header with Logo and Close Icon */}
                        <div className=" h-[85px] flex-col flex md:flex-row items-start gap-[22px] md:gap-[42px] h-min-screen mt-[42px] md:mt-[52px] justify-between  ">
                            {/* Logo inside the sidebar */}
                            <div className='flex justify-between w-full px-[32px] '>
                                <NavLink to="/" onClick={goToHome}>
                                    <img
                                        src={logo}
                                        alt="Home Logo2"
                                        className="h-auto w-[157px]"
                                    />
                                </NavLink>


                                {/* Topbar Links */}
                                <div className="hidden md:grid  md:grid-cols-2  md:gap-x-[26px] lg:gap-x-[102px] gap-y-[21px] md:gap-y-[38px] text-tcfsectext text-sm font-bold px-[16px]">


                                    <div className="flex items-center gap-[12px]">
                                        <div className="relative bg-tcfmediumgreen  rounded-full p-2.5">
                                            <img src={ecrite} alt="" className="" />
                                        </div>
                                        <NavLink to="/comprehension-ecrite-instructions">
                                            Compréhension Écrite
                                        </NavLink>
                                    </div>

                                    <div className='flex items-center gap-[12px]'>
                                        <div className="relative bg-tcfmediumgreen  rounded-full flex items-center p-2.5">
                                            <img src={listen} alt="" className="" />
                                        </div>
                                        <NavLink to="/comprehension-orale-instructions">
                                            Compréhension Orale
                                        </NavLink>
                                    </div>


                                    <div className="flex gap-[12px] items-start text-start text-tcfsectext">
                                        <div className="relative bg-tcfmediumgreen  rounded-full p-2.5 md:mt-[-11px]">
                                            <img src={learn} alt="" className="" />
                                        </div>
                                        <span><NavLink to="/expression-ecrite-instructions">
                                            Expression Écrite
                                        </NavLink>
                                            <ul className='text-base font-normal  space-y-[6px] mt-[16px]'>
                                                <li>
                                                    <NavLink to="/expression-ecrite-instructions">
                                                        Les sujets d’actualités
                                                    </NavLink>
                                                </li>
                                                <li>
                                                    <NavLink to="/expression-ecrite-instructions">
                                                        Méthodologie
                                                    </NavLink>
                                                </li>
                                                <li>
                                                    <NavLink to="/expression-ecrite-instructions">
                                                        Correction des sujets <br /> d’expression écrite
                                                    </NavLink>
                                                </li>
                                                <li>
                                                    <NavLink to="/expression-ecrite-instructions">
                                                        Plate-forme de rédaction
                                                    </NavLink>
                                                </li>
                                            </ul>
                                        </span>
                                    </div>

                                    <div className="flex gap-[12px] items-start text-start text-tcfsectext">
                                        <div className="relative bg-tcfmediumgreen  rounded-full p-2.5 md:mt-[-11px]">
                                            <img src={speak} alt="" className="" />
                                        </div>
                                        <span>
                                            <NavLink to="/expression-orale-instructions">
                                                Expression Orale
                                            </NavLink>
                                            <ul className='text-base font-normal space-y-[6px] mt-[16px] '>
                                                <li>
                                                    <NavLink to="/expression-orale-instructions">
                                                        Les sujets d’actualités
                                                    </NavLink>
                                                </li>
                                                <li>
                                                    <NavLink to="/expression-orale-instructions">
                                                        Méthodologie
                                                    </NavLink>
                                                </li>
                                                <li>
                                                    <NavLink to="/expression-orale-instructions">
                                                        Correction des sujets
                                                    </NavLink>
                                                </li>


                                            </ul>
                                        </span>
                                    </div>


                                </div>

                                <button
                                    onClick={toggleMenu}
                                    className={`focus:outline-none flex items-center z-50 gap-[9px] md:text-lg ${isScrolled ? 'px-[11px] ' : ' px-[14px] md:px-[21px] '}  border  rounded-[5px] h-[40px] md:h-[50px] ${isMenuOpen ? ' text-tcfsectext border-tcfsectext ' : 'text-white border-white'
                                        }`}
                                >
                                    Menu
                                    <img
                                        src={isMenuOpen ? menuClose : menuOpen}
                                        alt={isMenuOpen ? 'Close Menu Icon' : 'Menu Icon'}

                                    />
                                </button>
                            </div>




                            {/* Topbar Links */}
                            <div className="grid grid-cols-1 md:hidden gap-x-[46px] gap-y-[21px] md:gap-y-[38px] text-tcfsectext text-sm font-bold px-[32px]">


                                <div className="flex items-center gap-[12px]">
                                    <div className="relative bg-tcfmediumgreen  rounded-full p-2.5">
                                        <img src={ecrite} alt="" className="" />
                                    </div>
                                    <NavLink to="/comprehension-ecrite-instructions">
                                        Compréhension Écrite
                                    </NavLink>
                                </div>

                                <div className='flex items-center gap-[12px]'>
                                    <div className="relative bg-tcfmediumgreen  rounded-full flex items-center p-2.5">
                                        <img src={listen} alt="" className="" />
                                    </div>
                                    <NavLink to="/comprehension-orale-instructions">
                                        Compréhension Orale
                                    </NavLink>
                                </div>


                                <div className="flex gap-[12px] items-start text-start text-tcfsectext">
                                    <div className="relative bg-tcfmediumgreen  rounded-full p-2.5 md:mt-[-11px]">
                                        <img src={learn} alt="" className="" />
                                    </div>
                                    <span><NavLink to="/expression-ecrite-instructions">
                                        Expression Écrite
                                    </NavLink>
                                        <ul className='text-base font-normal  space-y-[6px] mt-[16px]'>
                                            <li>
                                                <NavLink to="/expression-ecrite-instructions">
                                                    Les sujets d’actualités
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink to="/expression-ecrite-instructions">
                                                    Méthodologie
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink to="/expression-ecrite-instructions">
                                                    Correction des sujets <br /> d’expression écrite
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink to="/expression-ecrite-instructions">
                                                    Plate-forme de rédaction
                                                </NavLink>
                                            </li>
                                        </ul>
                                    </span>
                                </div>

                                <div className="flex gap-[12px] items-start text-start text-tcfsectext">
                                    <div className="relative bg-tcfmediumgreen  rounded-full p-2.5 md:mt-[-11px]">
                                        <img src={speak} alt="" className="" />
                                    </div>
                                    <span>
                                        <NavLink to="/expression-orale-instructions">
                                            Expression Orale
                                        </NavLink>
                                        <ul className='text-base font-normal space-y-[6px] mt-[16px] '>
                                            <li>
                                                <NavLink to="/expression-orale-instructions">
                                                    Les sujets d’actualités
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink to="/expression-orale-instructions">
                                                    Méthodologie
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink to="/expression-orale-instructions">
                                                    Correction des sujets
                                                </NavLink>
                                            </li>


                                        </ul>
                                    </span>
                                </div>


                            </div>

                            <div className="flex sm:hidden flex-col justify-between gap-[27px] md:gap-[32px]  bg-tcfmediumgreen  w-full py-4 md:py-[27px] lg:py-[22px] px-8 rounded-b-md">
                                <div className='flex justify-between '>
                                    {/* Left Section - Navigation Links */}
                                    <div className="flex flex-col md:flex-row gap-[6px] md:gap-6 text-base font-normal md:text-sm">

                                        <div className="flex items-center gap-2">
                                            <img src={graduate} alt="Graduate Icon" />
                                            <NavLink to="/formation" className="text-tcfsectext">Formations</NavLink>
                                        </div>

                                        <div className="flex items-center gap-2">
                                            <img src={contact} alt="Contact Icon" />
                                            <NavLink to="/contact" className="text-tcfsectext">Contactez-nous</NavLink>
                                        </div>

                                        <div className="flex items-center gap-2">
                                            <img src={card} alt="Card Icon" />
                                            <NavLink to="/tarifs" className="text-tcfsectext">Tarifs</NavLink>
                                        </div>

                                    </div>
                                    <div className=' hidden md:flex lg:hidden gap-[8px]  font-normal'>
                                        <a href="https://www.facebook.com/share/16HhSLiMpQ/?mibextid=wwXIfr" target="_blank" rel="noopener noreferrer" className="flex items-center space-x-2">
                                            <img src={facebook} alt="Facebook" className="w-7 h-7 cursor-pointer " />

                                        </a>
                                        <a href="https://youtube.com/@bidgroup-i2u?feature=shared" target="_blank" rel="noopener noreferrer" className="flex items-center space-x-2">
                                            <img src={whatsapp} alt="WhatsApp" className="w-7 h-7 cursor-pointer" />
                                        </a>
                                        <a href="https://www.instagram.com/bidgrouptravel1.0?igsh=bnVoeHdqaGVtMDdm&utm_source=qr" target="_blank" rel="noopener noreferrer" className="flex items-center space-x-2">
                                            <img src={instagram} alt="Instagram" className="w-7 h-7 cursor-pointer " />
                                        </a>

                                        <a href="https://youtube.com/@bidgroup-i2u?feature=shared" target="_blank" rel="noopener noreferrer" className="flex items-center space-x-2">
                                            <img src={youtube} alt="YouTube" className="w-7 h-7 cursor-pointer" />

                                        </a>



                                    </div>
                                </div>

                                {/* Right Section - Authentication & Social Icons */}
                                <div className="flex flex-col md:flex-row  gap-[27px] lg:gap-[148px] md:mt-0 lg:items-center lg:justify-between">

                                    {isAuthenticated ? (
                                        <button
                                            onClick={handleLogout}
                                            className="flex items-center w-fit gap-2 font-bold bg-tcfsectext text-white text-sm px-4 py-2 rounded-md hover:bg-opacity-80 transition"
                                        >
                                            <img src={logouticon} alt="Logout Icon" className="w-4 h-4" />
                                            <span>Se déconnecter</span>
                                        </button>
                                    ) : (
                                        <div className="flex gap-2">
                                            <button onClick={() => navigate("/tarifs")} className="border border-tcfsectext text-tcfsectext text-sm px-4 py-2 rounded-md">
                                                Commencez ici
                                            </button>
                                            <button onClick={() => navigate("/login")} className="bg-tcfsectext text-white text-sm px-4 py-2 rounded-md">
                                                Se connecter
                                            </button>
                                        </div>
                                    )}

                                    {/* Social Media Icons */}
                                    <div className="flex gap-3 md:hidden lg:flex">
                                        <a href="https://www.facebook.com/share/16HhSLiMpQ/?mibextid=wwXIfr" target="_blank" rel="noopener noreferrer" className="flex items-center space-x-2">
                                            <img src={facebook} alt="Facebook" className="w-7 h-7 cursor-pointer " />

                                        </a>
                                        <a href="https://youtube.com/@bidgroup-i2u?feature=shared" target="_blank" rel="noopener noreferrer" className="flex items-center space-x-2">
                                            <img src={whatsapp} alt="WhatsApp" className="w-7 h-7 cursor-pointer" />
                                        </a>
                                        <a href="https://www.instagram.com/bidgrouptravel1.0?igsh=bnVoeHdqaGVtMDdm&utm_source=qr" target="_blank" rel="noopener noreferrer" className="flex items-center space-x-2">
                                            <img src={instagram} alt="Instagram" className="w-7 h-7 cursor-pointer " />
                                        </a>

                                        <a href="https://youtube.com/@bidgroup-i2u?feature=shared" target="_blank" rel="noopener noreferrer" className="flex items-center space-x-2">
                                            <img src={youtube} alt="YouTube" className="w-7 h-7 cursor-pointer" />

                                        </a>

                                    </div>

                                </div>




                            </div>










                        </div>

                        <div className="hidden sm:flex flex-col lg:flex-row justify-between  gap-[27px] md:gap-[32px]  bg-tcfmediumgreen absolute bottom-0 w-full py-4 md:py-[27px] lg:py-[22px] px-8 md:rounded-b-md">
                            <div className='flex justify-between '>
                                {/* Left Section - Navigation Links */}
                                <div className="flex flex-col md:flex-row gap-[6px] md:gap-6 text-base font-normal md:text-sm">

                                    <div className="flex items-center gap-2">
                                        <img src={graduate} alt="Graduate Icon" />
                                        <NavLink to="/formation" className="text-tcfsectext">Formations</NavLink>
                                    </div>

                                    <div className="flex items-center gap-2">
                                        <img src={contact} alt="Contact Icon" />
                                        <NavLink to="/contact" className="text-tcfsectext">Contactez-nous</NavLink>
                                    </div>

                                    <div className="flex items-center gap-2">
                                        <img src={card} alt="Card Icon" />
                                        <NavLink to="/tarifs" className="text-tcfsectext">Tarifs</NavLink>
                                    </div>

                                </div>
                                <div className=' hidden md:flex lg:hidden gap-[8px]  font-normal'>
                                    <a href="https://www.facebook.com/share/16HhSLiMpQ/?mibextid=wwXIfr" target="_blank" rel="noopener noreferrer" className="flex items-center space-x-2">
                                        <img src={facebook} alt="Facebook" className="w-7 h-7 cursor-pointer " />

                                    </a>
                                    <a href="https://youtube.com/@bidgroup-i2u?feature=shared" target="_blank" rel="noopener noreferrer" className="flex items-center space-x-2">
                                        <img src={whatsapp} alt="WhatsApp" className="w-7 h-7 cursor-pointer" />
                                    </a>
                                    <a href="https://www.instagram.com/bidgrouptravel1.0?igsh=bnVoeHdqaGVtMDdm&utm_source=qr" target="_blank" rel="noopener noreferrer" className="flex items-center space-x-2">
                                        <img src={instagram} alt="Instagram" className="w-7 h-7 cursor-pointer " />
                                    </a>

                                    <a href="https://youtube.com/@bidgroup-i2u?feature=shared" target="_blank" rel="noopener noreferrer" className="flex items-center space-x-2">
                                        <img src={youtube} alt="YouTube" className="w-7 h-7 cursor-pointer" />

                                    </a>



                                </div>
                            </div>

                            {/* Right Section - Authentication & Social Icons */}
                            <div className="flex flex-col md:flex-row  gap-[27px] lg:gap-[148px] md:mt-0 lg:items-center lg:justify-between">

                                {isAuthenticated ? (
                                    <button
                                        onClick={handleLogout}
                                        className="flex items-center w-fit gap-2 font-bold bg-tcfsectext text-white text-sm px-4 py-2 rounded-md hover:bg-opacity-80 transition"
                                    >
                                        <img src={logouticon} alt="Logout Icon" className="w-4 h-4" />
                                        <span>Se déconnecter</span>
                                    </button>
                                ) : (
                                    <div className="flex gap-2">
                                        <button onClick={() => navigate("/tarifs")} className="border border-tcfsectext text-tcfsectext text-sm px-4 py-2 rounded-md">
                                            Commencez ici
                                        </button>
                                        <button onClick={() => navigate("/login")} className="bg-tcfsectext text-white text-sm px-4 py-2 rounded-md">
                                            Se connecter
                                        </button>
                                    </div>
                                )}

                                {/* Social Media Icons */}
                                <div className="flex gap-3 md:hidden lg:flex">
                                    <a href="https://www.facebook.com/share/16HhSLiMpQ/?mibextid=wwXIfr" target="_blank" rel="noopener noreferrer" className="flex items-center space-x-2">
                                        <img src={facebook} alt="Facebook" className="w-7 h-7 cursor-pointer " />

                                    </a>
                                    <a href="https://youtube.com/@bidgroup-i2u?feature=shared" target="_blank" rel="noopener noreferrer" className="flex items-center space-x-2">
                                        <img src={whatsapp} alt="WhatsApp" className="w-7 h-7 cursor-pointer" />
                                    </a>
                                    <a href="https://www.instagram.com/bidgrouptravel1.0?igsh=bnVoeHdqaGVtMDdm&utm_source=qr" target="_blank" rel="noopener noreferrer" className="flex items-center space-x-2">
                                        <img src={instagram} alt="Instagram" className="w-7 h-7 cursor-pointer " />
                                    </a>

                                    <a href="https://youtube.com/@bidgroup-i2u?feature=shared" target="_blank" rel="noopener noreferrer" className="flex items-center space-x-2">
                                        <img src={youtube} alt="YouTube" className="w-7 h-7 cursor-pointer" />

                                    </a>

                                </div>

                            </div>




                        </div>




                    </div>




                    {/* Overlay covering remaining 20% */}
                    <div className="fixed top-0 right-0 w-full h-full bg-black bg-opacity-30 z-30" onClick={toggleMenu} />
                </>
            )
            }


        </nav >
    );
};


export default HomeNavigation;
