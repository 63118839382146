import React, { useState, useEffect } from "react";
import timeIcon from "../images/time.svg";
import apiClient from '../services/httpService';

const TimePicker = ({ selectedDate, selectedTime, setSelectedTime, bookedTimes }) => {
    const today = new Date();
    const currentHour = today.getHours();
    const currentMinutes = today.getMinutes();
    const currentSeconds = today.getSeconds();

    const timeSlots = [];
    for (let hour = 7; hour <= 20; hour++) { // Time range from 7 AM to 8 PM
        const formattedHour = hour % 12 === 0 ? 12 : hour % 12;
        const period = hour < 12 ? "AM" : "PM";

        // Store the full "HH:mm:ss" format for accurate comparison
        const formattedTime = `${hour.toString().padStart(2, "0")}:00:00`;

        timeSlots.push({ hour, label: `${formattedHour}:00 ${period}`, formattedTime });
    }

    

    const handleTimeClick = (hour, label) => {
        setSelectedTime(label);
    };

    const handleClearInput = () => {
        setSelectedTime(null);
    };




    return (
        <div className="flex flex-col gap-[11px] flex-[1]">
            <div className="w-full bg-tcfbg h-[40px] rounded-[5px] flex justify-between items-center pr-4 pl-3">
                <input
                    placeholder="Sélectionner l'heure"
                    value={selectedTime || ""}
                    readOnly
                    className="text-tcfinput text-base flex-1 outline-none bg-transparent"
                />
                {selectedTime ? (
                    <button onClick={handleClearInput} className="text-tcfinput text-sm">✕</button>
                ) : (
                    <img src={timeIcon} alt="timeIcon" />
                )}
            </div>
            <div className="w-full h-[243px] flex flex-col gap-[13px] pt-[17px] pb-[27px] px-[15px] border rounded-[5px] shadow-md bg-white overflow-auto">
                {timeSlots.map(({ hour, label }) => {
                    const isToday = selectedDate && selectedDate.toDateString() === today.toDateString();
                    // const isPastTime = isToday && hour < currentHour;
                    const isPastTime = isToday && (hour < currentHour || (hour === currentHour && (currentMinutes > 0 || currentSeconds > 0)));
                    const formattedHour = `${hour.toString().padStart(2, "0")}:00:00`;

                    const isBooked = bookedTimes.includes(formattedHour); // Check if time is booked

                    return (
                        <button
                            key={hour}
                            className={`w-full text-left text-base py-[6px]  px-[9px] font-medium rounded-[5px] transition-all ${isPastTime  || isBooked ? "text-tcfinput cursor-not-allowed" : "hover:bg-tcfbg "
                                } ${selectedTime === label ? "bg-tcfbg  " : ""}`}
                            onClick={() => !isPastTime && !isBooked && handleTimeClick(hour, label)}
                            disabled={isPastTime || isBooked}
                        >
                            {label}
                        </button>
                    );
                })}
            </div>
        </div>
    );
};

export default TimePicker;
