// ResponseSection.js
import React, { useCallback, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import erroricon from '../images/exclamationicon.svg'
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentIndex, setExpressionWrittenResponse } from '../store/questionsSlice';
import apiClient from '../services/httpService';
import Alert from './Alert';
import ProgressBar from './ProgressBar';
import sendEmail from './Email';
import { resetSelectedTest } from '../store/questionsSlice';



const ExpressionOraleResponseSection = ({ question, showCorrections, setActiveSection }) => {
    const [responses, setResponses] = useState([]);
    const [responseId, setResponseId] = useState([]);
    const [text, setText] = useState('');
    const [wordCount, setWordCount] = useState(0);
    const { questions, currentIndex, selectedTest } = useSelector((state) => state.questions);
    const dispatch = useDispatch();
    const maxWords = 120;

    const navigate = useNavigate();


    const handleChange = (event) => {

        const inputText = event.target.value;
        setText(inputText);

        // Calculate word count by splitting by spaces and filtering out empty strings
        const count = inputText.trim().split(/\s+/).filter(word => word.length > 0).length;
        setWordCount(count);
        dispatch(setExpressionWrittenResponse({ questionId: question.id, response: inputText }));
    };

    const handleNextQuestion = useCallback(() => {
        if (currentIndex < questions.expression_questions.length - 1) {
            dispatch(setCurrentIndex(currentIndex + 1));
        }
    }, [currentIndex, dispatch, questions.expression_questions.length]);

    const handlePreviousQuestion = useCallback(() => {
        if (currentIndex > 0) {
            dispatch(setCurrentIndex(currentIndex - 1));
        }
    }, [currentIndex, dispatch]);


    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated); // Get auth state

    const { user } = useSelector((state) => state.auth);



    const handleFinishExam = async () => {
        const formDataQuestions = questions?.expression_questions;
        // console.log(formDataQuestions)
        let valid = true;

        for (const question of formDataQuestions) {
            if (!question.response) {
                valid = false;
                alert('Please select an answer for all questions.');
                break; // Exit the loop immediately
            }
        }

        if (valid) {
            const response = await apiClient.post(`/mgmt/save-response-expression/${selectedTest}/`, {
                questions: questions.expression_questions,
            });
            if (response.status === 201) {
                setResponseId(response?.data?.id)

                //  fetch user response
                // const userResponse = await apiClient.get(`/mgmt/fetch-response/${responseId}/`);
                const userAnswers = {
                    responses: formDataQuestions.map(item => item.response), // Collect all responses
                    name: user.first_name,
                    email: user.email // Append email only once
                };

                // console.log(userAnswers)
                // send email
                sendEmail(userAnswers)

                dispatch(resetSelectedTest()); // Ensures sidebar is visible on refresh

                setActiveSection('')

            }
        }
    };




    return (
        <div className=" flex flex-col gap-[22px]  mt-[42px]">
            {!question && <div className="text-red-500 text-xl">Veuillez sélectionner un test</div>}



            <div className='flex justify-between items-center '>
                <p className='flex gap-[12px] text-base md:text-sm font-bold'>{`Tache ${question?.task_number}`}<span className='font-bold text-[10px] md:text-xs bg-tcfmediumgreen h-[20px] px-[10px] rounded-[5px] '>{`${question?.points}`}points</span></p>

                {isAuthenticated && !showCorrections && (
                    <div className='flex items-center gap-[5px]'>
                        <ProgressBar totalTime={questions?.duration} />
                    </div>
                )}

            </div>

            {!showCorrections && (<Alert type="error" header="Attention" message="Ces contenus sont basés sur des sujets réels ! Ils ne sont pas les originaux.
" />)}


            {(question) &&
                <>
                    <div className={`flex flex-col items-start  ${showCorrections ? '' : 'border border-tcfsectext p-2'}  rounded-[5px] gap-[6px] `}>
                        {!showCorrections && (
                            <div className='flex flex-row w-full justify-between'>
                                <p className="text-sm font-bold">SUJET</p>
                            </div>)}
                        <p className={`text-sm ${showCorrections ? 'text-tcfinput' : 'text-tcfsectext'}`}>
                            {question?.description} <span className={`font-bold ${showCorrections ? 'text-tcfsectext' : 'text-tcfsectext '} `}>{question?.description_question}</span>
                        </p>

                    </div>
                    <div className='bg-tcfmediumgreen px-[14px]  py-[11px] p-2 rounded-[5px] mt-2'>
                        <p className={`text-sm ${showCorrections ? 'text-tcfinput' : 'text-tcfsectext'}`}>
                            {question?.question} <span className={`font-bold ${showCorrections ? 'text-tcfsectext' : 'text-tcfsectext '} `}>{question?.description_question}</span>

                        </p>
                    </div>
                </>
            }


            {/* Writing area */}
            {isAuthenticated && !showCorrections && (
                ''
            )}

            {/* Correction */}
            {isAuthenticated && showCorrections && (
                ''
            )}

            {/* Buttons */}

            <div className='flex flex-col md:flex-row justify-between mt-[10px] gap-y-[11px] '>


                {currentIndex > 0 && (
                    <button onClick={handlePreviousQuestion} className=" w-full font-bold border-tcfsectext border bg-white text-base px-[39px] h-[40px] md:h-[30px] rounded-[5px] text-tcfsectext mr-4 md:w-fit  ">Retour</button>
                )}

                {currentIndex < questions.expression_questions.length - 1 && (
                    <button
                        onClick={handleNextQuestion}
                        className={`font-bold border text-base px-[36px] h-[40px] md:h-[30px] rounded-[5px] md:ml-auto bg-tcfsectext text-white
            ${(isAuthenticated && !showCorrections && currentIndex >= questions.expression_questions.length - 1) || (isAuthenticated && !showCorrections && !questions.expression_questions[currentIndex]?.response)
                                ? ' '
                                : ' border-white hover:bg-tcfmediumgreen hover:text-tcfsectext'
                            }`} 
                    >
                        Suivant
                    </button>

                )}
            </div>
        </div>
    );
}

export default ExpressionOraleResponseSection;
