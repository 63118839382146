import React, { useEffect, useState } from "react";

const AudioProgressBar = ({ audioRef, width = "w-full" }) => {
  const [audioProgress, setAudioProgress] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);

  useEffect(() => {
    if (!audioRef?.current) return;

    const audioElement = audioRef.current;

    const updateProgress = () => {
      setCurrentTime(audioElement.currentTime);
      setDuration(audioElement.duration || 0);
      setAudioProgress((audioElement.currentTime / audioElement.duration) * 100 || 0);
    };

    audioElement.addEventListener("timeupdate", updateProgress);
    audioElement.addEventListener("loadedmetadata", updateProgress);

    return () => {
      audioElement.removeEventListener("timeupdate", updateProgress);
      audioElement.removeEventListener("loadedmetadata", updateProgress);
    };
  }, [audioRef]); // ✅ Proper dependency usage

  // Format time (MM:SS)
  const formatTime = (time) => {
    if (isNaN(time) || time < 0) return "00:00";
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
  };

  return (
    <div className="flex items-center gap-[8px] w-full">
      {/* Display Current Time */}
      <p className="text-base text-tcfsectext">{formatTime(currentTime)}</p>

      {/* Progress Bar */}
      <div className={`bg-tcfmediumgreen h-[5px] ${width} rounded-full`} aria-label="Audio progress">
        <div
          className="bg-tcfsectext h-[5px] transition-all rounded-full duration-300"
          style={{ width: `${audioProgress}%` }}
        ></div>
      </div>

      {/* Display Total Duration */}
      <p className="text-base text-tcfsectext">{formatTime(duration)}</p>
    </div>
  );
};

export default React.memo(AudioProgressBar); // ✅ Prevent unnecessary re-renders
