/** @format */

import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import QueryString from 'query-string';
import successIcon from '../images/success.svg'
import { useSelector } from 'react-redux';
import apiClient from '../services/httpService';
import background from '../images/noisy-gradients.png';

const StripePaymentConfirmation = () => {
  const { isAuthenticated } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const [mes, setMsg] = useState('');
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  var button_text = '';

  if (isAuthenticated) {
    button_text = 'Aller au tableau de bord';
  } else if (mes === '') {
    button_text = 'Loading....';
  } else {
    button_text = 'Aller à la connexion';
  }

  const handleRedirect = () => {
    if (isAuthenticated) {
      navigate('/dashboard');
    } else {
      navigate('/login');
    }
  };

  useEffect(() => {
    const values = QueryString.parse(location.search);

    const updatePaymentStatus = async () => {
      if (!values.session_id || !values.payment_id) {
        setMsg('Payment Failed! Missing payment information.');
        setLoading(false);
        return;
      }

      try {
        const payment_status = values.success === 'true' ? 'success' : 'failed';

        const response = await apiClient.post(
          '/payment/stripe/update-payment/',
          {
            checkout_id: values.session_id,
            payment_status: payment_status,
            payment_id: values.payment_id,
            success: values.success === 'true',
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );

        if (response.status === 200) {
          setMsg('Paiement réussi!');
        } else {
          setMsg('Échec du paiement!');
        }
      } catch (error) {
        console.error('Payment update error:', error);
        setMsg(
          error.response?.data?.message ||
            'Échec du paiement! Veuillez contacter le support.'
        );
      } finally {
        setLoading(false);
      }
    };

    if (values.success || values.canceled) {
      updatePaymentStatus();
    } else {
      setMsg('Invalid payment session');
      setLoading(false);
    }
  }, [location.search]);

  const SuccessIcon = () => (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className='h-16 w-16 text-green-500'
      fill='none'
      viewBox='0 0 24 24'
      stroke='currentColor'>
      <path
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={2}
        d='M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z'
      />
    </svg>
  );

  const FailureIcon = () => (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className='h-16 w-16 text-red-500'
      fill='none'
      viewBox='0 0 24 24'
      stroke='currentColor'>
      <path
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={2}
        d='M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z'
      />
    </svg>
  );

  return (
    <div className='min-h-screen bg-gray-50'>
      <section className='relative block h-[30vh]'>
        {/* <div className='absolute inset-0 bg-gradient-to-r from-blue-600 via-blue-700 to-blue-800' /> */}
        {/* <div className='absolute inset-0 ' /> */}
        <div className='h-[30vh] bg-center bg-cover'  style={{
                backgroundImage: `url(${background})`,
            }}></div>
      </section>

      <section className='max-w-lg mx-auto px-4 -mt-40 relative z-10'>
        <div className='bg-white rounded-2xl shadow-xl overflow-hidden transform transition-all hover:shadow-2xl'>
          <div className='p-8 md:p-12'>
            <div className='flex flex-col items-center space-y-6'>
              {loading ? (
                <div className='flex items-center justify-center'>
                  <div className='animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500'></div>
                </div>
              ) : mes ? (
                <div className='text-center space-y-4'>
                  <div className='flex justify-center transform transition-transform hover:scale-105 duration-200'>
                    {mes === 'Paiement réussi!' ? (
                                 <img src={successIcon} alt="successIcon" className=' w-[57.6px] h-[57.6px] bg-[#4FBF26D9] rounded-full p-2' />

                    ) : (
                      <FailureIcon />
                    )}
                  </div>
                  <h2
                    className={`text-xl font-bold ${
                      mes === 'Paiement réussi!'
                        ? 'text-tcfsectext'
                        : 'text-tcfred'
                    }`}>
                    {mes}
                  </h2>
                </div>
              ) : null}

              {!loading && (
                <button
                  onClick={handleRedirect}
                  className={`w-full bg-tcfsectext text-sm text-white h-[40px] mg:h-[50px] rounded-[5px] font-semibold shrink-0 mb-[16px]
                    transform transition-all duration-200 hover:-translate-y-0.5 hover:shadow-xl
                   `}>
                  {button_text}
                </button>
                 
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default StripePaymentConfirmation;
