// src/app/store.js
import { configureStore } from "@reduxjs/toolkit";
import { persistStore , persistReducer} from "redux-persist";
import storage from "redux-persist/lib/storage"; // Use local storage for persistence
import authReducer from "./authSlice";
import questionsReducer from "./questionsSlice";
import responseReducer from "./answerSlice";
import timeReducer from "./timeSlice"; // Import the time slice
import scoreReducer from "./scoreSlice"; // Import the slice

// Persist configuration
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth"], // Choose which reducers to persist (e.g., auth)
}; 
const persistedAuthReducer = persistReducer(persistConfig, authReducer);

const store = configureStore({
  reducer: {
    auth: authReducer,
    questions: questionsReducer,
    response: responseReducer,
    time: timeReducer,
    score: scoreReducer, // Register the reducer
   
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore non-serializable Redux Persist actions
        ignoredActions: ["persist/PERSIST", "persist/REHYDRATE"],
      },
    }),
});

const persistor = persistStore(store);

export { store, persistor };




