import React, { useState, useEffect, } from 'react';
import { useNavigate } from 'react-router-dom';
import RoundProgressBar from './RoundProgress';
import ProgressBar from './ProgressBar';
import GradingSheet from './GradingSheet';
import { useSelector, useDispatch } from "react-redux";
import { resetScore } from '../store/scoreSlice'


const ResultsStats = ({ selectedTest, totalQuestions, correctAnswersCount, handleShowCorrections }) => {



  const dispatch = useDispatch();
  const navigate = useNavigate(); // If navigation is needed

  const handleRestart = () => {
    dispatch(resetScore()); // Reset score in Redux
    navigate("/dashboard"); // Change "/quiz" to the actual route for your quiz
  };


  const score = correctAnswersCount / totalQuestions
  const progress = totalQuestions > 0 ? (score * 100).toFixed(2) : 0;
  const elapsedTime = useSelector((state) => state.time.elapsedTime);
  // const userelapsedTime = useSelector((state) => state.question.userelapsedTime);
  const [elapsedTimeState, setElapsedTimeState] = useState(0);
  const totalScore = useSelector((state) => state.score.totalScore); // Get total score from Redux

  // ✅ Only update when elapsedTime changes
  useEffect(() => {
    setElapsedTimeState(elapsedTime);
  }, [elapsedTime]);

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = Math.floor(timeInSeconds % 60);
    return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
  };

  // console.log('test', selectedTest)
  // console.log('elapsedtime on results page', elapsedTime)
  return (


    <div className=' flex flex-col justify-between gap-[16px]  h-full w-full mt-[35px] bg-white text-tcfsectext px-[25px] lg:px-[65px] py-[33px] w-full border border-[#C1C1C180] rounded-[5px] shadow-md '>

      <div className='flex flex-col lg:flex-row gap-[44px] lg:gap-[16px] w-full '>
        <div className='w-full lg:flex lg:flex-1'>
          <div className='flex flex-col gap-[22px]  w-full'>
            <div className='space-y-[2px]'>
              <div className='flex justify-between items-center'>
                <p className='flex  text-sm font-bold'>Résultats</p>
                <div className='flex flex-col lg:hidden justify-between gap-y-[10px]'>
                  <button onClick={handleShowCorrections} className={`underline text-base  h-[40px] md:h-[30px] rounded-[5px]  text-tcfsectext`}>
                    Voir les résponses
                  </button>
                  {/* <button onClick={handleRestart} className={`font-bold border text-base px-[23px] h-[40px] md:h-[30px] mrounded-[5px]  border-tcfsectext rounded-[5px] `}>
                      Relancer le quiz
                    </button> */}
                </div>
              </div>

              <p className="text-sm">{correctAnswersCount} sur {totalQuestions} Questions sont correctes</p>
            </div>

            <div className='flex gap-[17px] items-center'>
              <RoundProgressBar progress={progress} strokeWidth={5} size={85} label={`${correctAnswersCount}/${totalQuestions}`} />
              <div>
                <p className="text-sm">Votre temps</p>
                <p className="text-2xl font-bold">{formatTime(elapsedTimeState)}</p>
                {/* <p className="text-2xl font-bold">{formatTime(userelapsedTime)}</p> */}

              </div>
            </div>

            <div className='border border-tcfgray1 w-full  py-[11px] rounded-[5px]'>
              <p className='flex  px-[16px] text-sm '>Note moyenne: 64.22%</p>
              <p className=' border-t border-tcfgray1 my-[12px]'></p>
              <div className='whitespace-nowrap mt-[2px] px-[16px] space-y-[10px] mb-[6px]'>
                <p className='flex text-sm '>Votre score: {progress}% ( <strong className='sm:pr-1 sm:pl-1'> {totalScore} points</strong>)</p>
                <ProgressBar timer={false} progress={progress} width='w-[75%]' />
              </div>

            </div>
          </div>

        </div>
        <GradingSheet testType="Comprehension Écrite" />



      </div>
      <div className='hidden lg:flex flex-col md:flex-row justify-between gap-y-[10px]'>
        <button onClick={handleShowCorrections} className={`font-bold border text-base px-[30px] h-[40px] md:h-[30px] rounded-[5px] bg-tcfsectext text-white`}>
          Voir les résponses
        </button>
        {/* <button onClick={handleRestart} className={`font-bold border text-base px-[23px] h-[40px] md:h-[30px] mrounded-[5px]  border-tcfsectext rounded-[5px] `}>
          Relancer le quiz
        </button> */}

      </div>



    </div>





  );
};

export default ResultsStats;
