import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TabView from './TestView';
import TaskNumbers from './QuestionNavigation';
import ResponseSection from './ComprehensionTestSection';
import apiClient from '../services/httpService';
import { useDispatch, useSelector } from "react-redux";
import ProgressBar from './ProgressBar';
import { resetSelectedTest } from "../store/questionsSlice";
import locked from '../images/locked.svg'



const ComprenensionEcriteSection = ({ setActiveSection , showCorrections={showCorrections} }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();


    const [tests, setTests] = React.useState([]);
    const { selectedTest, questions, currentIndex } = useSelector((state) => state.questions);
    // const [showCorrections, setShowCorrections] = useState(false);


    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated); // Get auth state

    const getTestsForCategory = async () => {
        try {
            const response = await apiClient.get('/mgmt/tests/001/'); //Comprehension Ecrite
            if (response.status === 200) {
                setTests(response.data);

            }
        } catch (error) {
            console.error('Failed to fetch tests', error);
        }
    };

    useEffect(() => {
        getTestsForCategory();
    }, []);

    const handleBackToTests = () => {
        // setShowCorrections(false)
        dispatch(resetSelectedTest());
    };


    // Function to extract the series number from the test name
    const getSeriesNumberFromTestName = (testName) => {
        const match = testName.match(/s?r?ie\s*(\d+)/i); // Matches "serie" or "série" followed by digits
        return match ? match[1] : null;
    };

    const getTestNumber = (testName) => {
        const match = testName.match(/test\s*(\d+)/i);  // 'i' makes it case-insensitive
        return match ? match[1] : null;  // Returns the test number or null if not found
    };


    // Function to get the selected test details (name and series number)
    const getSelectedTestDetails = () => {
        const selectedTestObj = tests.find((test) => test.id === selectedTest);
        if (selectedTestObj) {
            const testName = selectedTestObj.name;
            const seriesNumber = getSeriesNumberFromTestName(testName);
            const testNumber = getTestNumber(testName);
            return {
                testNumber,
                seriesNumber,
            };
        }
        return {
            testNumber: '',
            seriesNumber: null,
        };
    };
    

    const { testNumber, seriesNumber } = getSelectedTestDetails();


    return (
        // add w-[70%] ml-auto later
        <div className="w-full flex flex-col gap-1  ">

            {!selectedTest && <TabView tests={tests} testName= {showCorrections ? "Correction des comprehension écrite" : 'Comprehension Ecrite' } />}


            {selectedTest && (


                <div className='flex flex-col'>
                    <label className='text-center text-white font-bold text-2xl lg:text-7xl mb-[48px]'>{showCorrections ? "Correction des comprehension écrite" : 'Compréhension écrite test '} (Série {seriesNumber}) </label>
                    <div className='text-white flex flex-col md:flex-row gap-[16px] lg:gap-x-[70px] mg:gap-x-[157px] items-center mb-4'>
                        <button
                            onClick={handleBackToTests}
                            className="  font-bold border border-white px-[35px] h-[40px] rounded-[5px] w-full  md:w-fit "
                        >
                            Retour au test
                        </button>
                        {/* {isAuthenticated && (
                            <button
                                onClick={() => setShowCorrections(!showCorrections)}
                                className="bg-[#50B303] h-[40px] px-[58px] rounded-[5px] text-white w-full  md:w-fit"
                            >
                                {showCorrections ? "Masquer les réponses" : "Voir les réponses"}
                            </button>
                        )} */}
                    </div>

                    {/* mb-[117px] */}
                    <div className='flex flex-col lg:flex-row gap-[16px] h-fit   '>
                        <div className='bg-white shrink-0 w-full lg:w-[228px] mg:w-[316px] border border-[#C1C1C180] rounded-[5px] pt-[27px] pb-[36px] shadow-md px-[24px] md:px-[26px] '>
                            <TaskNumbers questions={questions?.normal_questions} showCorrections={showCorrections} />

                        </div>
                        {questions && (
                            <div className='flex flex-col bg-white text-tcfsectext px-[25px] md:px-[42px] lg:px-[65px] py-[33px] w-full border border-[#C1C1C180] rounded-[5px] shadow-md '>
                                <div className='flex justify-between items-center'>
                                    <p className='flex gap-[12px] text-base md:text-sm font-bold'>Question {currentIndex + 1}<span className='font-bold text-[10px] md:text-xs bg-tcfmediumgreen h-[20px] px-[10px] rounded-[5px] '>{questions?.normal_questions[currentIndex]?.points} points</span></p>
                                    {/* <p className="text-xl font-bold  self-start text-white rounded-l-md rounded-br-[14px] rounded-tr-[2px] border-none bg-black px-5 py-2 ">{currentIndex + 1}</p> */}

                                    {!showCorrections && (
                                        <div className='flex items-center gap-[5px]'>
                                            <ProgressBar totalTime={questions?.duration} width='w-[52px] md:w-[100px]' />
                                        </div>
                                    )}

                                </div>
                                <div className='mt-[18px]'>


                                    <ResponseSection question={questions?.normal_questions[currentIndex]} setActiveSection={setActiveSection} showCorrections={showCorrections} />


                                </div>

                            </div>
                        )}

                    </div>
                </div>)}

        </div>
    );
};

export default ComprenensionEcriteSection;
