
// src/pages/SignUpPage.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import HomeNavigation from '../components/Navbar';
import Footer from '../components/Footer';
import background from '../images/noisy-gradients.png';
import successIcon from '../images/success.svg'


const PasswordResetSuccess = () => {
  const navigate = useNavigate();

  const handleSubmit = () => {
    navigate('/login');
  };



  return (

    <div className="flex flex-col min-h-screen justify-between">

      <div className=' text-tcfsectext pt-[147px]  md:pt-[173px]  lg:pt-[205px] h-[265px] md:h-[269px] lg:h-[326px] mg:h-[399px] bg-cover bg-center ' style={{

        backgroundImage: `url(${background})`,


      }}>
        <div className='text-center w-full text-white flex flex-col justify-center  ' >
          <HomeNavigation />

          <h2 className=" text-3xl md:text-4xl lg:text-7xl font-bold mg:mt-[68px]  px-[37px]">Réinitialisation du mot
            de passe</h2>

        </div>
        <div className=' w-full pt-[70px]  md:pt-[50px] lg:pt-[75px] mg:pt-[82px] mb-[203px] md:mb-[161px] flex flex-col items-center justify-center md:px-0'>

          <div className=' w-full px-[32px]  md:px-0 gap-[25px] lg:gap-[28px] md:w-[50%] md:w-[38%] pt-[79px] lg:pt-[75px] mg:pt-[82px] flex flex-col items-center justify-center '>
            <img src={successIcon} alt="successIcon" className=' w-[57.6px] h-[57.6px] bg-[#4FBF26D9] rounded-full p-2' />

            <p className='text-center'>Votre mot de passe a été réinitialisé avec succès. Cliquez ci-dessous pour
              vous connecter</p>
            <button
              onClick={handleSubmit}
              className='w-full bg-tcfsectext text-sm text-white h-[40px] mg:h-[50px] rounded-[5px] font-semibold shrink-0 mb-[16px]'>
              Se connecter
            </button>


          </div>



        </div>
        <Footer />
      </div>

    </div>
  );
};




export default PasswordResetSuccess;

