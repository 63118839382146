/** @format */

// LoginPage.js
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Footer from '../components/Footer';
import HomeNavigation from '../components/Navbar';
import loginbanner from '../images/looginbanner.png';
import background from '../images/noisy-gradients.png';
import InputField from '../components/InputField';
import axios from 'axios';

const ResetPasswordPage = () => {
  const apiURL = process.env.REACT_APP_APIURL || '';
  const [email, setEmail] = useState('');
  const [passwords, setPasswords] = useState({
    new_password: '',
    re_new_password: '',
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { uid, token } = useParams();
  const navigate = useNavigate();
  const handleChange = (name, value) => {
    setPasswords((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    if (passwords.new_password !== passwords.re_new_password) {
      setError('Les mots de passe ne correspondent pas');
      return;
    }

    try {
      setLoading(true);
      setError(null);
      console.log('passwords', {
        uid,
        token,
        new_password: passwords.new_password,
        re_new_password: passwords.re_new_password,
      });
      const response = await axios.post(
        `${apiURL}/auth/users/reset_password_confirm/`,
        {
          uid,
          token,
          new_password: passwords.new_password,
          re_new_password: passwords.re_new_password,
        }
      );
      console.log('response.status', response.status);
      navigate('/reset-success');

      // if (response.status === 200) {
      //   navigate('/login');
      // }
    } catch (err) {
      setError(err.response?.data?.detail || 'Une erreur est survenue');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='flex flex-col  gap-[35vh] md:gap-0 justify-between'>
      {/* Login Section */}
      <div className='  flex flex-col justify-between min-h-screen md:min-h-[85vh] lg:min-h-[120vh]  lg:min-h-[140vh]'>
        {/* Section 1 */}
        <section
          className='w-full flex pr-[32px] md:pr-[40px] lg:pr-[100px] mg:pr-[96px] xl:pr-[190px] pl-[32px] mg:pl-[64px] xl:pl-[102px] pt-[209px] md:pt-[183px]  lg:pt-[209px]  mg:pt-[277px] h-[298px] md:h-[298px] lg:h-[419px] xl:h-[433px] bg-cover bg-center '
          style={{
            backgroundImage: `url(${background})`,
          }}>
          <div className='z-10'>
            <HomeNavigation />
          </div>
          {/* Navbar at the top */}

          <div className='w-full flex flex-col md:flex-row gap-y-[36px] md:gap-x-[16px] lg:gap-x-[38px] xl:gap-x-[111px] justify-around'>
            {/* Image */}
            <div className='flex-1 flex'>
              <img
                src={loginbanner}
                alt='login'
                className='w-full  h-[341px] md:h-[325px] lg:h-[489px] xl:h-[623px] object-cover rounded-[5px]'
              />
            </div>

            {/* Login Form */}
            <div className='flex-1 flex'>
              <div className='flex flex-col w-full text-tcfsectext md:text-white'>
                <h3 className='text-3xl md:text-4xl lg:text-7xl font-bold md:mb-[5px] mg:mb-0'>
                  Changer le mot de passe
                </h3>
                <p className=' lg:hidden mb-[26px]  md:mb-[66px] lg:mb-[55px] mg:mb-[82px]'>
                  Entrez votre nouveau mot de passe
                </p>
                <p className='hidden lg:block mb-[26px]  md:mb-[44px] lg:mb-[78px] mg:mb-[82px]'>
                  Entrez votre email pour recevoir le lien de réinitialisation
                  du mot de passe
                </p>

                <div className='flex flex-col justify-center w-full gap-[16px]'>
                  <InputField
                    label='Mot de passe'
                    type='password'
                    placeholder='Votre mot de passe'
                    showPasswordToggle
                    showicon={false}
                    handleChange={handleChange}
                    name='new_password'
                    style='bg-tcfbg  h-[40px] mg:h-[50px]'
                  />
                  <InputField
                    label='Confirmez le mot de pass'
                    type='password'
                    placeholder='Confirmez votre mot de passe'
                    showPasswordToggle
                    showicon={false}
                    handleChange={handleChange}
                    name='re_new_password'
                    style='bg-tcfbg  h-[40px] mg:h-[50px]'
                  />
                  <button
                    onClick={handleSubmit}
                    className='w-full bg-tcfsectext text-sm text-white h-[40px] mg:h-[50px] rounded-[5px] font-semibold shrink-0 mb-[16px]'>
                    Changer le mot de passe
                  </button>
                </div>
                <a
                  href='/login'
                  className='text-center text-base md:text-tcfsectext lg:text-white  xl:text-tcfsectext underline solid mb-[9px]'>
                  Retour à la connexion
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className=''>
        <Footer />
      </div>
    </div>
  );
};

export default ResetPasswordPage;
