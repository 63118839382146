import React from "react";
import book from '../images/book.svg'
import books from '../images/books.svg'
import savings from '../images/savings.svg'
import school from '../images/school.svg'



const CardComponent = () => {
    const cards = [
        {
            icon: book,
            title: "Apprentissage avancé",
            description:
                "Préparez-vous avec des tests précis et des simulations réelles d’examen.",
        },
        {
            icon: books,
            title: "Cours Personnalisés",
            description:
                "Des programmes adaptés à votre rythme pour une maîtrise complète du TCF.",
        },
        {
            icon: savings,
            title: "Expertise Prouveé",
            description:
                "Profitez de l’expérience de formateurs qui ont guidé plus de 600 réussites annuelles.",
        },
        {
            icon: school,
            title: "Investissement Intelligent",
            description:
                "L'excellence à des prix justes, investissez dans la qualité, récoltez la réussite.",
        },
    ];

    return (
        <div className="relative  px-[32px] md:px-[61px] lg:px-[120px]">
            <div className="grid grid-cols-1 md:grid-cols-2 md:gap-x-[42px] gap-x-[87px] gap-y-[20px] md:gap-y-[31px] ">
                {cards.map((card, index) => (
                    <div key={index} className="flex">
                        <div className="w-[10px] h-[130px] bg-tcfsectext "></div>
                        <div  className=" w-full  ">
                            <div className=" pt-[23px] px-[10px] text-start border bg-tcfbg h-[130px] ">
                                <div className="flex gap-[23px] items-start text-tcfsectext">
                                    <img className=" " src={card.icon} alt='' />
                                    <span><h3 className="text-sm font-bold mb-[8px]">{card.title}</h3>
                                        <p className="text-sm font-normal">{card.description}</p>
                                    </span>
                                </div>


                            </div>


                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default CardComponent;
