import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setSubscriptionStatus } from "../store/authSlice";
import apiClient from "../services/httpService"; // Import your API client

const fetchSubscriptionStatus = async (dispatch) => {
  try {
    const response = await apiClient.get("/user/check-subscription/");
    if (response.status === 200) {

      dispatch(setSubscriptionStatus(response.data?.status || "Not Active"));
    //   console.log("response.statuss", response.data?.status );
    }
  } catch (error) {
    console.error("Failed to fetch subscription status", error);
    dispatch(setSubscriptionStatus("Not Active")); // Default if request fails
  }
};

const SubscriptionStatusChecker = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    fetchSubscriptionStatus(dispatch);
  }, [dispatch]);

  return null; // This component just runs the effect
};

export default SubscriptionStatusChecker;
