// src/features/auth/authSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // localStorage

const initialState = {
  user: null,
  accessToken: null,
  refreshToken: null,
  isAuthenticated: false,
  subscriptionStatus: "Not Active", 
  signUp:{
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    country: '',
    phone: ''
  }
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginSuccess: (state, action) => {
      state.user = action.payload.user;
      state.accessToken = action.payload.access;
      state.refreshToken = action.payload.refresh;
      state.isAuthenticated = true;
    },
    logoutSuccess: (state) => {
      state.user = null;
      state.accessToken = null;
      state.refreshToken = null;
      state.isAuthenticated = false;
      state.subscriptionStatus = "Not Active";
    },
    setSubscriptionStatus: (state, action) => {
      state.subscriptionStatus = action.payload; // Update subscription status
    },
    setSignUpEmail: (state, action) => {
      state.signUp.email = action.payload;
    },
    setSignUpFirstName: (state, action) => {
      state.signUp.firstName = action.payload;
    },
    setSignUpLastName: (state, action) => {
      state.signUp.lastName = action.payload;
    },
    setSignUpPassword: (state, action) => {
      state.signUp.password = action.payload;
    },
    setSignUpCountry: (state, action) => {
      state.signUp.country = action.payload;
    },
    setSignUpPhone: (state, action) => {
      state.signUp.phone = action.payload;
    },
    refreshToken: (state, action) => {
      state.accessToken = action.payload.accessToken;
    },
  },
});

export const { loginSuccess, logoutSuccess,setSubscriptionStatus, refreshToken, setSignUpEmail, setSignUpFirstName, setSignUpLastName, setSignUpPassword, setSignUpCountry, setSignUpPhone } =
  authSlice.actions;

// Persist config for localStorage
const persistConfig = {
  key: "auth",
  storage,
  whitelist: ["accessToken", "user", "isAuthenticated","subscriptionStatus"], // Persist only these keys
};

const persistedAuthReducer = persistReducer(persistConfig, authSlice.reducer);

export default persistedAuthReducer;
