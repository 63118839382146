import React, { useRef, useState, useEffect } from "react";
import playIcon from "../images/play.svg";
import pauseIcon from "../images/pause.svg";
import volumeIcon from "../images/volume.svg";
import AudioProgressbar from "./AudioProgressbar";
import '../index.css'; // Import global styles

const AudioPlayer = ({ audioUrl }) => {
    const audioRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [volume, setVolume] = useState(1); // Default volume to max (1)

    const togglePlay = async () => {
        if (!audioRef.current) return;

        try {
            if (audioRef.current.paused) {
                await audioRef.current.play();
                setIsPlaying(true);
            } else {
                audioRef.current.pause();
                setIsPlaying(false);
            }
        } catch (error) {
            console.error("Error playing audio:", error);
        }
    };

    useEffect(() => {
        if (audioRef.current && audioRef.current.src) {
            audioRef.current.volume = volume;
        }
    }, [volume]);


    

    // Handle when audio finishes playing
    const handleAudioEnd = () => {
        setIsPlaying(false);
    };

    return (
        <div className="flex flex-col md:flex-row justify-between gap-[10px] mt-[28px]">
            {/* Audio Player Section */}
            <div className="md:flex-[2] flex items-center gap-[10px] justify-center w-fit bg-tcfbg h-[40px] rounded-[5px] px-[14px] w-full">
                <img
                    className="cursor-pointer"
                    src={isPlaying ? pauseIcon : playIcon}
                    alt="play"
                    onClick={togglePlay}
                />
                <AudioProgressbar  width="w-[100%]" audioRef={audioRef} />
            </div>

            {/* Volume Control Section */}
            <div className="w-full md:flex-[1] flex items-center justify-center bg-tcfbg h-[40px] rounded-[5px] px-[14px] gap-[8px]">
                <img className="" src={volumeIcon} alt="volume" />
                <input
                    type="range"
                    min="0"
                    max="1"
                    step="0.01"
                    value={volume}
                    onChange={(e) => setVolume(parseFloat(e.target.value))}
                    className="volume-slider w-full cursor-pointer appearance-none h-[6px] rounded-full"
                    style={{
                        background: `linear-gradient(to right, #12372A ${volume * 100}%, #C2F7C9 ${volume * 100}%)`
                    }}
                />
            </div>



            {/* Hidden Audio Element */}
            <audio ref={audioRef} src={audioUrl} onEnded={handleAudioEnd}></audio>
        </div>
    );
};

export default AudioPlayer;
