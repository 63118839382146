import React from 'react';

const TeamCard = ({ name, position, isCenterCard }) => {
  return (
    <div
      className={`relative border rounded-[5px] bg-[#D9D9D9] shrink-0 ${
        isCenterCard ? 'w-[241px] h-[202px] lg:w-[281px] lg:h-[236px]' : 'w-[192px] h-[152px] lg:w-[224px] lg:h-[178px]'
      }`}
    >
      <div className={`absolute px-[13px]  bg-white  space-y-[2px] text-tcfsectext rounded-[5px] ${isCenterCard ? 'py-[11px]  bottom-[17px] right-[18px] left-[18px]' : 'py-[8px]  bottom-[15px] right-[14px] left-[13px]'}`}>
        <h3 className="text-sm font-bold text-start">{name}</h3>
        <p className="text-base font-normal text-start">{position}</p>
      </div>
    </div>
  );
};

export default TeamCard;
