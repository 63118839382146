import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  elapsedTime: 0, // Store elapsed time in seconds
};

const timeSlice = createSlice({
  name: "time",
  initialState,
  reducers: {
    setElapsedTime: (state, action) => {
      state.elapsedTime = action.payload;
    },
    resetElapsedTime: (state) => {
      state.elapsedTime = 0;
    },
  },
});

export const { setElapsedTime, resetElapsedTime } = timeSlice.actions;
export default timeSlice.reducer;
