// Header.js
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logoutSuccess } from '../store/authSlice';
import profileimage from '../images/profileimage.jpg'
import SubscriptionUpgrade from './SubscriptionUpgrade';
import cameraIcon from '../images/camera.svg'

const Header = ({ setActiveSection, currentPlan, currentSubType }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(logoutSuccess());
    navigate("/login");
  };
  const { user } = useSelector((state) => state.auth);

  const getFormattedDate = () => {
    const today = new Date();

    // Define options for the desired date format
    const options = {
      weekday: 'long', // Full weekday name (e.g., "Monday")
      year: 'numeric', // Full year (e.g., "2025")
      month: 'long', // Full month name (e.g., "November")
      day: '2-digit', // Day of the month as 2 digits (e.g., "04")
    };

    // Format the date using the specified options and French locale
    const formattedDate = today.toLocaleDateString('fr-FR', options);

    // Capitalize each part of the date (e.g., "Lundi", "Novembre", etc.)
    const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

    // Split the formatted date and capitalize each part
    const splitDate = formattedDate.split(' ');
    const capitalizedDate = splitDate.map(capitalize).join(' ');
    // console.log('user',user)
    return capitalizedDate;
  };

  return (
    <div className='flex justify-between items-end'>
      <div className="w-full flex flex-col  gap-[7px] md:gap-[11px]">
        <div className='relative  md:hidden'>
          <img src={profileimage} alt="User" className="relative w-[70px] h-[70px] rounded-full object-cover" />
          <div className='flex items-center justify-center rounded-full size-10 bg-tcfmediumgreen absolute left-10 top-8 '>
            <img src={cameraIcon} alt="camera" className=" " />
          </div>
        </div>
        <div className='text-white flex gap-6'>
          <div className='relative hidden md:block lg:hidden'>
            <img src={profileimage} alt="User" className=" relative w-[70px] h-[70px] rounded-full object-cover " />
            <div className='flex items-center justify-center rounded-full size-10 bg-tcfmediumgreen absolute left-10 top-8 '>
              <img src={cameraIcon} alt="camera" className=" " />
            </div>
          </div>

          {/* <img src={`${user?.image || {profileimage}}`} alt="User" className="w-[70px] h-[70px] rounded-full object-cover hidden md:block lg:hidden" /> */}
          <div className='md:sapce-y-[6px]'>
            <p className='text-3xl md:text-3xl lg:text-7xl font-bold '>Bonjour {user?.first_name}</p>
            <p>{getFormattedDate()}</p>
            {/* Number of Tests: {user?.number_of_tests_done} */}
          </div>
        </div>
        <button onClick={() => setActiveSection("accountsettings")} className=" mt-[22px] hidden md:block lg:hidden font-bold text-sm  text-white  bg-tcfsectext h-[40px] px-[49px] rounded-[5px] w-fit  ">Mon Compte</button>


      </div>
      <div className='hidden md:block lg:hidden'>
        <SubscriptionUpgrade currentPlan={currentPlan} currentSubType={currentSubType} />
      </div>

    </div>


  )
};

export default Header;
