// src/components/CountrySelect.js
import React from 'react';

// const CountrySelect = () => (
//   <div className="mb-4">
//     <select className="w-full p-[16px] border rounded-md bg-gray-100 outline-none">
//       <option>Guinea</option>
//       {/* Add more country options as needed */}
//     </select>
//   </div>
// );

// export default CountrySelect;

import countryData from 'country-data';

const CountrySelect = ({ handleChange, name}) => {
  const countries = countryData.countries.all;

  return (
    <div className="">
      <select className="w-full px-[16px] py-[11px] rounded-[5px] bg-tcfbg outline-none text-base text-peepinput">
        <option value="">Guinea</option>
        {countries.map((country,  index) => (
          <option  key={`${country.alpha2}-${index}`} value={country.alpha2} onClick={() => handleChange(name, country.name)}>
            {country.name} 
          </option>
        ))}
      </select>
    </div>
  );
};

export default CountrySelect;
