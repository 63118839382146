import React, { useState } from "react";
import { useSelector } from "react-redux";
import profileimage from "../images/profileimage.jpg";
import home from "../images/home.svg";
import ecrite from "../images/ecrite.svg";
import listen from "../images/listen.svg";
import learn from "../images/learn.svg";
import speak from "../images/speak.svg";
import SubscriptionUpgrade from "./SubscriptionUpgrade";
import cameraIcon from '../images/camera.svg'

const Sidebar = ({ setActiveSection, currentPlan, currentSubType }) => {
  const { user } = useSelector((state) => state.auth);
  const [openDropdown, setOpenDropdown] = useState(null);

  const toggleDropdown = (id, e) => {
    e.stopPropagation();
    setOpenDropdown((prev) => (prev === id ? null : id));
  };

  const menuItems = [
    { id: "dashboard", label: "Tableau de bord", icon: home },
    { id: "comprehensionecritesection", label: "Comprehension Écrite", icon: ecrite, subSection: "comprehensionecritecorrectionsection" },
    { id: "comprehensionoralesection", label: "Comprehension Orale", icon: listen, subSection: "comprehensionoralecorrectionsection" },
    { id: "expressionecritesection", label: "Expression Écrite", icon: learn, subSection: "expressionecritecorrectionsection" },
    { id: "expressionoralesection", label: "Expression Orale", icon: speak, subSection: "expressionoralecorrectionsection" },
  ];

  return (
    <aside className="flex flex-col justify-between flex-[1] lg:min-w-[246px] mg:min-w-[316px] shrink-0 lg:bg-white text-tcfsectext lg:py-[22px] lg:px-[18px] lg:border border-tcfgray1 rounded-[5px] shadow-md mg:px-[31px]">
      <div className="flex flex-col justify-between">
        {/* Profile Section */}
        <div className="relative hidden lg:flex gap-4 items-center mb-[33px]">
          <img src={profileimage} alt="User" className="w-[50px] h-[50px] mg:w-[70px] mg:h-[70px] rounded-full object-cover" />
          <div className='flex items-center justify-center rounded-full size-6 xl:size-8 bg-tcfmediumgreen absolute left-8 top-8 xl:left-12 xl:top-8'>
            <img src={cameraIcon} alt="camera" className=" size-4 xl:size-auto" />
          </div>
          <div className="flex flex-col">
            <h2 className="text-sm mg:text-2xl whitespace-nowrap font-bold">
              {user?.first_name} {user?.last_name}
            </h2>
            <p className="text-base mg:text-sm text-tcfinput">@{user?.first_name}</p>
          </div>
        </div>

        {/* Account Settings */}
        <div className="mb-[19px] lg:mb-[24px]">
          <p className="hidden lg:block text-tcfinput font-bold text-base mb-[11px]">PROFIL</p>
          <button onClick={() => setActiveSection("accountsettings")} className="md:hidden lg:block font-bold text-sm text-white bg-tcfsectext h-[40px] px-[49px] rounded-[5px] w-full">
            Mon Compte
          </button>
        </div>

        <hr className="hidden lg:block border-t border-tcfbackground" />

        {/* Menu */}
        <div className="lg:mt-[13px]">
          <p className="hidden lg:block text-tcfinput font-bold text-base mb-[20px]">MENU</p>

          <nav className="grid grid-cols-5 lg:grid-cols-1 justify-around gap-[12px] lg:gap-[6px] text-base font-[500] bg-white lg:border-none border border-tcfgray1 lg:bg-none py-[8px] lg:py-0 w-full rounded-[5px] shadow-md lg:shadow-none items-center">
            {menuItems.map((item) => (
              <div key={item.id} className="relative z-[1] ">
                <div
                  className="flex flex-col lg:flex-row gap-[6px] lg:gap-[9px] items-center lg:h-[45px] mg:h-[60px] md:hover:px-[11px] rounded-[5px] focus:bg-tcfmediumgreen hover:bg-tcfmediumgreen cursor-pointer"
                  onClick={() => setActiveSection(item.id)}
                >
                  {/* Small Screens: Icon beside Dropdown */}
                  <div className="flex items-center gap-2 lg:hidden">
                    <img src={item.icon} alt="" className="lg:size-7 mg:size-8" />
                    {item.subSection && (
                      <svg
                        onClick={(e) => toggleDropdown(item.id, e)}
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className={`transition-transform duration-300 ${openDropdown === item.id ? "rotate-180" : "rotate-0"}`}
                      >
                        <path d="M12 15L7 10H17L12 15Z" fill="#12372A" />
                      </svg>
                    )}
                  </div>

                  {/* Label below icon in small screens */}
                  <button className="block text-center py-[2px] w-full text-[8px] leading-[10.24px] md:text-xs lg:text-base  lg:hidden">
                    {item.label}
                  </button>

                  {/* Larger Screens: Icon, Label, then Dropdown */}
                  <div className="hidden lg:flex items-center gap-[9px] w-full justify-between">
                    <div className="flex items-center gap-[9px]">
                      <img src={item.icon} alt="" className="mg:size-7" />
                      <span className="font-[500]">{item.label}</span>
                    </div>
                    {item.subSection && (
                      <svg
                        onClick={(e) => toggleDropdown(item.id, e)}
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className={`transition-transform duration-300 ${openDropdown === item.id ? "rotate-180" : "rotate-0"}`}
                      >
                        <path d="M12 15L7 10H17L12 15Z" fill="#12372A" />
                      </svg>
                    )}
                  </div>
                </div>

                {/* Sub-section Dropdown (Placement Unchanged) */}
                {openDropdown === item.id && item.subSection && (
                  <div className="hidden lg:flex justify-end my-2">
                    <div className="flex gap-[7px] w-[80%]">
                      <div className="w-[2px] h-auto bg-tcfbg shrink-0"></div>
                      <button
                        className="block font-[500] my-2 flex items-center py-[8px] px-[14px] w-full text-[8px] leading-[10.24px] md:text-xs lg:text-base mg:text-sm bg-tcfbg rounded-[5px]"
                        onClick={(e) => {
                          e.stopPropagation();
                          setActiveSection(item.subSection);
                          setOpenDropdown(null);
                        }}
                      >
                        Correction
                      </button>
                    </div>
                  </div>
                )}

                {/* Floating Dropdown for Small Screens */}
                {openDropdown === item.id && item.subSection && (
                  <div className="absolute left-0 w-full bg-white rounded-[5px] shadow-md mt-4 py-[13px] z-40 lg:hidden shadow-md ">
                    <button
                      className="block font-[500] w-full  text-center text-[8px] leading-[10.81px] md:text-base py-2 bg-tcfbg"
                      onClick={(e) => {
                        e.stopPropagation();
                        setActiveSection(menuItems.find((item) => item.id === openDropdown)?.subSection);
                        setOpenDropdown(null);
                      }}



                    >
                      Correction
                    </button>
                  </div>
                )}
              </div>
            ))}
          </nav>
        </div>
      </div>

      {/* Subscription Section */}
      {currentPlan != 'Diamond' && (
        <div className="hidden lg:block mt-[46px]">
          <p className="text-tcfinput font-bold text-base mb-[14px]">ABONNEMENT</p>
          <hr className="border-t border-tcfbackground mb-4" />
          <SubscriptionUpgrade currentPlan={currentPlan} currentSubType={currentSubType} />
        </div>)}

    </aside>
  );
};

export default Sidebar;
