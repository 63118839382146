import React, { useState } from "react";
import { useSelector } from "react-redux";
import InputField from "./InputField";
import SubscriptionTable from "./SubscriptionTable";




// Profile Settings Component
const ProfileSettings = () => {
    const { user } = useSelector((state) => state.auth);
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [error, setError] = useState('');

    const handlePasswordChange = () => {
        if ( !newPassword || !confirmNewPassword) {
            setError("Tous les champs sont requis");
            return;
        }

        if (newPassword.length < 6) {
            setError("Le nouveau mot de passe doit contenir au moins 6 caractères");
            return;
        }

        if (newPassword !== confirmNewPassword) {
            setError("Les nouveaux mots de passe ne correspondent pas");
            return;
        }

        // Simulate API request
        setTimeout(() => {
            setError('');
            setNewPassword('');
            setConfirmNewPassword('');
        }, 1000);
    };

    
return (
    <div className="mt-6 flex flex-col gap-[32px] px-[16px] md:px-[34px]">
        <div>
            <h2 className="text-base  md:text-sm font-bold">Modifier votre compte</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-3">
                <InputField placeholder={`${user?.first_name??'prenom'} ${user?.lastName??''}`}  style='bg-tcfbg h-[40px] xl:h-[50px]' name={' prenom'} />
                <InputField placeholder={`${user?.email??'nom'} `} style='bg-tcfbg h-[40px] xl:h-[50px]' name={'nom'}  />
                <InputField placeholder={`${user?.phone??'Téléphone'} `}style='bg-tcfbg h-[40px] xl:h-[50px]' name={'Téléphone'}  />
            </div>
            <button className="w-full bg-tcfsectext text-base md:text-sm text-white h-[40px] mg:h-[50px] rounded-[5px] font-semibold shrink-0 mt-[16px]">Enregistrer les modifications</button>

        </div>
        <div>
            <h2 className="text-base  md:text-sm font-bold">Changement de mot de passe</h2>
            <div className="grid grid-cols-1 gap-4 mt-3">
                {/* <InputField placeholder="Changement de mot de passe" style='bg-tcfbg h-[40px] xl:h-[50px]' name={'Changement de mot de passe'}  /> */}
                <InputField placeholder=" Nouveau mot de passe" style='bg-tcfbg h-[40px] xl:h-[50px]' name={'Nouveau mot de passe'}    error={error}    handleChange={(name, value) => setNewPassword(value)}
 />
                <InputField placeholder="Confirmer le nouveau mot de passe" style='bg-tcfbg h-[40px] xl:h-[50px]' name={'Confirmer le nouveau mot de passe'} error={error}    handleChange={(name, value) => setConfirmNewPassword(value)}/>
            </div>
            <button   onClick={handlePasswordChange} className="w-full bg-tcfsectext text-base md:text-sm text-white h-[40px] mg:h-[50px] rounded-[5px] font-semibold shrink-0 mt-[16px]">Enregistrer les modifications</button>

        </div>

    </div>
);
}


// Main Account Page Component
const AccountPage = () => {
    const [activeTab, setActiveTab] = useState("Profil");

    return (
        <div className=" bg-white py-[44px] rounded-[5px] shadow-md px-2 md:px-[22px] min-h-fit lg:min-h-[110vh]  mg:min-h-[110vh] xl:min-h-[100vh]">
            {/* Tabs Navigation */}
            <div className="flex border-b px-[22px] text-base  md:text-sm ">
                {["Profil", "Abonnement", "Orders"].map((tab) => (
                    <button
                        key={tab}
                        onClick={() => setActiveTab(tab)}
                        className={`px-4 md:px-10 lg:px-16 py-2 font-semibold ${activeTab === tab ? " border-b-[3px]  border-tcfsectext" : ""
                            }`}
                    >
                        {tab}
                    </button>
                ))}
            </div>
            <div className="px-1 ">
                {/* Conditional Rendering of Tabs */}
                {activeTab === "Profil" && <ProfileSettings />}
                {activeTab === "Abonnement" && <SubscriptionTable label={false} />}
                {/* {activeTab === "Orders" && <Orders />} */}
            </div>
        </div>
    );
};

export default AccountPage;
