// src/pages/SignUpPage.js
import React, { useState, useEffect } from 'react';
import HomeNavigation from '../components/Navbar';
import Footer from '../components/Footer';
import background from '../images/noisy-gradients.png';
import axios from 'axios';
import InputField from '../components/InputField';


const ContactPage = () => {

     // Add state for form fields
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    message: '',
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');

  // Handle input changes
  const handleInputChange = (name, value) => {
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Handle form submission
  const handleMessageSubmit = async () => {
    try {
      setIsSubmitting(true);
      setError('');

      const apiURL = process.env.REACT_APP_APIURL || '';

      console.log('formData', formData);
      const response = await axios.post(
        `${apiURL}/user/api/contact/`,
        formData
      );

      if (response.status === 200) {
        // Reset form
        setFormData({
          fullName: '',
          email: '',
          message: '',
        });
        alert('Message sent successfully!');
      }
    } catch (err) {
      setError('Failed to send message. Please try again.');
      console.error('Contact form error:', err);
    } finally {
      setIsSubmitting(false);
    }
  };


  return (
    <div className="flex flex-col min-h-screen justify-between">

      <div className=' text-tcfsectext pt-[147px]  md:pt-[173px]  lg:pt-[205px] h-[265px] md:h-[269px] lg:h-[326px] mg:h-[399px] bg-cover bg-center ' style={{
        
        backgroundImage: `url(${background})`,
        
       
      }}>
        <div className='text-center w-full text-white flex flex-col justify-center  ' >
          <HomeNavigation />

          <h2 className=" text-3xl md:text-4xl lg:text-7xl font-bold mg:mt-[68px] ">Contactez-nous</h2>
          <p>Faites vos choix, enregistrez-vous, et 
          commencez aujourd'hui</p>

        </div>
        <div className=' w-full pt-[70px]  md:pt-[50px] lg:pt-[75px] mg:pt-[82px] mb-[137px] flex flex-col items-center justify-center px-[32px] md:px-0'>
          <div className='w-full md:w-[52%] lg:w-[509px] flex flex-col '>
              <div className='space-y-[12px] md:space-y-[10px]'>
                <InputField
                 style='h-[40px]  bg-tcfbg'
                  label='Prénom & Nom'
                  placeholder='Prénom & Nom'
                  name='fullName'
                  value={formData.fullName}
                  handleChange={handleInputChange}
                />
                <InputField
                style='h-[40px] bg-tcfbg'
                  label='Courriel'
                  placeholder='exemple@mail.com'
                  name='email'
                  value={formData.email}
                  handleChange={handleInputChange}
                />
                <textarea
                  placeholder='Écrivez votre message'
                  className='w-full py-[7px] border text-base rounded-[5px] bg-tcfbg resize-none px-[12px] placeholder:text-tcfinput h-[173px] md:h-[156px] lg:h-[196px]  ' 
                  value={formData.message}
                  onChange={(e) => handleInputChange('message', e.target.value)}
                />
              </div>
              {error && <p className='text-red-500 text-sm mt-2'>{error}</p>}
              <button
                className='text-white bg-tcfsectext font-bold text-base h-[40px] rounded-[5px] mt-[7px]'
                onClick={handleMessageSubmit}
                disabled={isSubmitting}>
                {isSubmitting ? 'Envoi...' : 'Envoyez un message'}
              </button>
            </div>
            
            
        
        </div>
        <Footer />
      </div>
      
    </div>
  );
};




export default ContactPage;
