/** @format */

import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  Outlet,
} from 'react-router-dom';
import HomePage from './pages/HomePage';
import DashboardPage from './pages/DashboardPage';
import LoginPage from './pages/LoginPage';
import Subscription from './pages/SubscriptionPage';
import PaymentPage from './pages/PaymentPage';
import PaymentConfirmationPage from './pages/PaymentConfirmationPage';
import { useSelector } from 'react-redux';
import ComprehensionEcritePage from './pages/ComprehensionEcritePage';
import ComprehensionOralePage from './pages/ComprehensionOralePage';
import ComprehensionInstructionsPage from './pages/ComprehensionEcriteInstructionsPage';
import ComprehensionOraleInstructionsPage from './pages/ComprehensionOraleInstructionsPage';
import ExpressionEcriteInstructionsPage from './pages/ExpressionEcriteInstructionsPage';
import ExpressionOraleInstructionsPage from './pages/ExpressionOraleInstructionsPage';
import ExpressionEcritePage from './pages/ExpressionEcritePage';
import ContactPage from './pages/ContactPage';
import FormationPage from './pages/FormationPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import PasswordResetSuccess from './pages/PasswordResetSucessPage';
import StripePaymentConfirmation from './pages/StripePaymentConfirmation';
import ApproveBooking from './pages/ApproveBooking';
import ExpressionOralePage from './pages/ExpressionOralePage';


// AuthenticatedRoute component
const AuthenticatedRoute = () => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  return isAuthenticated ? <Outlet /> : <Navigate to='/login' />;
};

// Public Route Wrapper (Redirect logged-in users away from homepage)
const PublicRoute = () => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  return !isAuthenticated ? <HomePage /> : <Navigate to='/dashboard' />;
};
// App component
function App() {
  return (
    <Router>
      <div className=''>
        <Routes>
          {/* Redirect root URL to login */}
          <Route path='/' element={<PublicRoute />} />
          {/* Landing page */}
          <Route path='/' element={<HomePage />} />
          {/* Protected dashboard route */}
          <Route element={<AuthenticatedRoute />}>
            <Route path='/dashboard' element={<DashboardPage />} />
          </Route>
          {/* Free test and instructions pages */}
          <Route
            path='/comprehension-ecrite'
            element={<ComprehensionEcritePage />}
          />
          <Route path='/expression-ecrite' element={<ExpressionEcritePage />} />
          <Route path='/expression-orale' element={<ExpressionOralePage />} />

          
          <Route
            path='/comprehension-orale'
            element={<ComprehensionOralePage />}
          />
          <Route
            path='/comprehension-ecrite-instructions'
            element={<ComprehensionInstructionsPage />}
          />
          <Route
            path='/comprehension-orale-instructions'
            element={<ComprehensionOraleInstructionsPage />}
          />
          <Route
            path='/expression-ecrite-instructions'
            element={<ExpressionEcriteInstructionsPage />}
          />
          <Route
            path='/expression-orale-instructions'
            element={<ExpressionOraleInstructionsPage />}
          />
          {/* Other routes */}
          {/* Additional routes can go here for other sections if needed */}
          <Route path='/login' element={<LoginPage />} />
          <Route path='/tarifs' element={<Subscription />} />
          <Route path='/contact' element={<ContactPage />} />
          <Route path='/formation' element={<FormationPage />} />
          <Route path='/forgot-password' element={<ForgotPasswordPage />} />
          <Route
            path='/password/reset/confirm/:uid/:token'
            element={<ResetPasswordPage />}
          />
          <Route path='/reset-success' element={<PasswordResetSuccess />} />
          <Route path='/payment' element={<PaymentPage />} />\
          <Route
            path='/bookings/:bookingId/approve_booking/'
            element={<ApproveBooking />}
          />
          <Route
            path='/stripe-payment/confirmation'
            element={<StripePaymentConfirmation />}
          />
          <Route
            path='/payment/confirmation'
            element={<PaymentConfirmationPage />}
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
