import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TaskButtons from './TaskButtons';
import TestSection from './ExpressionOraleTestSection';
import TabView from './TestView';
import apiClient from '../services/httpService';
import { useSelector, useDispatch } from 'react-redux';
import { resetSelectedTest } from '../store/questionsSlice';
import ExpressionOraleBooking from './ExpressionOraleBooking';


const ExpressionOraleSection = ({ showCorrections, setActiveSection}) => {
    const dispatch = useDispatch();
const navigate = useNavigate();

    const [tests, setTests] = React.useState([]);
    const { selectedTest, questions, currentIndex } = useSelector((state) => state.questions);

    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated); // Get auth state

    const handleBackToTests = () => {
        // setShowCorrections(false)
        dispatch(resetSelectedTest());
    };

    const selectedTestObj = tests.find((test) => test.id === selectedTest);
    const testName = selectedTestObj?.name

    //  console.log("selectedTest", testName)

    const getTestsForCategory = async () => {
        try {
            const response = await apiClient.get('/mgmt/tests/004/');

            // console.log('response', response)
            if (response.status === 200) {
                setTests(response.data);
            }
        } catch (error) {
            console.error('Failed to fetch tests', error);
        }
    };
    useEffect(() => {
        getTestsForCategory();
    }, []);



    return (

        <div className="flex flex-col gap-[22px] text-tcfsectext gap-4 ">
            {!selectedTest &&  !showCorrections && <TabView tests={tests} testName={ 'Expression Orale '} />}
            {!selectedTest && showCorrections && <ExpressionOraleBooking/>}

            {selectedTest && (
                <>
                    {/* <label className='text-center text-white font-bold text-2xl lg:text-7xl mb-[48px]'>{showCorrections ? "Correction des sujets d'expression écrite" : 'Expression écrite test'} ({testName})</label> */}
                    <div className='flex justify-center items-center text-white gap-[12px]  mb-6'>
                    <button className='bg-[#FF5900] h-[40px] px-[58px] rounded-[5px]'>Méthodologies</button>
                        <button
                            onClick={() => navigate('/formation')}
                            className="bg-[#007BFF] h-[40px] px-[58px] rounded-[5px] text-white">
                            Formation
                        </button>
                        <button
                            onClick={() => window.open("https://youtube.com/@bidgroup-i2u?feature=shared", "_blank")}
                            className="bg-[#D70202] h-[40px] px-[58px] rounded-[5px] text-white">
                            Examples
                        </button>
                    </div>
                    <div className='text-white flex flex-col md:flex-row gap-[16px]  items-center'>
                        <button
                            onClick={handleBackToTests}
                            className="  font-bold border border-white px-[35px] h-[40px] rounded-[5px] w-full  md:w-fit "
                        >
                            Retour au test
                        </button>
                        {/* {isAuthenticated && (
                            <button
                                onClick={() => setShowCorrections(!showCorrections)}
                                className="w-full md:w-fit bg-[#50B303] h-[40px] px-[58px] rounded-[5px] text-white"
                            >
                                {showCorrections ? "Masquer les réponses" : "Voir les réponses"}
                            </button>
                        )} */}
                    </div>
                    <div className=' bg-white py-[51px] border border-tcfgray1 rounded-[5px] px-[25px] lg:px-[65px] shadow-md'>

                        <TaskButtons questions={questions?.expression_questions} />
                        <TestSection question={questions?.expression_questions[currentIndex]} showCorrections={showCorrections} setActiveSection={setActiveSection} />
                    </div>
                </>
            )}
        </div>

    );
};

export default ExpressionOraleSection

