import { createSlice } from "@reduxjs/toolkit";

const scoreSlice = createSlice({
    name: "score",
    initialState: {
        totalScore: 0, // Default score
    },
    reducers: {
        setTotalScore: (state, action) => {
            state.totalScore = action.payload; // Update score
        },
        resetScore: (state) => {
            state.totalScore = 0;
        },
    },
});

export const { setTotalScore,resetScore } = scoreSlice.actions;
export default scoreSlice.reducer;



