// SeriesButtons.js
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import apiClient from '../services/httpService';
import { useDispatch, useSelector } from 'react-redux';
import { setQuestions, setSelectedTest } from '../store/questionsSlice';
import locked from '../images/locked.svg'



const SeriesButtons = ({ tests = [], testName = '' }) => {



  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { selectedTest } = useSelector((state) => state.questions);


  const fetchQuestions = async (id) => {
    dispatch(setSelectedTest(id));
    try {
      const response = await apiClient.get(`/mgmt/questions/${id}/`);
      if (response.status === 200) {
        dispatch(setQuestions(response.data));
      } else {
        console.error('Error fetching questions:', response.data);
      }
    } catch (error) {
      console.error('Error fetching questions:', error);
    }
  };


  const getTestNumber = (testName) => {
    const match = testName.match(/teste?\s*(\d+)/i);  // 'teste?' makes 'e' optional
    return match ? match[1] : null;  // Returns the test number or null if not found
  };



  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated); // Get auth state



  return (


    <div className={`w-full h-full min-h-fit lg:min-h-[78vh]  mg:min-h-[110vh] xl:min-h-[100vh] text-tcfsectext flex flex-col ${isAuthenticated ? 'px-[22px] py-[22px]' : ' px-[25px] py-[28px] lg:px-[65px]  lg:py-[56px]'}  bg-white shadow-md border border-[#C1C1C180] rounded-[5px] `}>
      <div className=' h-full min-h-fit  lg:min-h-[78vh]  mg:min-h-[110vh] xl:min-h-[100vh] border border-[#C1C1C180] bg-[#E5E5E580] rounded-[5px] pt-[22px] pb-[23px] '>
        <div className='px-[16px] lg:px-[39px] pb-[17px] font-bold text-lg lg:text-2xl'>
          {testName}
        </div>
        <p className=' border-t border-[#C1C1C180]'></p>

        <ul className={`grid grid-cols-1 md:grid-cols-2 md:gap-y-[13px] lg:gap-y-[18px] ${testName.includes('Comprehension') || testName.includes('comprehension') ? 'gap-x-[54px] pr-[34px]  pl-[6px] gap-y-[2px]':'gap-x-[30px] pl-[16px] pr-[16px] gap-y-[8px] mt-0 md:mt-[24px]'} justify-between md:pl-[24px]  lg:pr-[64px]  pt-[12px] overflow-y-scroll custom-scrollbar ${isAuthenticated ? "max-h-[70vh]  lg:max-h-[63vh] xl:max-h-[84vh]" : "h-[300px] "}   cursor-pointer`}>
          {tests.map((value, i) => (
            <li

              className={`flex items-center rounded-[5px] ${testName.includes('Comprehension') || testName.includes('comprehension')? ' p-2.5 justify-between ':'text-white bg-tcfsectext  p-3 lg:p-4 mg:p-5 justify-center  '}
              ${value.status === "locked" ? "opacity-80 cursor-not-allowed" : "cursor-pointer"} 
              ${selectedTest?.id === value.id ? "bg-tcfmediumgreen text-white" : "hover:bg-tcfmediumgreen hover:text-tcfsectext focus:bg-tcfmediumgreen"}`}
              onClick={() => value.status !== "locked" && fetchQuestions(value.id)} key={i} // Navigate on click
            >
              <span className={`text-base mg:text-sm font-normal  `}>{testName} test {i + 1}</span>
              <span>
                {value.status === 'locked' ? (
                  <img onClick={() => { navigate("/tarifs") }} src={locked} alt='locked icon' />
                ) : (
                  ''
                )}
              </span>
            </li>
          ))}
        </ul>

      </div>

    </div>

  )
};

export default SeriesButtons;
