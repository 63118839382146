import React, { useState } from 'react';
import HomeNavigation from '../components/Navbar';
import Footer from '../components/Footer';
import ExpressionOraleSection from '../components/ExpressionOraleSection'
import background from '../images/noisy-gradients.png';

// the free page user sees when user is not logged in 
const ExpressionEcritePage = () => {

    return (
        <div className="flex flex-col min-h-screen justify-between  text-tcfsectext ">
             <div style={{
                height: '520px',
                backgroundImage: `url(${background})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
            }}>
            <HomeNavigation  />
            <>       
                    <div className=" mt-[140px] mg:mt-[240px] mb-[77px] mx-[32px] mg:mx-[64px] ">
                        
                        <ExpressionOraleSection showCorrections={false}  />

                 
                    </div>
                    </>
            
            <Footer />
        </div>
         </div>
    );

};




export default ExpressionEcritePage;
