import React, { useState } from 'react';
import HomeNavigation from '../components/Navbar';
import Footer from '../components/Footer';
import formation from '../images/section.jpg'
import SubscriptionSection from '../components/Subscription';
import background from '../images/noisy-gradients.png';
import { useNavigate, useMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';



const ComprehensionOralePage = () => {
    const navigate = useNavigate();

    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated); // Get auth state



    const handleNavigation = () => {
        if (isAuthenticated) {
            navigate("/dashboard"); // Redirect to dashboard if logged in
        } else {
            navigate("/tarifs"); // Otherwise, go to subscription
        }
    };
    const handleFreeCompOraleTestNavigation = () => {
        if (isAuthenticated) {
            navigate("/dashboard"); // Redirect to dashboard if logged in
        } else {
            navigate("/comprehension-orale"); // Otherwise, go to subscription
        }
    };


    return (
        <div className=" flex flex-col min-h-screen text-tcfsectext ">

            <div className=" h-[590px] md:h-[473px] lg:h-[520px] bg-cover bg-center" style={{ backgroundImage: `url(${background})` }}>

                <HomeNavigation />

                <div className=' flex flex-col text-center justify-center items-center pt-[184px] mg:pt-[225px] gap-[15px] px-[32px]  '>

                    <h2 className=" text-3xl lg:text-7xl font-bold  text-white">Se preparer au TCF Canada <br /> (Compréhension Orale) </h2>
                    <p className=" text-sm  text-white ">Afin de vous préparer au mieux au TCF et booster votre score, nous mettons à votre disposition des tests qui <br />
                        réunissent les mêmes conditions que lors d’une session officielle</p>
                    <div className='flex flex-col md:flex-row  gap-[18px] mt-[13px]'>
                        <button onClick={handleFreeCompOraleTestNavigation} className="bg-white  text-tcfsectext font-bold text-sm px-[19px] h-[40px] rounded-[5px]  "> S’entraîner Gratuitement</button>
                        <button onClick={handleNavigation} className=" font-bold border border-white text-sm px-[19px] h-[40px] rounded-[5px] text-white ">Tester En Condition Réelle</button>



                    </div>
                </div>

                <>

                    <div className=" h-min-screen pt-[168px] lg:pt-[123px]"> {/* Padding top to avoid overlap with fixed navbar */}
                        <div className=' border border-[#C1C1C180] pt-[16px] pb-[27px] lg:pt-[38px] lg:pb-[33px] px-[13px] lg:px-[38px] mb-[45px] mx-[32px]  rounded-[5px] '>
                        <div className='flex items-start gap-[8px] lg:gap-[17px]'>
                            <div className="relative bg-tcfmediumgreen text-sm font-bold lg:text-2xl rounded-full flex items-center text-tcfsectext p-1.5 lg:p-4  ">
                                    01
                                </div>
                                <div className='space-y-[7px]'>
                                    <p className='text-lg lg:text-2xl font-bold'>S'entraîner à la Compréhension Orale</p>
                                    <p className='text-sm '>Choisissez une série et répondez aux questions dans le délai prescrit, chaque question ne <br />comportant qu’une seule réponse correcte parmi les quatre réponses proposées. </p>
                                </div>

                            </div>
                        </div>

                        

                        <div className='mt-[47px] flex flex-col md:flex-row  mb-[62px]'>
                        <img
                            src={formation}
                            alt='formation'
                            className=' w-full h-[280px] md:w-[305px] md:h-auto  lg:w-[415px] lg:h-[379px] shrink-0  object-cover'
                            
                        />
                        <div className=' bg-tcfmediumgreen w-full rounded-r-[5px] '>
                        <div className=' px-[33px] py-[26px]'>
                                <div className='flex items-start gap-[8px] lg:gap-[17px] mb-[15px]'>
                                    <div className="relative bg-tcfsectext  font-bold  text-sm lg:text-2xl rounded-full flex items-center text-white p-1.5 lg:p-4 ">
                                        02
                                    </div>
                                    <div className='space-y-[7px]'>
                                        <p className='text-lg lg:text-2xl font-bold'>Tests en conditions réelles
                                        (Compréhension Orale)</p>

                                    </div>


                                </div>
                                <p className='mb-[24px]'>Êtes-vous prêt à relever le défi ? Inscrivez-vous sur notre plateforme et<br />
                                        entraînez-vous dans des conditions identiques à celles du TCF. Améliorez vos<br />
                                        scores en passant les tests aussi souvent que nécessaire.</p>

                                <p>Pour une préparation optimale au TCF, nous vous offrons des simulations d’examen qui reproduisent fidèlement l’ambiance et les conditions des sessions officielles. Profitez de cet entraînement idéal pour exceller le jour de votre examen.</p>
                                <button onClick={() => {
                  navigate('/tarifs');
                }} className='bg-tcfsectext text-white text-sm font-bold h-[40px] px-[15px] rounded-[5px] mt-[26px]'>Je suis prêt !</button>

                            </div>

                        </div>

                    </div>

                    <div className='relative border border-[#C1C1C180]  mb-[45px] mx-[32px]  rounded-[5px] pt-[16px] pb-[27px] lg:pt-[38px] lg:pb-[33px] px-[13px] lg:px-[38px]'>
                        <div className='flex items-start  gap-[8px] lg:gap-[17px]'>
                            <div className="relative bg-tcfmediumgreen text-sm  lg:text-2xl rounded-full flex items-center text-tcfsectext font-bold p-1.5 lg:p-4 ">
                                    03
                                </div>
                                <div className='space-y-[7px]'>
                                    <p className='text-lg lg:text-2xl  font-bold'>Consignes générales</p>
                                    <ul className='list-disc pl-5'>
                                        <li>Cette épreuve est constituée de questions à choix multiples (QCM). Pour chaque question, il y a 4 choix de réponse
                                            (A, B, C, D) mais une seule réponse est exacte.</li>
                                        <li>Cette épreuve est composée de 39 questions.</li>
                                        <li>Gérez bien votre temps. Cette partie dure 35 minutes.</li>
                                        <li>La question est toujours posée après l’écoute du document, par la même voix qui a lu la consigne. </li>
                                        <li>Attention, vous n’entendrez ces documents sonores et les questions qu’une seule fois, alors, écoutez bien !</li>
                                        <li>Attention vous ne pouvez pas revenir à une question si vous avez mal répondu. </li>
                                        <li>les questions et les situations sont de plus en plus difficiles au fil de l’épreuve. </li>

                                    </ul>
                                </div>

                            </div>
                        </div>





                    </div>


                    <div className='text-tcfsectext  mt-[115px] mb-[79px]'>
                        <SubscriptionSection />
                    </div>


                    <div className='relative border border-[#C1C1C180]  mb-[45px] mx-[32px]  rounded-[5px] pt-[16px] pb-[27px] lg:pt-[38px] lg:pb-[33px] px-[13px] lg:px-[38px]'>
                        <div className='flex items-start gap-[8px] lg:gap-[17px]'>
                            <div className="relative bg-tcfmediumgreen text-sm  lg:text-2xl rounded-full flex items-center text-tcfsectext font-bold p-1.5 lg:p-4">
                                04
                            </div>
                            <div className='space-y-[7px]'>
                                <p className='text-lg lg:text-2xl font-bold'>Comment réussir la compréhension Orale du TCF Canada ? <br />
                                    Astuces et techniques ?</p>
                                <p>Réussir la compréhension orale du TCF Canada peut être un défi, mais il y a certaines stratégies que vous pouvez adopter pour vous préparer de manière efficace. Voici quelques conseils: :</p>
                                <ul class="list-disc pl-5 space-y-4">
                                    <li>
                                        <strong>Familiarisez-vous avec le format de l’examen</strong> : Comme pour la compréhension orale, il est essentiel de connaître la structure de l’examen de compréhension écrite. Cela comprend le nombre de questions, le type de textes que vous serez amené à lire, et le temps imparti.
                                    </li>
                                    <li>
                                        <strong>Pratiquez la lecture en français</strong> : Lisez régulièrement des textes en français pour vous habituer à la structure des phrases, au vocabulaire et à l’orthographe. Essayez de diversifier vos sources pour couvrir un large éventail de sujets et de styles, tels que les journaux, les romans, les magazines et les articles en ligne.
                                    </li>
                                    <li>
                                        <strong>Améliorez votre vocabulaire</strong> : Connaître un grand nombre de mots et d’expressions vous aidera non seulement à comprendre les textes, mais aussi à répondre plus rapidement aux questions. Essayez d’apprendre de nouveaux mots chaque jour et utilisez-les dans des phrases pour mieux les mémoriser.
                                    </li>
                                    <li>
                                        <strong>Apprenez à repérer les informations clés</strong> : Lorsque vous lisez un texte, entraînez-vous à repérer les informations importantes qui vous aideront à répondre aux questions. Cela peut inclure les idées principales, les détails spécifiques et l’attitude ou l’opinion de l’auteur.
                                    </li>
                                    <li>
                                        <strong>Pratiquez des exercices de compréhension écrite</strong> : Il existe de nombreux exercices de compréhension écrite disponibles en ligne ou dans les manuels de préparation aux examens. Ces exercices vous aideront à vous habituer au type de questions qui seront posées lors de l’examen.
                                    </li>
                                    <li>
                                        <strong>Gérez votre temps efficacement</strong> : Lors de l’examen, vous devez lire les textes et répondre aux questions dans un temps limité. Pratiquez la gestion du temps lors de vos révisions pour vous assurer que vous pouvez terminer tous les éléments de l’examen dans le temps imparti.
                                    </li>
                                    <li>
                                        <strong>Revoyez la grammaire française</strong> : Une bonne connaissance de la grammaire française vous aidera à comprendre les textes plus facilement. Revoyez les règles grammaticales, les temps verbaux et les structures de phrases avant l’examen.
                                    </li>
                                    <li>
                                        <strong>Restez calme et concentré</strong> : Lors de l’examen, essayez de rester calme et concentré. Si vous ne comprenez pas une partie du texte, ne vous inquiétez pas. Concentrez-vous sur ce que vous pouvez comprendre et faites de votre mieux pour répondre aux questions.
                                    </li>
                                    <li>
                                        <strong>Rappelez-vous</strong> : La clé pour réussir la section de compréhension écrite du TCF Canada est la préparation et la pratique régulières. Bonne chance !
                                    </li>
                                </ul>

                            </div>

                        </div>
                    </div>
                </>
                <Footer />
            </div>
        </div>
    );
};




export default ComprehensionOralePage;
