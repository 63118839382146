/** @format */

// src/components/SignUpForm.js
import React, { useState, useEffect } from 'react';
import InputField from '../components/InputFieldSignup';
import CountrySelect from '../components/CountrySelect';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  setSignUpCountry,
  setSignUpEmail,
  setSignUpFirstName,
  setSignUpLastName,
  setSignUpPassword,
  setSignUpPhone,
} from '../store/authSlice';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const SignUpForm = ({ onFormDataChange }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    country: '',
    phone: '',
    password: '',
    confirmPassword: '',
  });

  const [errors, setErrors] = useState({});


  

    const validateField = (name, value) => {
      let error = '';
      if (name === 'email' && !/^\S+@\S+\.\S+$/.test(value)) error = "Format de l’email invalide";
      if (name === 'password' && value.length < 6) error = 'Le mot de passe doit contenir au moins 6 caractères';
      if (name === 'confirmPassword' && value !== formData.password) error = 'Les mots de passe ne correspondent pas';
      return error;
    };

    const handleChange = (name, value) => {
      const newFormData = { ...formData, [name]: value };
      setFormData(newFormData);
  
      const error = validateField(name, value);
      const newErrors = { ...errors, [name]: error };
      setErrors(newErrors);
  
      onFormDataChange(newFormData, newErrors); // Send to parent
    };

  return (
    <div className='flex flex-col justify-center w-full gap-[16px]'>
      <div className='flex flex-col lg:flex-row gap-[21px] justify-between w-full'>
        <InputField
          label='Prenom'
          placeholder='Votre prénom'
          handleChange={handleChange}
          name={'firstName'}
          divstyle='flex flex-[1] '
          error={errors.firstName}
        />
        <InputField
          label='Nom'
          placeholder='Votre nom'
          handleChange={handleChange}
          name={'lastName'}
          divstyle='flex flex-[1] '
          error={errors.lastName}
        />
      </div>
      <InputField
        label='Email'
        type='email'
        placeholder='Votre email'
        handleChange={handleChange}
        name={'email'}
        error={errors.email}
      />
      <CountrySelect name='country' handleChange={(value) => handleChange} />

      <PhoneInput
        country={'gn'}
        value={formData.phone}
        specialLabel=''
        onChange={(phone) => handleChange('phone', phone)}
        disableDropdown={false}
        inputStyle={{
          height: '40px',
          width: '100%',
          borderRadius: '5px',
          border: 'none',
          backgroundColor: '#E5E5E580',
          fontSize: '14px',
        }}
        placeholder=''
      />

      <InputField
        label='Mot de passe'
        type='password'
        placeholder='Votre mot de passe'
        showPasswordToggle
        showicon={false}
        handleChange={handleChange}
        name={'password'}
        error={errors.password}
      />
      <InputField
        label='Confirmez le mot de pass'
        type='password'
        placeholder='Confirmez votre mot de passe'
        showPasswordToggle
        showicon={false}
        handleChange={handleChange}
        name={'confirmPassword'}
        error={errors.confirmPassword}
      />
    </div>
  );
};

export default SignUpForm;
