/** @format */

// LoginForm.js
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import InputField from './InputField';
import userIcon from '../images/user.svg';
import lock from '../images/lock.svg';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { loginSuccess } from '../store/authSlice';
import { resetSelectedTest } from '../store/questionsSlice';
import axios from 'axios';

const LoginForm = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = async () => {
    const apiURL = process.env.REACT_APP_APIURL || '';
    try {
      const response = await axios.post(`${apiURL}/auth/login/jwt/create/`, {
        email,
        password,
      });
      if (response.status === 200) {
        dispatch(
          loginSuccess({
            user: response.data.user,
            access: response.data.access,
            refresh: response.data.refresh,
          })
        );
        // console.log(response.data.access);

        dispatch(resetSelectedTest());

        navigate('/dashboard');
      } else {
        setError('Something went wrong. Please try again.');
      }
    } catch (error) {
      setError(error.response.data.detail);
    }
  };

  const handleChange = (name, value) => {
    if (name === 'email') {
      setEmail(value);
    } else if (name === 'password') {
      setPassword(value);
    }
  };

  return (
    <div className='flex flex-col w-full text-tcfsectext md:text-white  '>
      <h3 className=' text-3xl md:text-4xl lg:text-7xl font-bold mb-[5px]  '>
        Se connecter
      </h3>
      <p className=' mb-[22px] lg:mb-[28px]'>
        Veuillez saisir vos coordonnées ci-dessous
      </p>

      <div className='flex flex-col justify-center w-full gap-[16px]'>
        <InputField
          icon={userIcon}
          placeholder='Entrez votre email'
          showicon={true}
          name={'email'}
          handleChange={handleChange}
          style='bg-tcfbg md:bg-white h-[40px] mg:h-[50px]'
        />
        <InputField
          type='password'
          icon={lock}
          placeholder='Mot de passe'
          showicon={true}
          name={'password'}
          showPasswordToggle
          handleChange={handleChange}
          style='bg-tcfbg md:bg-white h-[40px] mg:h-[50px]'
        />
      </div>

      <p className='text-base  mt-[14px] mb-4  md:mb-[27px] lg:mb-4 '>
        Se souvenir de moi
      </p>

      <button
        onClick={handleSubmit}
        className='w-full bg-tcfsectext text-sm text-white h-[40px] mg:h-[50px] rounded-[5px] font-semibold shrink-0 mb-[16px]'>
        Se Connecter
      </button>

      <a
        href='/forgot-password'
        className='text-center text-base md:text-tcfsectext lg:text-white  xl:text-tcfsectext underline solid mb-[9px]'>
        
        Mot de passe oublié?
      </a>

      <NavLink
        className=' text-center text-base md:text-tcfsectext lg:text-white  mg:text-tcfsectext'
        to='/tarifs'>
        S’inscrire
      </NavLink>
    </div>
  );
};

export default LoginForm;
