import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentIndex } from '../store/questionsSlice';

const TaskNumbers = ({ questions, showCorrections }) => {
  const { currentIndex } = useSelector((state) => state.questions);



  const dispatch = useDispatch();



  return (
    <div className=" text-tcfsectext flex flex-col justify-between w-full ">
      <h2 className="text-sm font-bold mb-[24px]">Nombre de questions</h2>
      <div className="grid grid-cols-[repeat(auto-fit,_minmax(40px,_1fr))] gap-y-1 gap-x-[4px]">
        {questions?.map((question, i) => {

          let bgColor = "bg-tcfbg"; // Default background color

          if (question.revise_question && !question.response) {
            bgColor = "bg-[#FFF67E]"; // Revise question with no response
          }
          if (question.response) {
            bgColor = showCorrections?'bg-tcfbg':"bg-tcfmediumgreen"; // Question has a response
          }
          if (i === currentIndex) {
            bgColor = "bg-tcfsectext"; // Current question
          }
          if (i === currentIndex && question.revise_question) {
            bgColor = "bg-[#FFF67E] border-2 border-tcfsectext"; // Current question
          }

          return (



            <div className="text-tcfsectext flex flex-col justify-between  ">



              <div className="">

                <button
                  key={i}
                  onClick={() => dispatch(setCurrentIndex(i))}
                  className={`w-full flex-1 text-tcfsectext  h-[40px] flex justify-center items-center  text-sm  hover:bg-tcfgray1 focus:outline-none  ${bgColor} ${bgColor === "bg-tcfsectext" ? "text-white" : ''}  `}
                >
                  {i + 1}
                </button>
              </div>



            </div>
          );

        })}




      </div>
      <div className="flex lg:flex-col  justify-between md:justify-normal lg:justify-between gap-[6px] md:gap-[22px] lg:gap-[6px] mg:gap-[16px] mt-[24px]">
        <div className='flex gap-[7px] items-center'>
          <div className="w-[15px] h-[15px] bg-tcfsectext rounded-[3px] ">

          </div>
          <span className='text-base '>Actuel</span>
        </div>
        {!showCorrections && (
          <>
            <div className='flex gap-[7px] items-center'>
              <div className="w-[15px] h-[15px] bg-[#FFF67E] rounded-[3px] ">

              </div>
              <span className='text-base '>Révision</span>
            </div>
            <div className='flex gap-[7px] items-center'>
              <div className="w-[15px] h-[15px] bg-tcfmediumgreen  rounded-[3px] ">

              </div>
              <span className='text-base ' >Répondu</span>
            </div>
          </>
        )}

      </div>
      {/* <button onClick={handleReviseQuestion} className=" font-bold bg-tcfsectext border border-white text-base px-[19px] h-[30px] rounded-[5px] text-white mt-[53px] ">Revise</button> */}

    </div>
  );
};

export default TaskNumbers;
